import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import { productOptionsRequest } from "../../actions/productActions";
import { stockTransferReportRequest } from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import { DateInput, StyledMultiSelect , StyledInput} from "../../components/inputFields";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  VariantSkuTag,
  VariantTag,
  ViewDateText
} from "../../components/viewComponents";
import { formatDate } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";

export default function StockTransferReportScreen() {
  useDocumentTitle("Stock Transfer Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [reportData, setReportDate] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [showCost, setShowCost] = useState(false);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    if (!options) {
      dispatch(productOptionsRequest());
    }
    if (options && !Object.keys(options).length > 0) {
      dispatch(productOptionsRequest());
    }
  }, [dispatch, options]);

  useEffect(() => {
    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("view_product_cost")){
        setShowCost(true);
      }else{
        setShowCost(false);
      }
    }
  }, [userDetailsSuccess]);

  // filter and search  function
  const [filterLocation, setFilterLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  const [filterError, setFilterError] = useState("");

  const applyFilter = () => {
    getProducts();
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    let stockListdata = [];

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await stockTransferReportRequest(
        0,
        99999999999,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        filterKeyword,
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvString = "";
    if(showCost){
      let csvWriter = new CsvWriter([
        "CreateDate",
        "TransferOutLocation",
        "TransferInLocation",
        "Code",
        "ProductName",
        "Category",
        "TransferQuantity",
        "UnitCost",
        "TotalCost",
        "Notes",
        "Creator",
      ]);
      for (let line of stockListdata) {
        csvWriter.addLine([
          line.atime ? formatDate(
            new Date(line.atime * 1000),
            userDetails.data.orgInfo.date_format,
            userDetails.data.orgInfo.time_zone
          ) : "",
          line.outLocation,
          line.inLocation,
          line.variantSku,
          line.name,
          line.category ? line.category : "",
          line.transfer_qty,
          line.unit_cost,
          line.total_cost,
          line.notes,
          line.creatorFirstName + " " +( line.creatorLastName ? line.creatorLastName : ""),
        ]);
      }
      csvString = csvWriter.content;
    }else{
      let csvWriter = new CsvWriter([
        "CreateDate",
        "TransferOutLocation",
        "TransferInLocation",
        "Code",
        "ProductName",
        "Category",
        "TransferQuantity",
        "Notes",
        "Creator",
      ]);
      for (let line of stockListdata) {
        csvWriter.addLine([
          line.atime ? formatDate(
            new Date(line.atime * 1000),
            userDetails.data.orgInfo.date_format,
            userDetails.data.orgInfo.time_zone
          ) : "",
          line.outLocation,
          line.inLocation,
          line.variantSku,
          line.name,
          line.category ? line.category : "",
          line.transfer_qty, 
          line.notes,
          line.creatorFirstName + " " +( line.creatorLastName ? line.creatorLastName : ""),
        ]);
      }
      csvString = csvWriter.content;
    }

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "stock-transfer-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    let selectedFilterCategory = [];
    let selectedFilterLocation = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    if (filterLocation.length > 0) {
      filterLocation.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await stockTransferReportRequest(
        pageNum,
        pageSize,
        selectedFilterCategory.join(),
        selectedFilterLocation.join(),
        startTimestamp,
        endTimestamp,
        filterKeyword,
        config
      );

      if (responseData.success) {
        setFilterError("");
        setReportDate(responseData.data);
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Stock Transfer Report" />

      <ScreenTitle
        title="Stock Transfer Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => setFilterStartDate(startDate)}
              />
              <StyledMultiSelect
                label="Location"
                selectOptions={optionsSuccess ? options.location : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
              <StyledInput
                label="Notes"
                type="text"
                name="filterKeyword"
                value={filterKeyword}
                onChange={(filterKeyword) => setFilterKeyword(filterKeyword)}
              />
            </div>
            <div style={{ width: "45%" }}>
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => setFilterEndDate(endDate)}
              />
              <StyledMultiSelect
                label="Category"
                selectOptions={optionsSuccess ? options.category : []}
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Create Date</th>
                  <th>Transfer Out Location</th>
                  <th>Transfer in Location</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Transfer Quantity</th>
                  {showCost && <th>Unit Cost</th>}
                  {showCost && <th>Total Cost</th>}
                  <th>Notes</th>
                  <th>Creator</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>
                          <ViewDateText value={item.atime} />
                        </td>
                        <td>
                          <Tag name={item.outLocation} color="gray" />
                        </td>
                        <td>
                          <Tag name={item.inLocation} color="gray" />
                        </td>
                        <td><VariantSkuTag name={item.variantSku} /></td>
                        <td>{item.name}</td>
                        <td>{item.category}</td>
                        <td>{item.transfer_qty}</td>
                        {showCost && <td><DollarTag />{item.unit_cost}</td>}
                        {showCost && <td><DollarTag />{item.total_cost}</td>}
                        <td>{item.notes}</td>
                        <td>{item.creatorFirstName + " " +( item.creatorLastName ? item.creatorLastName : "")}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
