import React, { useEffect, useState } from "react";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { ScreenTitle } from "../../components/editComponents";
import { useDispatch, useSelector } from "react-redux";
import { favGroupRequest } from "../../actions/dashboardActions";
import { ErrorMessage, Loader } from "../../components/viewComponents";
import FavouriteGroup from "./FavouriteGroup";
import SalesBoard from "./SalesBoard";
import StockBoard from "./StockBoard";
import TrialCard from "./TrialCard";
import { useLocation } from "react-router-dom";
import OnboardingModal from "./OnboardingModal";
import InventoryBoard from "./InventoryBoard";

export default function Dashboard() {
  useDocumentTitle("Dashboard");
  const dispatch = useDispatch();
  const location = useLocation();
  const showOnboardingModal = location.state
    ? location.state.showOnboardingModal
    : false;
  const [onboardingModal, setOnboardingModal] = useState(showOnboardingModal);

  const {
    userDetails,
    success: userDetailsSuccess,
    loading: userDetailsLoading,
    error: userDetailsError,
  } = useSelector((state) => state.userDetail);
  const {
    loading: favLoading,
    favList,
    success: favSuccess,
    error: favError,
  } = useSelector((state) => state.favGroup);

  useEffect(() => {
    dispatch(favGroupRequest());
  }, [dispatch]);

  return (
    <div className={commonStyle.pageContainer}>
      <OnboardingModal
        isModalOpen={onboardingModal}
        closeModal={() => setOnboardingModal(false)}
      />

      <div className={commonStyle.pageCardContentContainer}>
        {userDetailsLoading ? (
          <Loader mess="Requesting user details..." />
        ) : userDetailsSuccess ? (
          <div className="mb-[20px]">
            <ScreenTitle title="Dashboard" />
            {userDetails.data.orgInfo.plan_name === "trial" &&
            userDetails.data.roles.includes("Super Admin") ? (
              <TrialCard />
            ) : (
              <></>
            )}
            <InventoryBoard />
            {favLoading ? (
              <Loader mess="Requesting favorite features, please wait a moment..." />
            ) : favSuccess ? (
              <FavouriteGroup initialData={favList} />
            ) : (
              <ErrorMessage mess={favError} />
            )}

            <SalesBoard />
            <StockBoard />
          </div>
        ) : userDetailsError ? (
          <ErrorMessage mess={userDetailsError} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
