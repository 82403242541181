import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const deleteSaleOrderRequest = async (soid, config) => {
   try {
     const response = await axios.post(SERVER_URL + `/sale_order/delete/${soid}`, "", config);
     return response.data;
   } catch (error) {
     throw new Error(error.message);
   }
};

export const voidSaleOrderRequest = async (data, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/sale_order/void`, data, config);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};


