import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUserRequest } from "../../../actions/userRoleActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import commonStyle from "../../../style/commonStyle.module.css";
import UserDetailForm from "./UserDetailForm";
import { DeleteDialog, SimpleDialog } from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";
import { ScreenTitle } from "../../../components/editComponents";
import { removeUserRequest } from "../../../api/userServices";

export default function EditUser() {
  useDocumentTitle("Update User");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {}, [dispatch]);

  // get state from url
  const data = location.state;

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, success, error } = useSelector((state) => state.updateUser);

  const [editUserDialog, setEditUserDialog] = useState(false);

  const [removeDialog, setRemoveDialog] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const editUserRequest = (values) => {
    setEditUserDialog(true);
    // get selected permissions id

    let selectedRolesId = [];

    for (let i = 0; i < values.roles.length; i++) {
      selectedRolesId.push(values.roles[i].id);
    }

    let userValues = {
      email: values.userEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.userPhone,
      position: values.position,
      departmentId: values.department.id,
      locationId: values.location.id,
      roleIds: selectedRolesId,
    };

    dispatch(updateUserRequest(userValues, values.id));
  };

  const closeDialogWithSuccess = () => {
    navigate("/settings/users", { replace: true, from: location });
  };

  const openRemoveDialog = (e) => {
    e.preventDefault();
    setRemoveDialog(true);
  };

  const removeUser = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
        success: false,
        error: "",
      }));
      const responseData = await removeUserRequest(data.id, config);
      if (responseData.data.success) {
       

        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else if (responseData.data.error === 10) {
        throw "Can not remove super admin user.";
      } else if (responseData.data.error === 20) {
        throw "User does not exist.";
      } else if (responseData.data.error === 24) {
        throw "User has been removed.";
      } else {
        throw "Remove user failed.";
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeRemoveDialogWithSuccess = () => {
    setRemoveDialog(false);
    navigate("/settings/users", { replace: true, from: location });
  }

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update User"
        successMessage="User updated successfully!"
        isDialogOpen={editUserDialog}
        closeDialog={() => setEditUserDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        btnValue="Confirm"
        confirmAction={closeDialogWithSuccess}
      />
      <DeleteDialog
        title="Remove User"
        preDeleteMess="Remove this user can not be undone. Removing this user will not impact historical transaction records, but the user will no longer be able to access the system. Are you sure you want to proceed with removing this user?"
        successMessage="User removed successfully."
        isDialogOpen={removeDialog}
        closeDialog={() => setRemoveDialog(false)}
        closeDialogWithSuccess={closeRemoveDialogWithSuccess}
        loading={reqStatus.loading}
        success={reqStatus.success}
        error={reqStatus.error}
        loadingMessage="Processing request..."
        deleteAction={removeUser}
        proceedBtnName={"Proceed Removing"}
      />

      <Breadcrumbs screenName="Update User" />
      <ScreenTitle title="Update User" />
      <div>
        {
          data && <div className={commonStyle.pageContentContainer}>
          <UserDetailForm
            values={data}
            onSubmit={editUserRequest}
            btnName="Update User"
            type="edit"
          />
          <div>
            {data.isActivated === 1 && data.isAdmin !==1 && (
              <div className="flex flex-row mt-4">
                <p className="mr-2">User has resigned,</p>
              <button onClick={openRemoveDialog} className="text-brandColor hover:underline">Remove User</button>
              </div>
            )}
          </div>
        </div>
        }
        
      </div>
    </div>
  );
}
