import React, { useState } from "react";
import axios from "axios";
import loginStyle from "./loginStyle.module.css";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  LoadingButton,
  SimpleBlueButton,
} from "../../components/editComponents";
import { StyledPasswordInput } from "../../components/inputFields";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  ViewText,
  ErrorText,
  SuccessText,
} from "../../components/viewComponents";
import useDocumentTitle from "../../useDocumentTitle";
import LoginAdver from "./LoginAdver";
import { useDispatch } from "react-redux";
import { userlogout } from "../../actions/authAction";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ResetPasswordScreen() {
  useDocumentTitle("Set Password");

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const from = searchParams.get("from");

  const headers = {
    "Content-type": "application/json",
  };

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState();

  //define the error message
  const [formErrors, setFormErrors] = useState({});

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  // handle password field changes
  const [hint1, setHint1] = useState(loginStyle.hintError);
  const [hint2, setHint2] = useState(loginStyle.hintError);
  const [hint3, setHint3] = useState(loginStyle.hintError);
  const [hint4, setHint4] = useState(loginStyle.hintError);
  const [hint5, setHint5] = useState(loginStyle.hintError);

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);

    const lowerCaseCount = (newPassword.match(/[a-z]/g) || []).length;
    const upperCaseCount = (newPassword.match(/[A-Z]/g) || []).length;
    const numberCount = (newPassword.match(/\d/g) || []).length;
    const specialCount = (newPassword.match(/[~^@#$!%*?&]/g) || []).length;
    setHint1(
      newPassword.length > 7 ? loginStyle.hintPassed : loginStyle.hintError
    );
    setHint4(lowerCaseCount > 0 ? loginStyle.hintPassed : loginStyle.hintError);
    setHint3(upperCaseCount > 0 ? loginStyle.hintPassed : loginStyle.hintError);
    setHint2(numberCount > 0 ? loginStyle.hintPassed : loginStyle.hintError);
    setHint5(specialCount > 0 ? loginStyle.hintPassed : loginStyle.hintError);

    if (
      newPassword.length > 7 &&
      lowerCaseCount > 0 &&
      upperCaseCount > 0 &&
      numberCount > 0 &&
      specialCount > 0
    ) {
      setFormErrors({ password: "" });
    }
  };

  const handleRepeatPasswordChange = (newRepeatPassword) => {
    setRepeatPassword(newRepeatPassword);
    if (newRepeatPassword !== password) {
      setFormErrors({ repeatPassword: "Password does not match" });
    } else {
      setFormErrors({ repeatPassword: "" });
    }
  };

  // check input validation
  const validate = () => {
    const errors = {};
    const regexPassword =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~^@#$!%*?&])[a-zA-Z0-9~^@#$!%*?&]{8,}$/;

    if (!password) {
      errors.password = "Password is required!";
    } else if (!regexPassword.test(password)) {
      errors.password = "Password is invalid!";
    }

    if (repeatPassword !== password) {
      errors.repeatPassword = "Password does not match!";
    }

    return errors;
  };

  const savePassword = () => {
    const hasErrors = validate();

    if (Object.keys(hasErrors).length === 0) {
      sendResetPasswordRequest();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const sendResetPasswordRequest = () => {
    let passwordValues = {
      userEmail: email,
      password: password,
      type: from,
    };

    try {
      setRequestStatus({ ...requestStatus, loading: true });

      axios
        .post(SERVER_URL + `/user/reset_password`, passwordValues, headers)
        .then(async (response) => {
          if (response.data.success) {
            setRequestStatus({
              ...requestStatus,
              loading: false,
              success: true,
            });
            dispatch(userlogout());
          } else if (response.data.error === 10) {
            throw "Email does not exist."
          } else if (response.data.error === 20) {
            throw "Account is inactive, can not reset password by this way."
          } else if (response.data.error === 22) {
            throw "Account is removed, can not reset password."
          } else if (response.data.error === 24) {
            throw "reset password failed"
          } else if (response.data.error === 30) {
            throw "Account status is actived, please log in"
          } else if (response.data.error === 32) {
            throw "Account is removed."
          } else if (response.data.error === 34) {
            throw "Activate account failed."
          } else {
            throw "Reset paswword failed."
          }
        })
        .catch((err) => {
          setRequestStatus({
            ...requestStatus,
            loading: false,
            error: err,
          });
        });
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };

  return (
    <div className={loginStyle.container}>
      <LoginAdver />

      <div className={loginStyle.loginContainer}>
        <div className={loginStyle.brandLogoCon}>
          <div className={loginStyle.brandLogo}></div>
        </div>

        <div className={loginStyle.loginFormCon}>
          <form className={loginStyle.loginForm}>
            {from === "resetpwd" ? (
              <h1 className="text-3xl font-medium">Reset Password</h1>
            ) : from === "newuser" ? (
              <div>
                <h1 className="text-3xl font-medium">Activate Account</h1>
                <p style={{ marginTop: "12px" }}>
                  Please set a password to activate your account.
                </p>
              </div>
            ) : (
              <></>
            )}

            {requestStatus.error ? (
              <ErrorText mess={requestStatus.error} />
            ) : (
              <></>
            )}
            {!email ? (
              <ErrorText mess="We're sorry, but your email address request failed. Please click the link from the reset password email again." />
            ) : (
              <div>
                <ViewText title="Email" value={email} />
                {requestStatus.success ? (
                  <div>
                    <SuccessText
                      mess={
                        from === "newuser"
                          ? "Congratulations, your account has been successfully activated! Please log in to your account to get started."
                          : "Congratulations! Your password has been reset successfully. Please sign in with your updated password."
                      }
                    />
                    <div className={loginStyle.linkCon}>
                      <NavLink
                        to="/login"
                        exact="true"
                        className={loginStyle.signinlink}
                      >
                        Sign In
                      </NavLink>
                    </div>
                  </div>
                ) : (
                  <div>
                    <StyledPasswordInput
                      label="Password*"
                      name="password"
                      value={password}
                      onChange={(password) => handlePasswordChange(password)}
                      error={formErrors.password}
                    />
                    <div className={loginStyle.passwordHints}>
                      <div className={hint1}>
                        <AiOutlineCheckCircle />
                        <p style={{ marginLeft: "2px" }}>
                          At least 8 characters
                        </p>
                      </div>
                      <div className={hint2}>
                        <AiOutlineCheckCircle />
                        <p style={{ marginLeft: "2px" }}>Contains 1 number</p>
                      </div>
                      <div className={hint3}>
                        <AiOutlineCheckCircle />
                        <p style={{ marginLeft: "2px" }}>
                          Contains 1 uppercase letter
                        </p>
                      </div>
                      <div className={hint4}>
                        <AiOutlineCheckCircle />
                        <p style={{ marginLeft: "2px" }}>
                          Contains 1 lowercase letter
                        </p>
                      </div>
                      <div className={hint5}>
                        <AiOutlineCheckCircle />
                        <p style={{ marginLeft: "2px" }}>
                          Contains 1 special character
                        </p>
                      </div>
                    </div>

                    <StyledPasswordInput
                      label="Repeat Password*"
                      name="repeatPassword"
                      value={repeatPassword}
                      onChange={(repeatPassword) =>
                        handleRepeatPasswordChange(repeatPassword)
                      }
                      error={formErrors.repeatPassword}
                    />
                    <div className="w-full mt-4">
                      {requestStatus.loading ? (
                        <LoadingButton name="Saving Password..." />
                      ) : (
                        <SimpleBlueButton
                          name={
                            from === "newuser"
                              ? "Activate Account"
                              : "Update Password"
                          }
                          action={savePassword}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
