import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { trialTaskRequest } from "../../actions/dashboardActions";
import {
  deleteTrialDataRequest,
  skipTrialStepRequest,
} from "../../api/dashboardServices";
import {
  SimpleBlueButtonLink,
  SimpleOutlineButton
} from "../../components/editComponents";
import {
  DeleteTrialAccountDataDialog,
  ErrorMessage,
  Loader
} from "../../components/viewComponents";
import billingStyle from "../billing/billingStyle.module.css";
import dashboardStyle from "./dashboardStyle.module.css";

export default function TrialCard() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails } = useSelector((state) => state.userDetail);
  const { loading, tasks, success, error } = useSelector(
    (state) => state.trailTask
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    dispatch(trialTaskRequest());
  }, [requestStatus.success]);

  //////////////////////////Skip Actions //////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  const skipAction = async (type) => {
    let skipStep = { name: type };
    try {
      setRequestStatus({
        ...requestStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await skipTrialStepRequest(
        skipStep,
        userInfo.data.token
      );

      if (responseData.success) {
        setRequestStatus({
          ...requestStatus,
          loading: false,
          success: true,
          error: "",
        });
      } else {
        setRequestStatus({
          ...requestStatus,
          loading: false,
          error: "Skip step failed.",
        });
      }
    } catch (error) {
      setRequestStatus({
        ...requestStatus,
        loading: false,
        error: error.message,
      });
    }
  };

  const openDeleteDialog = () => {
    setDeleteStatus({
      loading: false,
      success: false,
      error: "",
    });
    setDeleteDialogOpen(true);
  };

  const deleteTrialAccountData = async (dataType) => {
    try {
      setDeleteStatus({
        ...deleteStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await deleteTrialDataRequest(
        dataType,
        userInfo.data.token
      );

      if (responseData.success) {
        setDeleteStatus({
          ...deleteStatus,
          loading: false,
          success: true,
          error: "",
        });
      } else {
        throw responseData.message;
      }
    } catch (error) {
      setDeleteStatus({
        ...deleteStatus,
        loading: false,
        error: error,
      });
    }
  };
  //////////////////////////Components //////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const CompleteTag = ({ status }) => {
    return (
      <div className={dashboardStyle.taskCompleteTag}>
        <AiOutlineCheckCircle className={dashboardStyle.completeIcon} />
        <p>{status}</p>
      </div>
    );
  };

  const SkipTag = ({ status }) => {
    return <p className={dashboardStyle.taskSkipTag}>{status}</p>;
  };

  return (
    <div className={dashboardStyle.section_wrapper}>
      <DeleteTrialAccountDataDialog
        title="Delete Trial Account Data"
        successMessage="Trial data deleted successfully."
        isDialogOpen={deleteDialogOpen}
        closeDialog={() => setDeleteDialogOpen(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        deleteAction={deleteTrialAccountData}
      />

      <h1 style={{ marginBottom: "12px", fontWeight:"500" }}>Welcom to your trial</h1>
      <p style={{ margin: "16px 0" }}>
        Please follow below steps to get a quick start.
      </p>
      {loading ? (
        <Loader mess="Request status..." />
      ) : success ? (
        <div>
          {userDetails.data.orgInfo.xeroConnected ? (
            <div className={dashboardStyle.taskcard}>
              <div className={dashboardStyle.taskcardTitle}>
                <div className={dashboardStyle.numIcon}>0</div>
                <div className={dashboardStyle.titleCon}>
                  <p style={{fontSize:"16px", marginBottom:"8px"}}>Mapping Xero Account</p>

                  <p className={dashboardStyle.explainText}>
                    After mapping with your xero account, you can sync stock
                    change, sale order, invoice to your Xero system
                    automatically.
                  </p>
                  <a
                    href={`https://support.stockunify.com/guide/map-xero-accounts`}
                    className={billingStyle.termLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    How to map Xero accounts?
                  </a>
                  <a
                    href={`https://support.stockunify.com/guide/import-products-from-xero`}
                    className={billingStyle.termLink}
                    target="_blank"
                    style={{ marginLeft: "20px" }}
                    rel="noreferrer"
                  >
                    How to import data from Xero?
                  </a>
                </div>
              </div>
              {tasks.xero === "configured" ? (
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", }}>
                <CompleteTag status="Configured" />
                <SimpleBlueButtonLink
                    name="Import Data From Xero"
                    path="/integration/xero/import"
                  />
       
                </div>
                
              ) : (
                <div className={dashboardStyle.taskBtnCon}>
                  <SimpleBlueButtonLink
                    name="Mapping Xero Accounts"
                    path="/integration/xero"
                  />
                
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          <div className={dashboardStyle.taskcard}>
            <div className={dashboardStyle.taskcardTitle}>
              <div className={dashboardStyle.numIcon}>1</div>
              <div className={dashboardStyle.titleCon}>
                <p style={{fontSize:"16px", marginBottom:"8px"}}>Add a New Product</p>
                <a
                  href={`https://support.stockunify.com/guide/create-new-product`}
                  className={billingStyle.termLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  How to add a new product?
                </a>
              </div>
            </div>
            {tasks.product === "completed" ? (
              <CompleteTag status="Completed" />
            ) : (
              <div className={dashboardStyle.taskBtnCon}>
                <SimpleBlueButtonLink
                  name="Add a New Product"
                  path="/inventory/new"
                />
              </div>
            )}
          </div>
          <div className={dashboardStyle.taskcard}>
            <div className={dashboardStyle.taskcardTitle}>
              <div className={dashboardStyle.numIcon}>2</div>
              <div className={dashboardStyle.titleCon}>
                <p style={{fontSize:"16px", marginBottom:"8px"}}>Initialize Stock Level</p>
                <a
                  href={`https://support.stockunify.com/guide/Initialize-product-stock-level`}
                  className={billingStyle.termLink}
                  target="_blank"
                  rel="noreferrer"
  
                >
                  How to initialize stock level?
                </a>
              </div>
            </div>

            {tasks.stockAdjust === "completed" ? (
              <CompleteTag status="Completed" />
            ) : tasks.stockAdjust === "skipped" ? (
              <SkipTag status="Skipped" />
            ) : (
              <div className={dashboardStyle.taskBtnCon}>
                <SimpleBlueButtonLink
                  name="Initial Stock Level"
                  path="/inventory/bulk-stock-adjustment/new"
                />
                <button
                  className={dashboardStyle.skipButton}
                  onClick={() => skipAction("stockAdjust")}
                >
                  Skip
                </button>
              </div>
            )}
          </div>
          <div className={dashboardStyle.taskcard}>
            <div className={dashboardStyle.taskcardTitle}>
              <div className={dashboardStyle.numIcon}>3</div>
              <div className={dashboardStyle.titleCon}>
                <p style={{fontSize:"16px", marginBottom:"8px"}}>Add a Purchase Order</p>
                <a
                  href={`https://support.stockunify.com/guide/create-new-purchase-order`}
                  className={billingStyle.termLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  How to add a new purchase order?
                </a>
                <a
                  href={`https://support.stockunify.com/guide/receive-stock`}
                  className={billingStyle.termLink}
                  style={{ marginLeft: "20px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  How to reveive stock?
                </a>
              </div>
            </div>
            {tasks.purchaseOrder === "completed" ? (
              <CompleteTag status="Completed" />
            ) : tasks.purchaseOrder === "skipped" ? (
              <SkipTag status="Skipped" />
            ) : (
              <div className={dashboardStyle.taskBtnCon}>
                <SimpleBlueButtonLink
                  name="Add a Purchase Order"
                  path="/purchase/order/new"
                />
                <button
                  className={dashboardStyle.skipButton}
                  onClick={() => skipAction("purchaseOrder")}
                >
                  Skip
                </button>
              </div>
            )}
          </div>
          <div className={dashboardStyle.taskcard}>
            <div className={dashboardStyle.taskcardTitle}>
              <div className={dashboardStyle.numIcon}>4</div>
              <div className={dashboardStyle.titleCon}>
                <p style={{fontSize:"16px", marginBottom:"8px"}}>Add a Sale Order</p>
                <a
                  href={`https://support.stockunify.com/guide/create-new-sale-order`}
                  className={billingStyle.termLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  How to add a new sale order?
                </a>
              </div>
            </div>

            {tasks.saleOrder === "completed" ? (
              <CompleteTag status="Completed" />
            ) : tasks.saleOrder === "skipped" ? (
              <SkipTag status="Skipped" />
            ) : (
              <div className={dashboardStyle.taskBtnCon}>
                <SimpleBlueButtonLink
                  name="Add a Sale Order"
                  path="/sale/order/new"
                />
                <button
                  className={dashboardStyle.skipButton}
                  onClick={() => skipAction("saleOrder")}
                >
                  Skip
                </button>
              </div>
            )}
          </div>

          {!Object.values(tasks).includes("in_progress") ? (
            <div style={{ marginTop: "20px" }}>
              <p style={{ color: "var(--indicate-line)" }}>
                Congratulations! You have completed all the tasks.
              </p>
              <p style={{ marginTop: "20px" }}>
                There are more popular topics you may interested!
              </p>
              <div style={{ marginTop: "6px" }}>
                <a
                  href={`https://support.stockunify.com/guide/invite-new-user`}
                  className={billingStyle.termLink}
                  style={{ marginRight: "20px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  How to invite a new user?
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : error ? (
        <ErrorMessage mess={error} />
      ) : (
        <></>
      )}

      <div style={{ width: "30%" }}>
        <SimpleOutlineButton
          name="Delete Trial Account Data"
          action={openDeleteDialog}
        />
      </div>
    </div>
  );
}
