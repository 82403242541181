import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonStyle from "../../../style/commonStyle.module.css";
import { ScreenTitle, SelectedFilterButton } from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Pagination from "../../../components/Pagination";
import {
  ErrorMessage,
  Loader,
  Tag,
  ViewDateText,
  DollarTag,
  SuccessSyncToXero,
  FailedSyncToXero,
} from "../../../components/viewComponents";
import { purchaseOrderListRequest } from "../../../actions/purchaseOrderActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { Search } from "../../../components/inputFields";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import {
  CloneLink,
  DeleteOrderButton,
  EditLink,
  PreviewLink,
  ReceiveStockLink,
} from "./poActionButtons";
import { NavLink, useNavigate } from "react-router-dom";
import { PurchaseOrderListFilter } from "../../../components/filters";
import { formatDate } from "../../../functions/functions";

export default function PurchaseOrderListScreen() {
  useDocumentTitle("Manage Purchase Orders");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, purchaseOrders, error, success } = useSelector(
    (state) => state.purchaseOrderList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  // search
  const [keyWords, setKeywords] = useState("");

  const [hasXeroBill, setHasXeroBill] = useState(false);
  const [refreshCnt, setRefreshCnt] = useState(0);

  const [filterDeleteCnt, setFilterDeleteCnt] = useState(0);
  // filter
  let initialFilterData = {
    status: [],
    location: [],
    startDate: "",
    endDate: "",
  };
  const [selectedFilterData, setSelectedFilterData] = useState(initialFilterData);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const applyFilter = (values) => {
    setSelectedFilterData(values);
    setPageNum(0);
  };

  const deleteFilterOption = (type, index) => {
    let copy = { ...selectedFilterData };
    let dataCopy = copy[type];
    dataCopy.splice(index, 1);
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  const deleteStartDate = () => {
    let copy = { ...selectedFilterData };
    copy.startDate = ""
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };
  const deleteEndDate = () => {
    let copy = { ...selectedFilterData };
    copy.endDate = ""
    setSelectedFilterData(copy);
    setFilterDeleteCnt(filterDeleteCnt + 1);
    setPageNum(0);
  };

  useEffect(() => {
    if (userDetailsSuccess) {
      if (
        userDetails.data.orgInfo.xeroConfigured &&
        userDetails.data.orgInfo.xeroConnected
      ) {
        setHasXeroBill(true);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    let selectedFilterStatus = [];
    let selectedFilterLocation = [];
    let startTimestamp = "";
    let endTimestamp = "";

    if (selectedFilterData.startDate) {
      startTimestamp = Math.floor(
        new Date(selectedFilterData.startDate).setHours(0, 0, 0, 0) / 1000
      );
    }
    if (selectedFilterData.endDate) {
      endTimestamp = Math.floor(
        new Date(selectedFilterData.endDate).setHours(23, 59, 59, 999) / 1000
      );
    }

    if (Number(endTimestamp) > 0 && Number(startTimestamp) > 0) {
      if (Number(startTimestamp) > Number(endTimestamp)) {
        // if start date is greater than end date, remove end date
        endTimestamp = "";
      }
    }

    if (selectedFilterData.location.length > 0) {
      selectedFilterData.location.forEach((loc) => {
        selectedFilterLocation.push(loc.id);
      });
    }

    if (selectedFilterData.status.length > 0) {
      selectedFilterData.status.forEach((s) => {
        selectedFilterStatus.push(s.value);
      });
    }

    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("purchase_order_list")){
        dispatch(purchaseOrderListRequest(
          keyWords, 
          pageNum, 
          pageSize, 
          "all", 
          selectedFilterStatus.join(),
          selectedFilterLocation.join(),
          startTimestamp,
          endTimestamp));
      }else{
        dispatch(purchaseOrderListRequest(
          keyWords, 
          pageNum, 
          pageSize, 
          "creator", 
          selectedFilterStatus.join(),
          selectedFilterLocation.join(),
          startTimestamp,
          endTimestamp));
      }
    }
  }, [dispatch, userDetailsSuccess, pageNum, keyWords, selectedFilterData, refreshCnt, filterDeleteCnt]);

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  const deleteSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const naviogateToOrderDetails = (orderId) => {
    navigate(`/purchase/order/preview/${orderId}`, { replace: false });
  }


  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="All Purchase Orders" />
      <ScreenTitle title="All Purchase Orders" />
      {
        userDetailsSuccess &&  
        <div className={commonStyle.pageContentContainer}>
          <div className="w-full flex">
          <div className="flex-grow">
          <Search
            placeholdertext="Type order number / supplier name to search..."
            initialQuery={keyWords}
            onChange={handleSearchChange}
          />
          </div>
          <div>
            <PurchaseOrderListFilter
              onSelect={applyFilter}
              initialFilter={selectedFilterData}
              onDelete={filterDeleteCnt}
            />
          </div>
        </div>

        <div className="w-full flex flex-row mt-4">
          {selectedFilterData.location.length > 0 ? (
            <div className="w-fit flex flex-row">
              <span>Location:</span>
              {selectedFilterData.location.map((x, index2) => (
                <SelectedFilterButton key={index2} name={x.value} action={() => deleteFilterOption("location", index2)} />
              ))}
            </div>
          ) : (
            <></>
          )}
          {selectedFilterData.status.length > 0 ? (
            <div className="w-fit flex flex-row">
              <span>Status:</span>
              {selectedFilterData.status.map((x, index3) => (
                  <SelectedFilterButton key={index3} name={x.value} action={() => deleteFilterOption("status", index3)} />
              ))}
            </div>
          ) : (
            <></>
          )}
          {selectedFilterData.startDate && (
            <div className="w-fit flex flex-row">
              <span>Start Date:</span>
              <SelectedFilterButton name={formatDate(new Date(selectedFilterData.startDate),userDetails.data.orgInfo.date_format,
              userDetails.data.orgInfo.time_zone)} action={deleteStartDate} />
            </div>
          )}
          {selectedFilterData.endDate && (
            <div className="w-fit flex flex-row">
              <span>End Date:</span>
              <SelectedFilterButton name={formatDate(new Date(selectedFilterData.endDate),userDetails.data.orgInfo.date_format,
              userDetails.data.orgInfo.time_zone)} action={deleteEndDate} />
            </div>
          )}
        </div>

        {loading ? (
          <Loader mess="Requesting purchase order list, please wait a moment..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the purchase order list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Issue Date</th>
                  <th>Supplier</th>
                  {purchaseOrders.hasProjects ? <th>Project</th> : <></>}
                  <th>Location</th>
                  <th>Total Cost</th>
                  <th>Creator</th>
                  <th>Status</th>
                  <th>Attachments</th>
                  {hasXeroBill && <th>Sent to Xero</th>}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrders.orders.length > 0 ? (
                  purchaseOrders.orders.map((item, index) => (
                    <tr key={index}>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                          {item.orderNumber}
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <ViewDateText value={item.issueDate} />
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">{item.supplier}</td>
                      {purchaseOrders.hasProjects ? (
                        <td className="max-w-[120px] truncate hover:cursor-pointer" onClick={() => naviogateToOrderDetails(item.id)}>
                          {item.projectCode ? (
                            <span>
                              {item.projectCode} - {item.projectName}
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <></>
                      )}
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <Tag name={item.location} color="gray" />
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <DollarTag />
                        {item.totalCostInclTax}
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        {item.creatorFirstName}{" "}
                        {item.creatorLastName ? item.creatorLastName : ""}
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        <div className="flex flex-row items-center justify-start">
                          <div>
                        {item.status === "Approved" ? (
                          <Tag name={item.status} color="green" />
                        ) : item.status === "Draft" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Placed" ? (
                          <Tag name={item.status} color="yellow" />
                        ) : item.status === "Completed" ? (
                          <Tag name={item.status} color="blue" />
                        ) : (
                          <></>
                        )}
                        </div>
                        {
                          item.sent && item.sent === 1 ?<div> <Tag name={"Sent"} color="blue" /> </div>: <></>
                        }
                        </div>
                      </td>
                      <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                        {item.files && item.files.length > 0 ? (
                          <OrderAttachmentsPreview
                            files={item.files}
                            style="list"
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      {hasXeroBill && (
                        <td onClick={() => naviogateToOrderDetails(item.id)} className="hover:cursor-pointer">
                          {item.billStatus && item.billStatus === "ISSUED" ? (
                            <SuccessSyncToXero />
                          ) : item.xeroTaskError ? (
                            <FailedSyncToXero mess={item.xeroTaskError} />
                          ) : (
                            <></>
                          )}
                        </td>
                      )}
                      {/* according to different status show different actions */}
                      <td>
                        <div className={commonStyle.actionsGrop}>
                          {item.status !== "Completed" && (
                            <EditLink orderId={item.id} buttonView={false}/>
                          )}
                          <PreviewLink orderId={item.id} buttonView={false}/>
                          {item.status === "Approved" && (
                            <ReceiveStockLink orderId={item.id} buttonView={false}/>
                          )}
                          <CloneLink orderId={item.id} buttonView={false}/>
                          {item.status !== "Completed" && (
                            <DeleteOrderButton
                              buttonView={false}
                              orderId={item.id}
                              deleteSuccess={deleteSuccessAction}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      {" "}
                      <p>
                        There are no purchase order available, create a new
                        purchase order now.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {purchaseOrders.orders.length > 0 ? (
              <Pagination
                totalPageNum={purchaseOrders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
      }

    </div>
  );
}
