import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  PURCHASE_ORDER_LIST_FAIL,
  PURCHASE_ORDER_LIST_REQUEST,
  PURCHASE_ORDER_LIST_SUCCESS,
  PURCHASE_ORDER_DETAILS_FAIL,
  PURCHASE_ORDER_DETAILS_REQUEST,
  PURCHASE_ORDER_DETAILS_SUCCESS,
  PURCHASE_ORDER_OPTIONS_FAIL,
  PURCHASE_ORDER_OPTIONS_REQUEST,
  PURCHASE_ORDER_OPTIONS_SUCCESS,
  SEARCH_PURCHASE_PRODUCT_FAIL,
  SEARCH_PURCHASE_PRODUCT_REQUEST,
  SEARCH_PURCHASE_PRODUCT_SUCCESS,
  PURCHASE_ORDER_CREATE_REQUEST,
  PURCHASE_ORDER_CREATE_SUCCESS,
  PURCHASE_ORDER_CREATE_FAIL,
  PURCHASE_ORDER_EDIT_REQUEST,
  PURCHASE_ORDER_EDIT_SUCCESS,
  PURCHASE_ORDER_EDIT_FAIL,
  RECEIVE_STOCK_REQUEST,
  RECEIVE_STOCK_SUCCESS,
  RECEIVE_STOCK_FAIL,
  LATEST_PRODUCT_PURCHASE_DETAILS_REQUEST,
  LATEST_PRODUCT_PURCHASE_DETAILS_SUCCESS,
  LATEST_PRODUCT_PURCHASE_DETAILS_FAIL,
  PURCHASE_ORDER_ACTIONS_REQUEST,
  PURCHASE_ORDER_ACTIONS_SUCCESS,
  PURCHASE_ORDER_ACTIONS_FAIL,
  PURCHASE_ORDER_EDIT_AFTER_RECEIVE_REQUEST,
  PURCHASE_ORDER_EDIT_AFTER_RECEIVE_SUCCESS,
  PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL,
} from "../constants/purchaseOrderConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const purchaseOrderOptionsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
  

    dispatch({ type: PURCHASE_ORDER_OPTIONS_REQUEST });
    axios
      .get(SERVER_URL + `/purchase_order/options`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: PURCHASE_ORDER_OPTIONS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error(
              "Request purchase order options failed, please try again later."
            ),
            { code: 208 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: PURCHASE_ORDER_OPTIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: PURCHASE_ORDER_OPTIONS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const searchPurchaseProductRequest =
  (keywords, productType) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: SEARCH_PURCHASE_PRODUCT_REQUEST });
      axios
        .get(SERVER_URL + `/product/search?keyword=${keywords}&productType=${productType}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: SEARCH_PURCHASE_PRODUCT_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error("Search product failed, please try again later."),
              { code: 201 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SEARCH_PURCHASE_PRODUCT_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SEARCH_PURCHASE_PRODUCT_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const createNewPurchaseOrderRequest =
  (values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    try {
      

      dispatch({ type: PURCHASE_ORDER_CREATE_REQUEST });
      axios
        .post(SERVER_URL + `/purchase_order/create`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: PURCHASE_ORDER_CREATE_SUCCESS, payload: response.data.data });
          } else if (response.data.error === 40) {
            throw Object.assign(
              new Error(
                "Order quantity must be greater than zero, please change the order quantity."
              ),
              { code: 202 }
            );
          } else if (response.data.error === 23) {
            throw Object.assign(
              new Error(
                response.data.message
              )
            );
          } else {
            throw Object.assign(
              new Error(
                "Create new purchase order failed, please try again later."
              ),
              { code: 203 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: PURCHASE_ORDER_CREATE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PURCHASE_ORDER_CREATE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

// get purchase order list
export const purchaseOrderListRequest =
  (keywords, pageNum, pageSize, range, status = '', location = '', startTime = '', endTime = '') => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PURCHASE_ORDER_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/purchase_order/list?keyword=${keywords}&page_num=${pageNum}&page_size=${pageSize}&range=${range}&status=${status}&location=${location}&startTime=${startTime}&endTime=${endTime}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: PURCHASE_ORDER_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else if (response.data.error === 6){
            throw Object.assign(
              new Error("Start date cannot be greater than end date"),
              { code: 3051 }
            );
          } else {
            throw Object.assign(
              new Error(
                "Request purchase order list failed, please try again later."
              ),
              { code: 204 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: PURCHASE_ORDER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PURCHASE_ORDER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const purchaseOrderDetailsRequest =
  (poid, type) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: PURCHASE_ORDER_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/purchase_order/${poid}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            let orderInfo = response.data.data;


            let productsTotal = parseFloat(orderInfo.orderItems.reduce(
              (preTotal, item) => Number(preTotal) + Number(item.totalCost),
              0
            ).toFixed(2));
            let additionalCostTotal = parseFloat(orderInfo.orderCosts.reduce(
              (preTotal, item) => Number(preTotal) + Number(item.cost),
              0
            ).toFixed(2));

            let orderSummary = {
              productsTotal: productsTotal,
              additionalCostTotal: additionalCostTotal,
            };

            orderInfo["orderSummary"] = orderSummary;

            // auto fill the received stock col with ordered qty when proceed receive stock
            if (type === "receiveStock") {
              orderInfo.orderItems.forEach((item) => {
                item.receivedQuantity = Number(item.quantity);
                item.batchNumbers = [];
                item.serialNumbers = [];
              });
            }

            if (
              type === "clone" &&
              orderInfo.basicInfo.status === "Completed"
            ) {
              orderInfo.orderItems.forEach((item) => {
                item.quantity = item.receivedQuantity;
              });
            }

            for(const item of orderInfo.orderItems){
              item.unitCost = Number(item.unitCost);
              item.discount = Number(item.discount);
              item.quantity = Number(item.quantity);
              item.totalCost = Number(item.totalCost);
              item.unitLatestCost = Number(item.latestCost);
            } 

            dispatch({
              type: PURCHASE_ORDER_DETAILS_SUCCESS,
              payload: orderInfo,
            });
          } else {
            throw Object.assign(
              new Error(
                "Request purchase order details failed, please try again later."
              ),
              { code: 205 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: PURCHASE_ORDER_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PURCHASE_ORDER_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const editPurchaseOrderRequest =
  (values, poid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PURCHASE_ORDER_EDIT_REQUEST });
      axios
        .put(SERVER_URL + `/purchase_order/${poid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: PURCHASE_ORDER_EDIT_SUCCESS, payload: response.data.data });
          } else if (response.data.error === 40) {
            throw Object.assign(
              new Error(
                "Order quantity must be greater than zero, please change the order quantity."
              ),
              { code: 202 }
            );
          } else if (response.data.error === 23) {
            throw Object.assign(
              new Error(
                response.data.message
              )
            );
          } else {
            throw Object.assign(
              new Error(
                "Update purchase order failed, please try again later."
              ),
              { code: 206 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: PURCHASE_ORDER_EDIT_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PURCHASE_ORDER_EDIT_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const receiveStockRequest =
  (values, poid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: RECEIVE_STOCK_REQUEST });
      axios
        .put(
          SERVER_URL + `/purchase_order/receive_stock/${poid}`,
          values,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: RECEIVE_STOCK_SUCCESS });
          } else {
            throw Object.assign(
              new Error("Receive stock failed, please try again later."),
              { code: 207 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: RECEIVE_STOCK_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: RECEIVE_STOCK_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const latestProductPurchaseDetailsRequest =
  (famid, variantCode) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
    

      dispatch({ type: LATEST_PRODUCT_PURCHASE_DETAILS_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/latest_purchase_details?famid=${famid}&variant_code=${variantCode}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: LATEST_PRODUCT_PURCHASE_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error(
                "Request purchase order details failed, please try again later."
              ),
              { code: 205 }
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: LATEST_PRODUCT_PURCHASE_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: LATEST_PRODUCT_PURCHASE_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const purchaseOrderActionsRequest =
  (poid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PURCHASE_ORDER_ACTIONS_REQUEST });
      axios
        .get(SERVER_URL + `/purchase_order/actions/${poid}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) { 
            dispatch({
              type: PURCHASE_ORDER_ACTIONS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw Object.assign(
              new Error(
                "Request purchase order actions failed, please try again later."
              ),
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: PURCHASE_ORDER_ACTIONS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PURCHASE_ORDER_ACTIONS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };
  export const editPurchaseOrderAfterReceiveRequest =
  (values, poid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: PURCHASE_ORDER_EDIT_AFTER_RECEIVE_REQUEST });
      axios
        .post(SERVER_URL + `/purchase_order/after-receive-edit/${poid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: PURCHASE_ORDER_EDIT_AFTER_RECEIVE_SUCCESS, payload: response.data.data });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: PURCHASE_ORDER_EDIT_AFTER_RECEIVE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

