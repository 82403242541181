import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import {
  productListReportRequest
} from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import {
  StyledMultiSelect
} from "../../components/inputFields";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  VariantSkuTag,
  VariantTag
} from "../../components/viewComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";
import { productOptionsRequest } from "../../actions/productActions";

export default function ProductListReportScreen() {
  useDocumentTitle("Products Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const {
    loading: optionsLoading,
    options,
    error: optionsError,
    success: optionsSuccess,
  } = useSelector((state) => state.productOptions);

  // pagination

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const [products, setProducts] = useState([]);
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };


  const [filterCategory, setFilterCategory] = useState([]);
  const [showCost, setShowCost] = useState(false);

  useEffect(() => {
    if(!options){
      dispatch(productOptionsRequest());
    }
  }, [dispatch,options]);

  useEffect(() => {
    if(userDetailsSuccess){
      if(userDetails.data.permissions.includes("view_product_cost")){
        setShowCost(true);
      }else{
        setShowCost(false);
      }
    }
  }, [userDetailsSuccess]);


  const applyFilter = () => {
    let selectedFilterCategory = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }
    getProducts();
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    let stockListdata = [];

    let selectedFilterCategory = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productListReportRequest(
        0,
        99999999999,
        selectedFilterCategory.join(),
        config
      );

      if (responseData.success) {
        stockListdata = responseData.data.products;

        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvString = "";
    if(showCost){
      let csvWriter = new CsvWriter([
        "Code",
        "ProductName",
        "Category",
        "Barcode",
        "Sale Price",
        "Latest Cost",
        "Reorder-Level",
      ]);
      for (let line of stockListdata) {
        csvWriter.addLine([
          line.variantSku,
          line.name,
          line.category ? line.category : "",
          line.barcode,
          line.sale_price,
          line.latest_cost,
          line.reorder_level,
        ]);
      }
      csvString = csvWriter.content;
    }else{
      let csvWriter = new CsvWriter([
        "Code",
        "ProductName",
        "Category",
        "Barcode",
        "Sale Price",
        "Reorder-Level",
      ]);
      for (let line of stockListdata) {
        csvWriter.addLine([
          line.variantSku,
          line.name,
          line.category ? line.category : "",
          line.barcode,
          line.sale_price,
          line.reorder_level,
        ]);
      }
      csvString = csvWriter.content;
    }

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "product-list-report.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, [pageNum]);

  const getProducts = async () => {
    let selectedFilterCategory = [];

    if (filterCategory.length > 0) {
      filterCategory.forEach((cate) => {
        selectedFilterCategory.push(cate.id);
      });
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await productListReportRequest(
        pageNum,
        pageSize,
        selectedFilterCategory.join(),
        config
      );

      if (responseData.success) {
        setProducts(responseData.data);
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Products Report" />

      <ScreenTitle
        title="Products Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "100%", marginRight:"20px" }}>
            <StyledMultiSelect
                label="Category"
                selectOptions={optionsSuccess ? options.category : []}
                value={filterCategory}
                onChange={(category) => setFilterCategory(category)}
              />
            </div>
            {/* <div style={{ width: "45%" }}>
            
            </div> */}
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success ? (
          <div className={commonStyle.longDataTable}>
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                <th>Code</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Barcode</th>
                  <th>Sale Price</th>
                  {showCost && <th>Latest Cost</th>}
                  <th>Re-order Level</th>
                </tr>
              </thead>
              <tbody>
                {products.products.length > 0 ? (
                  products.products.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>{item.variantSku && <VariantSkuTag name={item.variantSku} />}</td>
                        <td>{item.name}</td>
                        <td>{item.category}</td>
                        <td>{item.barcode}</td>
                        <td><DollarTag />{item.sale_price}</td>
                        {showCost && <td><DollarTag />{item.latest_cost}</td>}
                        <td>{item.reorder_level}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {products.products.length > 0 ? (
              <Pagination
                totalPageNum={products.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
