import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersListRequest } from "../../../actions/userRoleActions";
import {
  AddLinkButton,
  EditButtonLink,
  ViewButtonLink,
  ScreenTitle,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  Loader,
  RoleListTag,
  Tag,
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";

export default function UsersSettings() {
  useDocumentTitle("Users");
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(usersListRequest());
  }, [dispatch]);

  // get roles list
  const { loading, usersList, error, success } = useSelector(
    (state) => state.usersList
  );

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Users" />
      <ScreenTitle title="Users" />

      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting user list, please wait a moment..." />
        ) : success ? (
          <>
            <div className={commonStyle.longDataTable}>
              {userDetailsSuccess ? (
                userDetails.data.permissions.includes(
                  "add_delete_edit_users"
                ) ? (
                  Number(usersList.activeUserNum) < Number(usersList.userNum) ? (
                    <AddLinkButton name="New User" path="/settings/users/new" />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {/* hold the tax rates table */}
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Location</th>
                    <th>Phone</th>
                    <th>Position</th>
                    <th>Department</th>
                    <th>Roles</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList.users.length > 0 ? (
                    usersList.users.map((user) => (
                      <tr key={user.id}>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.userEmail}</td>
                        <td>{user.location.label}</td>
                        <td>{user.userPhone}</td>
                        <td>{user.position}</td>
                        <td>{user.department.label}</td>
                        <td style={{ width: "30%" }}>
                          <RoleListTag userRoles={user.roles} />
                        </td>
                        <td>
                          {Number(user.isActivated) === 1 ? (
                            <Tag name="Active" color="green" />
                          ) : Number(user.isActivated) === 0 ?  (
                            <Tag name="Waiting to Activate" color="yellow" />
                          ): Number(user.isActivated) === -1 ?  (
                            <Tag name="Removed" color="gray" />
                          ): <></>}
                        </td>
                        <td>
                          <div className={commonStyle.actionsGrop}>
                            <ViewButtonLink
                              path={`/settings/users/preview/${user.id}`}
                              data={user}
                            />
                            {userDetailsSuccess ? (
                              userDetails.data.permissions.includes(
                                "add_delete_edit_users"
                              ) && Number(user.isActivated) !== -1 ? (
                                <>
                                  <EditButtonLink
                                    path={`/settings/users/edit/${user.id}`}
                                    data={user}
                                  />
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td>
                          There are no users available. Please add a new user
                          now.
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
