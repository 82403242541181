import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  editSaleOrderAfterApproveRequest,
  saleOrderDetailsRequest,
  saleOrderOptionsRequest
} from "../../../actions/saleOrderActions";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { ScreenTitle } from "../../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  SimpleDialog,
  TextErrorMessage
} from "../../../components/viewComponents";
import commonStyle from "../../../style/commonStyle.module.css";

import useDocumentTitle from "../../../useDocumentTitle";
import SaleOrderFormBasicInfo from "./SaleOrderFormBasicInfo";

export default function EditSaleOrderAfterApproveScreen() {
  useDocumentTitle("Edit Sale Order");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id: soid } = useParams();
  const { loading, options, error, success } = useSelector(
    (state) => state.saleOrderOptions
  );

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: detailsLoading,
    orderInfo,
    error: detailsError,
    success: detailsSuccess,
  } = useSelector((state) => state.saleOrderDetails);

  useEffect(() => {
    dispatch(saleOrderOptionsRequest());
    if (soid) {
      dispatch(saleOrderDetailsRequest(soid, "edit"));
    }
  }, [dispatch, soid]);

  const {
    loading: editLoading,
    orderId,
    error: editError,
    success: editSuccess,
  } = useSelector((state) => state.editSaleOrderAfterApprove);

  const orderBasicInfo = () => {
    let find_salesperson = options.userOptions.find(
      (x) => x.id === orderInfo.basicInfo.salespersonId
    );

    let data = {
      id: orderInfo.basicInfo.id,
      noCustomer: orderInfo.basicInfo.noCustomer,
      issueDate: orderInfo.basicInfo.issueDate,
      orderNumber: orderInfo.basicInfo.orderNumber,
      suInvoice: orderInfo.basicInfo.suInvoice,
      invNumber: orderInfo.basicInfo.invoiceNumberSu,
      customer: options.customerOptions.filter(
        (x) => x.id === orderInfo.basicInfo.customerId
      )[0],
      salesperson: find_salesperson ? find_salesperson : "",
      taxRate: options.taxrateOptions.filter(
        (x) => x.rate === orderInfo.basicInfo.taxrate
      )[0],
      taxIncluded: orderInfo.basicInfo.taxIncluded,
      currency: orderInfo.basicInfo.currency,
      customerName: orderInfo.basicInfo.customerName
        ? orderInfo.basicInfo.customerName
        : "",
      customerBillingName: orderInfo.basicInfo.customerBillingName
        ? orderInfo.basicInfo.customerBillingName
        : "",
      firstName: orderInfo.basicInfo.firstName
        ? orderInfo.basicInfo.firstName
        : "",
      lastName: orderInfo.basicInfo.lastName
        ? orderInfo.basicInfo.lastName
        : "",
      email: orderInfo.basicInfo.email ? orderInfo.basicInfo.email : "",
      phone: orderInfo.basicInfo.phone ? orderInfo.basicInfo.phone : "",
      postalAddress: orderInfo.basicInfo.shippingAddress
        ? orderInfo.basicInfo.shippingAddress
        : {},
      billingAddress: orderInfo.basicInfo.billingAddress
        ? orderInfo.basicInfo.billingAddress
        : {},
      location: options.locationOptions.filter(
        (x) => x.id === orderInfo.basicInfo.locationId
      )[0],
      paymentTerm: orderInfo.basicInfo.paymentTerm,
      priceTier: options.priceTierOptions.filter(
        (x) => x.id === orderInfo.basicInfo.priceTierId
      )[0],
      project: options.projectsOptions.filter(
        (x) => x.id === orderInfo.basicInfo.project_id
      ).length
        ? options.projectsOptions.filter(
            (x) => x.id === orderInfo.basicInfo.project_id
          )[0]
        : "",
      isNeedShipping: orderInfo.basicInfo.isNeedShipping,
      notes: orderInfo.basicInfo.notes ? orderInfo.basicInfo.notes : "",
      status: orderInfo.basicInfo.rental_details,
    };
    return data;
  };

  const orderFiles = () => {
    let copy = [...orderInfo.orderFiles];
    let data = [];
    copy.forEach((x) => {
      data.push({
        fileName: x.fileName,
        uri: x.uri,
        loading: false,
        error: "",
      });
    });
    return data;
  };

  // edit order submit values
  const [dialog, setDialog] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// submit //////////////////////////////////////////////
  const openEditSaleOrderDialog = (values) => {

    if (typeof values.orderInfo.postalAddress === "object") {
      values.orderInfo.postalAddress = JSON.stringify(
        values.orderInfo.postalAddress
      );
    }
    if (typeof values.orderInfo.billingAddress === "object") {
      values.orderInfo.billingAddress = JSON.stringify(
        values.orderInfo.billingAddress
      );
    }

    let updatedValues = {
      orderInfo: {
        orderNumber: values.orderInfo.orderNumber,
        invNumber: values.orderInfo.invNumber,
        customerId: values.orderInfo.customer
          ? values.orderInfo.customer.id
          : "",
        salespersonId: values.orderInfo.salesperson
        ? values.orderInfo.salesperson.id
        : "",
        customerName: values.orderInfo.customerName,
        customerBillingName: values.orderInfo.customerBillingName,
        firstName: values.orderInfo.firstName,
        lastName: values.orderInfo.lastName,
        email: values.orderInfo.email,
        phone: values.orderInfo.phone,
        postalAddress: values.orderInfo.postalAddress,
        billingAddress: values.orderInfo.billingAddress,
        paymentTerm: values.orderInfo.paymentTerm,
        projectId: values.orderInfo.project ? values.orderInfo.project.id : "",
        notes: values.orderInfo.notes,
      },
      files: values.files,
    };
    setDialog(true);
    dispatch(editSaleOrderAfterApproveRequest(updatedValues, soid));
  };

  const closeDialogWithSuccess = () => {
    navigate(`/sale/order/preview/${soid}`, {
      replace: true,
      from: location,
    });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title={"Update Sale Order"}
        successMessage="Order updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={editLoading}
        loadingMessage="Processing request..."
        success={editSuccess}
        error={editError}
        confirmAction={closeDialogWithSuccess}
      />

      <Breadcrumbs screenName={"Edit Sale Order"} />
      <ScreenTitle title={"Edit Sale Order"} status="Edit" />
      <div className={commonStyle.pageContentContainer}>
        {userDetailsSuccess ? (
          loading ? (
            <Loader mess="Requesting data, please wait a moment..." />
          ) : success ? (
            detailsLoading ? (
              <Loader mess="Requesting order details, please wait a moment..." />
            ) : detailsSuccess ? (
              <>
                {orderInfo.basicInfo.status === "Approved" ||
                orderInfo.basicInfo.status === "Completed" ? (
                  <SaleOrderFormBasicInfo
                    initialOrderInfo={orderBasicInfo}
                    initialOrderItems={orderInfo.orderItems}
                    initialCosts={orderInfo.orderCosts}
                    initialFiles={orderFiles}
                    initialOrderSummary={orderInfo.orderSummary}
                    onSubmit={openEditSaleOrderDialog}
                    type="edit"
                  />
                ) : (
                  <TextErrorMessage
                    mess={`The order status is "${orderInfo.basicInfo.status}", can not be edited from this page`}
                  />
                )}
              </>
            ) : (
              <ErrorMessage mess={detailsError} />
            )
          ) : (
            <ErrorMessage mess={error} />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
