import React from "react";
import { AiOutlineLock } from "react-icons/ai";

export default function NoPermission({ requiredPermission }) {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <AiOutlineLock className="w-[42px] h-[42px] text-[#f7f7ff]" />
      <p className="text-base mt-2">
      Access to full data requires the <span className="font-medium text-black">[{requiredPermission}]</span> permission.
      </p>
      <p className="text-base ">
      Please contact your administrator to request access.
      </p>
    </div>
  );
}
