import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import inputStyle from "../style/inputFieldsStyle.module.css";
import { BsPlusCircle } from "react-icons/bs";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import "../style/datepickerStyle.css";
import {
  MdOutlineClose,
  MdOutlineSearch,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
// import { useSelector } from "react-redux";

// input component

function StyledLabel({
  label,
}) {
  
  return (
    <div className={inputStyle.styledInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
    </div>
  );
}

function ReadOnlyField({
  label,
  value,
}) {
  
  return (
    <div className={inputStyle.styledInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <p className={inputStyle.styledInputReadonly}>{value}</p>
    </div>
  );
}


function StyledInput({
  type,
  name,
  value,
  label,
  autofocus,
  placeHolder,
  onChange,
  error,
  isReadOnly,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.inputFieldError;
  } else {
    className = inputStyle.inputField;
  }

  return (
    <div className={inputStyle.styledInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        className={className}
        autoFocus={autofocus}
        placeholder={placeHolder}
        readOnly={isReadOnly}
        onChange={(e) => onChange(e.target.value)}
      ></input>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

function StyledPasswordInput({
  name,
  value,
  label,
  autoFocus,
  autocomplete,
  placeHolder,
  onChange,
  error,
  showHints = false,
  readOnly = false,
}) {
  const [showPassword, setShowPassword] = useState(false);
  // handle password field changes
  const [hint1, setHint1] = useState(inputStyle.hintError);
  const [hint2, setHint2] = useState(inputStyle.hintError);
  const [hint3, setHint3] = useState(inputStyle.hintError);
  const [hint4, setHint4] = useState(inputStyle.hintError);
  const [hint5, setHint5] = useState(inputStyle.hintError);

  let className;
  if (error) {
    className = inputStyle.passwordInputContainerError;
  } else {
    className = inputStyle.passwordInputContainer;
  }

  const checkPwd = (e) => {
    const { name, value } = e.target;

    const lowerCaseCount = (value.match(/[a-z]/g) || []).length;
    const upperCaseCount = (value.match(/[A-Z]/g) || []).length;
    const numberCount = (value.match(/\d/g) || []).length;
    const specialCount = (value.match(/[~^@#$!%*?&]/g) || []).length;
    setHint1(value.length > 7 ? inputStyle.hintPassed : inputStyle.hintError);

    setHint2(numberCount > 0 ? inputStyle.hintPassed : inputStyle.hintError);
    setHint3(upperCaseCount > 0 ? inputStyle.hintPassed : inputStyle.hintError);
    setHint4(lowerCaseCount > 0 ? inputStyle.hintPassed : inputStyle.hintError);

    setHint5(specialCount > 0 ? inputStyle.hintPassed : inputStyle.hintError);

    onChange(e.target.value);
  };

  return (
    <div className={inputStyle.styledInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <div className={className}>
        <input
          type={showPassword ? "text" : "password"}
          autoComplete="off"
          name={name}
          value={value}
          autoFocus={autoFocus}
          placeholder={placeHolder}
          onChange={(e) => checkPwd(e)}
          readOnly={readOnly}
        ></input>
        <div
          className={inputStyle.passwordIconCon}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <AiOutlineEye className={inputStyle.passwordIcon} />
          ) : (
            <AiOutlineEyeInvisible className={inputStyle.passwordIcon} />
          )}
        </div>
      </div>
      {error ? <p className={inputStyle.errorMessage}>{error}</p> : <></>}

      {showHints && value.length > 0 ? (
        <div className={inputStyle.passwordHints}>
          <div className={hint1}>
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>At least 8 characters</p>
          </div>
          <div className={hint2}>
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>Contains 1 number</p>
          </div>
          <div className={hint3}>
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>Contains 1 uppercase letter</p>
          </div>
          <div className={hint4}>
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>Contains 1 lowercase letter</p>
          </div>
          <div className={hint5}>
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>Contains 1 special character</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

// input component
function StyledEmailInput({
  type,
  name,
  value,
  label,
  autofocus,
  placeHolder,
  onChange,
  error,
  autocomplete,
  isReadOnly,
}) {
  let className;
  if (error) {
    className = inputStyle.inputFieldError;
  } else {
    className = inputStyle.inputField;
  }

  return (
    <div className={inputStyle.styledInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        readOnly={isReadOnly}
        className={className}
        autoFocus={autofocus}
        placeholder={placeHolder}
        onChange={(e) => onChange(e.target.value)}
      ></input>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

// input component
function StyledPureInput({
  type,
  name,
  value,
  autofocus,
  placeHolder,
  onChange,
  error,
  readOnly,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.inputFieldError;
  } else {
    className = inputStyle.inputField;
  }

  return (
    <div className={inputStyle.styledInput}>
      <input
        type={type}
        name={name}
        value={value}
        className={className}
        autoFocus={autofocus}
        placeholder={placeHolder}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
      ></input>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

function PureTextField({
  type,
  name,
  value,
  autofocus,
  placeHolder,
  onChange,
  error,
  readOnly,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.inputFieldError;
  } else {
    className = inputStyle.inputField;
  }

  return (
    <div className="w-full">
      <input
        type={type}
        name={name}
        value={value}
        className={className}
        autoFocus={autofocus}
        placeholder={placeHolder}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
      ></input>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

// input component
function NoBorderInput({
  type,
  name,
  value,
  autofocus,
  placeHolder,
  onChange,
  error,
  isReadOnly,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.noBorderInputError;
  } else {
    className = inputStyle.noBorderInput;
  }

  return (
    <div>
      <input
        type={type}
        name={name}
        value={value}
        className={className}
        autoFocus={autofocus}
        placeholder={placeHolder}
        onChange={(e) => onChange(e.target.value)}
        readOnly={isReadOnly}
      ></input>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}
// input component
function NoBorderPercentInput({
  type,
  name,
  value,
  autofocus,
  placeHolder,
  onChange,
  error,
  isReadOnly,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.noBorderPercentInputError;
  } else {
    className = inputStyle.noBorderPercentInput;
  }

  return (
    <div>
      <div className={inputStyle.noBorderPercentInputCon}>
        <div className={className}>
          <input
            type={type}
            name={name}
            value={value}
            autoFocus={autofocus}
            placeholder={placeHolder}
            onChange={(e) => onChange(e.target.value)}
            readOnly={isReadOnly}
          ></input>
          <span className={inputStyle.symbol}>%</span>
        </div>
        <span className={inputStyle.offTag}>OFF</span>
      </div>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

// textarea component
function StyledTextarea({
  type,
  value,
  name,
  label,
  placeHolder,
  onChange,
  error,
  presetRows,
  isReadOnly,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.inputFieldError;
  } else {
    className = inputStyle.inputField;
  }
  return (
    <div className={inputStyle.styledInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <textarea
        type={type}
        name={name}
        value={value}
        className={className}
        readOnly={isReadOnly}
        placeholder={placeHolder}
        onChange={(e) => onChange(e.target.value)}
        rows={presetRows ? presetRows : 1}
        style={{ fontFamily: "inherit" }}
      ></textarea>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

// percent input component
function StyledPercentInput({
  type,
  name,
  value,
  label,
  autofocus,
  placeHolder,
  onChange,
  error,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.percentInputContainerError;
  } else {
    className = inputStyle.percentInputContainer;
  }

  return (
    <div className={inputStyle.styledPercentInput}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <div className={className}>
        <input
          type={type}
          name={name}
          value={value}
          className={inputStyle.inputField}
          autoFocus={autofocus}
          placeholder={placeHolder}
          onChange={(e) => onChange(e.target.value)}
        ></input>
        <span>%</span>
      </div>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

// Multi Select component
function StyledMultiSelect({
  value,
  label,
  selectOptions,
  onChange,
  error,
  placeHolder,
}) {
  let className;
  if (error && value.length === 0) {
    className = inputStyle.selectContainerError;
  } else {
    className = inputStyle.selectContainer;
  }
  const handleSelect = (value) => {
    onChange(value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...MultiValueRemoveProps}>
        <MdOutlineClose />
      </components.MultiValueRemove>
    );
  };

  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "none" : "none",
      outline: state.isFocused ? "none" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: "white",
    }),
    option: (base, state) => ({
      ...base,
      color: "var(--font-color)",
      backgroundColor: state.isSelected ? "var(--main-color)" : "white",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--light-hover-color)",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--arrow-color)",
    }),

    multiValue: (base, state) => {
      return state.data.isFixed
        ? {
            ...base,
            border: "1px solid var(--main-color)",
            backgroundColor: "var(--main-color)",
            color: "var(--white-color)",
          }
        : {
            ...base,
            border: "1px dotted var(--main-color)",
            backgroundColor: "var(--white-color)",
            color: "var(--main-color)",
          };
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, color: "var(--white-color)" }
        : { ...base, color: "var(--main-color)" };
    },

    multiValueRemove: (base, state) => {
      return state.data.isFixed
        ? {
            ...base,
            display: "none",
          }
        : {
            ...base,
            padding: "4px 4px",
            height: "100%",
            "&:hover": {
              backgroundColor: "var(--white-color)",
            },
          };
    },
  };

  return (
    <div className={inputStyle.styledMultiSelect}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <div className={className}>
        <Select
          value={value}
          placeholder={placeHolder}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          onChange={(e) => handleSelect(e)}
          isClearable={value ? value.some((v) => !v.isFixed) : true}
          isMulti
          options={selectOptions}
          styles={customStyles}
        />
      </div>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

function StyledSelect({
  value,
  label,
  selectOptions,
  onChange,
  error,
  extractKey,
  placeHolder,
  clearable = true,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.selectContainerError;
  } else {
    className = inputStyle.selectContainer;
  }

  const handleSelect = (value) => {
    onChange(extractKey && value ? value[extractKey] : value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "none" : "none",
      outline: state.isFocused ? "none" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: "white",
      lineHeight: "22px",
    }),
    option: (base, state) => ({
      ...base,
      color: "#2f3842",
      backgroundColor: state.isSelected
        ? "var(--main-color)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--light-hover-color)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className={inputStyle.styledSelect}>
      <label className={inputStyle.inputLabel}>{label}</label>
      <div className={className}>
        <Select
          value={
            extractKey
              ? selectOptions.filter((e) => e[extractKey] === value)
              : value
          }
          // defaultValue={{ label: "Choose one", value: "dsds" }}
          components={{ DropdownIndicator, ClearIndicator }}
          placeholder={placeHolder}
          onChange={(e) => handleSelect(e)}
          isClearable={clearable}
          options={selectOptions}
          styles={customStyles}
        />
      </div>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

function NoBorderSelect({
  value,
  selectOptions,
  onChange,
  error,
  placeHolder,
  clearable,
}) {
  let className;
  if (error && !value) {
    className = inputStyle.selectNoBorderConError;
  } else {
    className = inputStyle.selectNoBorderCon;
  }

  const handleSelect = (value) => {
    onChange(value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused
        ? "1px dashed var(--main-color)"
        : "1px dashed var(--border-color)",
      outline: state.isFocused ? "none" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: "var(--white-color)",
      margin: "8px",
      // lineHeight:"24px",
      paddin: "0px",
    }),
    option: (base, state) => ({
      ...base,
      color: "#2f3842",
      backgroundColor: state.isSelected
        ? "var(--main-color)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--light-hover-color)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div>
      <div className={className}>
        <Select
          value={value}
          components={{ DropdownIndicator, ClearIndicator }}
          placeholder={placeHolder}
          onChange={(e) => handleSelect(e)}
          isClearable={clearable}
          options={selectOptions}
          styles={customStyles}
        />
      </div>
      <p className={inputStyle.errorMessage}>{error}</p>
    </div>
  );
}

// percent input component
function Search({ initialQuery, placeholdertext, onChange }) {
  const shouldSearch = useRef(false);

  const useDebounce = (text, delay) => {
    const [debounce, setDebounce] = useState(text);
    useEffect(() => {
      const timer = setTimeout(() => {
        setDebounce(text);
      }, delay);
      return () => {
        clearTimeout(timer);
      };
    }, [text]);
    return debounce;
  };

  const [query, setQuery] = useState(initialQuery);
  const decouncedText = useDebounce(query, 500);

  const clearQueryAction = () => {
    setQuery("");
  };
  const handleChange = (value) => {
    setQuery(value);
    shouldSearch.current = true;
  };

  useEffect(() => {
    if (shouldSearch.current) {
      onChange(decouncedText);
    }
  }, [decouncedText]);

  return (
    <div className={inputStyle.searchCon}>
      <div className={inputStyle.searchBoxCon}>
        <MdOutlineSearch className="w-4 h-4 mx-2" />
        <input
          value={query}
          placeholder={placeholdertext}
          onChange={(e) => handleChange(e.target.value)}
          className={inputStyle.searchBox}
        />
        <MdOutlineClose
          className="w-4 h-4 mr-2 hover:text-red-500 hover:cursor-pointer"
          onClick={clearQueryAction}
        />
      </div>
      {/* <div
        className="h-11 border border-solid border-brandColor rounded ml-2 flex items-center justify-center px-3 hover:bg-brandColor hover:cursor-pointer hover:text-white"
        onClick={() => searchAction(query)}
      >
        Search
      </div> */}
    </div>
  );
}

function TypingSearchInput({
  value,
  name,
  onChange,
  placeholdertext,
  onFocus,
}) {
  return (
    <div className={inputStyle.typingSearchCon}>
      <div className={inputStyle.typingSearchBoxCon}>
        <MdOutlineSearch className={inputStyle.searchIcon} />
        <input
          value={value}
          placeholder={placeholdertext}
          onChange={(e) => onChange(e.target.value)}
          className={inputStyle.typingSearchBox}
          onFocus={onFocus}
        />
      </div>
    </div>
  );
}

function FilterSearchInput({ value, onChange, placeholdertext }) {
  return (
    <div className={inputStyle.typingSearchCon}>
      <div className={inputStyle.typingSearchBoxCon}>
        <MdOutlineSearch className={inputStyle.searchIcon} />
        <input
          value={value}
          placeholder={placeholdertext}
          onChange={(e) => onChange(e.target.value)}
          className={inputStyle.typingSearchBox}
        />
      </div>
    </div>
  );
}

function DateInput({
  label,
  error,
  disabledPreDate,
  minDay,
  value,
  onChange,
  disableFuturedate,
}) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  // set user timezone, this could be retrieved from your application state or local storage
  const userTimeZone = userDetailsSuccess
    ? userDetails.data.orgInfo.time_zone
    : "Pacific/Auckland";
  const userDateFormat = userDetailsSuccess
    ? userDetails.data.orgInfo.date_format
    : "DD/MM/YYYY";

  let localToday = new Date();
  let userTimeZoneTodayStamp = localToday.toLocaleDateString("en-US", {
    timeZone: userTimeZone,
  });
  // calculate minday by user defined date.
  let userTimeZoneMinday = "";
  userTimeZoneMinday = disabledPreDate
    ? new Date(userTimeZoneTodayStamp)
    : null;
  // calculate max date -> hidden future date
  let userTimeZoneMaxday = "";
  let userTimeZoneToday = new Date(userTimeZoneTodayStamp);

  userTimeZoneMaxday = disableFuturedate
    ? userTimeZoneToday.setDate(userTimeZoneToday.getDate() - 1)
    : null;
  // calculate date format
  let dateFormat = "";

  if (userDateFormat === "MM/DD/YYYY") {
    dateFormat = "MM/dd/yyyy";
  } else if (userDateFormat === "DD/MM/YYYY") {
    dateFormat = "dd/MM/yyyy";
  } else if (userDateFormat === "YYYY/MM/DD") {
    dateFormat = "yyyy/MM/dd";
  } else {
    dateFormat = "dd/MM/yyyy";
  }

  return (
    <div className={inputStyle.datapickerCon}>
      <label className={inputStyle.inputLabel}>{label}</label>

      <div
        className={
          error
            ? `${inputStyle.datapickerInputCon} ${inputStyle.dateEror}`
            : `${inputStyle.datapickerInputCon}`
        }
      >
        <DatePicker
          wrapperClassName={inputStyle.datepicker}
          prevMonthButtonDisabled={disabledPreDate}
          showPopperArrow={false}
          selected={value}
          onChange={(date) => onChange(date)}
          minDate={userTimeZoneMinday}
          maxDate={userTimeZoneMaxday}
          placeholderText="Select..."
          dateFormat={dateFormat}
          disabledKeyboardNavigation
        />
      </div>
      {error && !value ? (
        <p className={inputStyle.errorMessage}>{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
}

function CreateableSelectField({
  name,
  value,
  label,
  options,
  onChange,
  noResultText,
  placeholder = "",
  autoFocus = false,
  readOnly = false,
  error = "",
  clearable = false,
  addBtnName,
  openAddNewModal,
}) {
  const containerRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setKeyword(value);
    setFilterOptions(
      value
        ? options.filter((x) =>
            x.label.toLowerCase().includes(value.toLowerCase())
          )
        : options
    );
    setShowSearchResults(true);
  };

  const handleSelect = (selectedItem) => {
    onChange(selectedItem);
    setShowSearchResults(false);
    setKeyword(selectedItem.label);
  };

  useEffect(() => {
    setKeyword(value ? value.label : "");
  }, [value]);

  useEffect(() => {
    if (options && options.length) {
      setFilterOptions(options);
    }
  }, [options, value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
        if(!value){
          setKeyword("");
        }
        setFilterOptions(options);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value]);

  const clickSelectIcon = (e) => {
    e.preventDefault();
    setFilterOptions(options);
    setShowSearchResults(!showSearchResults);
    if(!value){
      setKeyword("");
    }
  };

  const clickOnFiled = (e) => {
    e.preventDefault();
    const newState = !showSearchResults;
    if (!newState && !value) {
      setKeyword(""); // Clear keyword if closing and no value is selected
    }
    setShowSearchResults(newState);
    setFilterOptions(options);
  };

  const clearSelect = (e) => {
    e.preventDefault();
    setKeyword("");
    setFilterOptions(options);
    onChange("");
  };

  const handleCreate = (e) => {
    e.preventDefault();
    openAddNewModal();
    setShowSearchResults(false);
  };


  return (
    <div className="group relative w-full mt-3" ref={containerRef}>
      <div className="w-full">
        <div className="relative w-full">
          <label className="text-[#757474] text-sm">{label}</label>
          <div className="relative flex items-center w-full mt-1">
            <input
              type="text"
              name={name}
              value={keyword}
              onChange={handleChange}
              //onFocus={() => setShowSearchResults(true)}
              onClick={clickOnFiled}
              placeholder={placeholder}
              autoFocus={autoFocus}
              readOnly={readOnly}
              className="w-full text-sm h-10 pl-2 rounded border border-borderGray outline-none hover:border-brandColor focus:border-brandColor placeholder:text-sm placeholder:text-gray hover:cursor-default"
            />
            <button
              className="absolute  h-full right-2 flex items-center justify-end "
              onClick={clickSelectIcon}
            >
              <div className="h-[22px] w-[21px] border-solid border-l border-[#cccccc] flex items-center justify-end">
                <MdKeyboardArrowDown className="h-[28px] text-[#cccccc]" />
              </div>
            </button>
            {keyword && clearable && (
              <button
                className="absolute right-[38px] flex items-center justify-center"
                onClick={clearSelect}
              >
                <MdClear className="text-[#cccccc] hover:text-red-500" />
              </button>
            )}
          </div>
          {error && <p className="w-full text-sm text-red-500 mt-2">{error}</p>}
        </div>
      </div>

      {showSearchResults && (
        <div
          className={`absolute rounded-md border ${
            label ? "top-[70px]" : "top-[48px]"
          } flex w-full justify-between text-black z-10  shadow-md `}
        >
          <div className="w-full max-h-[476px] justify-start rounded bg-white shadow-md py-2 overflow-y-scroll border border-solid border-borderGray">
            <div className="w-full border-b border-solid border-borderGray">
            <button
                  className="text-green-500 flex flex-row items-center pl-2 h-10 hover:underline"
                  onClick={handleCreate}
                >
                  <BsPlusCircle className="mr-1" /> <span>{addBtnName}</span>{" "}
            </button>
            </div>
            {filterOptions.length > 0 ? (
              <ul className="w-full">
                {filterOptions.map((item, index) => (
                  <li
                    key={index}
                    className={`w-full pl-2 h-8 hover:cursor-pointer hover:bg-[#DAE5FB] flex items-center ${ value && (value.id
                       === item.id) ? "bg-brandColor" : "bg-white"
                    }`}
                    onClick={() => handleSelect(item)}
                  >
                    <p className="w-full text-sm rounded">{item.label}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="w-full text-base text-gray h- text-center">
                {noResultText}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function PureDateInput({
  disabledPreDate,
  minDay,
  value,
  onChange,
  disableFuturedate = false,
}) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  // set user timezone, this could be retrieved from your application state or local storage
  const userTimeZone = userDetailsSuccess
    ? userDetails.data.orgInfo.time_zone
    : "Pacific/Auckland";
  const userDateFormat = userDetailsSuccess
    ? userDetails.data.orgInfo.date_format
    : "DD/MM/YYYY";

  let localToday = new Date();
  let userTimeZoneTodayStamp = localToday.toLocaleDateString("en-US", {
    timeZone: userTimeZone,
  });
  // calculate minday by user defined date.
  let userTimeZoneMinday = "";
  userTimeZoneMinday = disabledPreDate
    ? new Date(userTimeZoneTodayStamp)
    : null;
  // calculate max date -> hidden future date
  let userTimeZoneMaxday = "";
  let userTimeZoneToday = new Date(userTimeZoneTodayStamp);

  userTimeZoneMaxday = disableFuturedate
    ? userTimeZoneToday.setDate(userTimeZoneToday.getDate() - 1)
    : null;
  // calculate date format
  let dateFormat = "";

  if (userDateFormat === "MM/DD/YYYY") {
    dateFormat = "MM/dd/yyyy";
  } else if (userDateFormat === "DD/MM/YYYY") {
    dateFormat = "dd/MM/yyyy";
  } else if (userDateFormat === "YYYY/MM/DD") {
    dateFormat = "yyyy/MM/dd";
  } else {
    dateFormat = "dd/MM/yyyy";
  }

  return (
    <div className={"w-full"}>
      <div className={inputStyle.datapickerInputCon}>
        <DatePicker
          wrapperClassName={inputStyle.datepicker}
          prevMonthButtonDisabled={disabledPreDate}
          showPopperArrow={false}
          selected={value}
          onChange={(date) => onChange(date)}
          minDate={userTimeZoneMinday}
          maxDate={userTimeZoneMaxday}
          placeholderText="Pick a date..."
          dateFormat={dateFormat}
          disabledKeyboardNavigation
        />
      </div>
    </div>
  );
}

export {
  ReadOnlyField,
  StyledPercentInput,
  StyledEmailInput,
  StyledPasswordInput,
  StyledInput,
  StyledPureInput,
  StyledTextarea,
  NoBorderInput,
  StyledMultiSelect,
  StyledSelect,
  NoBorderSelect,
  Search,
  DateInput,
  TypingSearchInput,
  NoBorderPercentInput,
  FilterSearchInput,
  CreateableSelectField,
  StyledLabel,
  PureTextField,
  PureDateInput
};
