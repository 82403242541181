import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {MdKeyboardArrowDown} from "react-icons/md";
import commonStyle from "../../../style/commonStyle.module.css";
import purchaseStyle from "../purchaseStyle.module.css";
import {
  DownloadPDFButton,
  EmailButton,
  ScreenTitle,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  Modal,
  ErrorMessage,
  Loader,
  ViewText,
  ViewDateWithTitle,
  SubTitle,
  Tag,
  FromTag,
  PriceText,
  ViewLabel,
  VariantSkuTag,
  ViewDateWithTime,
} from "../../../components/viewComponents";
import { purchaseOrderActionsRequest, purchaseOrderDetailsRequest } from "../../../actions/purchaseOrderActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { downloadPurchaseOrderPdf } from "./downloadPurchaseOrderPdf";
import PoEmailForm from "./PoEmailForm";
import XeroLink from "../../../components/XeroLink";
import { ProductTypeIcon } from "../../../components/icons";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import { generatePurchaseOrderRequest } from "../../../api/generatePDFServices";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import { createPurchaseOrderXeroTaskRequest, restartXeroTaskRequest } from "../../../api/xeroServices";
import {
  MoreActionLink,
  CloneLink,
  DeleteOrderButton,
  EditLink,
  ReceiveStockLink,
} from "./poActionButtons";

export default function PreviewPurchaseOrderScreen() {
  useDocumentTitle("Preview Purchase Order");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: poid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const standard_config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { loading, orderInfo, error, success } = useSelector(
    (state) => state.purchaseOrderDetails
  );

  const { loading:actionsLoading, actions, error: actionsError, success: actionsSuccess } = useSelector(
    (state) => state.purchaseOrderActions
  );


  const [emailModalOpen, setEmaiModalOpen] = useState(false);
  const [refreshCnt, setRefreshCnt] = useState(0);

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [refreshTaskSent, setRefreshTaskSent] = useState(false);

  const [restartTaskStatus, setRestartTaskStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  const [xeroTaskSent, setXeroTaskSent] = useState(false);
  const [sendToXeroStatus, setSendToXeroStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [showActions, setShowActions] = useState(false);


  /////////////////////////////////////////////////////////////////////


  useEffect(() => {
    dispatch(purchaseOrderDetailsRequest(poid));
    dispatch(purchaseOrderActionsRequest(poid));
  }, [dispatch, poid, refreshCnt]);

  /////////////////////////////////////////////////////////////////////////////
  const downloadPDFAction = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generatePurchaseOrderRequest(poid, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(
          blob,
          `StockUnify-purchase-order-${orderInfo.basicInfo.orderNumber}.pdf`
        );

        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const sendEmailAction = () => {
    setEmaiModalOpen(true);
  };

  const restartXeroTask = async (taskId) => {
    try {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await restartXeroTaskRequest(
        taskId,
        standard_config
      );
      if (responseData.data.success) {
        setRefreshTaskSent(true);
        setRestartTaskStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };
  const createPurchaseOrderXeroTask = async () => {
    try {
      setSendToXeroStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      let req_data = {
        orderId: poid
      }
      const responseData = await createPurchaseOrderXeroTaskRequest(req_data, standard_config);
      if (responseData.data.success) {
        setXeroTaskSent(true);
        setSendToXeroStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw "Create xero task failed";
      }
    } catch (error) {
      setSendToXeroStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const deleteSuccessAction = () => {
    navigate(`/purchase/order-list`, {
      replace: true,
    });
  };

  const ProcessingButton = ({text}) => {
    const handProcessRefresh = (e) => {
      e.preventDefault();
      setRefreshCnt(refreshCnt + 1);
    }

    return(
      <div>
        <p className="text-green-500">{text}</p>
        <button onClick={handProcessRefresh} className="border-brandColor border px-3 py-1 rounded my-2 hover:bg-brandColor hover:text-white">
          Check Status
        </button>
      </div>
    )

  };
  
  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="Send Email "
        isModalOpen={emailModalOpen}
        closeModal={() => setEmaiModalOpen(false)}
        content={
          <PoEmailForm
            supplierEmail={success ? orderInfo.basicInfo.email : ""}
            orderInfo={orderInfo}
            closeModal={() => setEmaiModalOpen(false)}
          />
        }
      />
      <Breadcrumbs screenName="Preview Purchase Order" />
      <ScreenTitle title="Preview Purchase Order" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting purchase order details, please wait a moment..." />
        ) : success ? (
          <div>
            <div className={purchaseStyle.orderNumberRow}>
              <div className={purchaseStyle.orderNumberCon}>
                <div>
                  <ViewText title="Order No." />
                  <p> {orderInfo.basicInfo.orderNumber}</p>
                </div>
                {orderInfo.basicInfo.status === "Approved" ? (
                  <Tag name={orderInfo.basicInfo.status} color="green" />
                ) : orderInfo.basicInfo.status === "Draft" ? (
                  <Tag name={orderInfo.basicInfo.status} color="yellow" />
                ) : orderInfo.basicInfo.status === "Completed" ? (
                  <Tag name={orderInfo.basicInfo.status} color="blue" />
                ) : orderInfo.basicInfo.status === "Deleted" ? (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                ) : (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                )}
                {
                  orderInfo.basicInfo.sent && orderInfo.basicInfo.sent === 1 ? <Tag name={"Sent"} color="brandBlue" /> : <></>
                }
              </div>
              <div>
                <div className="w-fit flex flex-row items-center justify-center">
                
                  {orderInfo.basicInfo.status !== "Completed" && (
                    <EditLink
                      orderId={orderInfo.basicInfo.id}
                      buttonView={true}
                      marginRight={true}
                    />
                  )}
                
                  {orderInfo.basicInfo.status === "Approved" && (
                    <ReceiveStockLink
                      orderId={orderInfo.basicInfo.id}
                      buttonView={true}
                      marginRight={true}
                    />
                  )}
                  <CloneLink
                    orderId={orderInfo.basicInfo.id}
                    buttonView={true}
                    marginRight={true}
                  />
                  {orderInfo.basicInfo.status !== "Completed" && (
                    <DeleteOrderButton
                      buttonView={true}
                      marginRight={true}
                      orderId={orderInfo.basicInfo.id}
                      deleteSuccess={deleteSuccessAction}
                    />
                  )}
                  {orderInfo.basicInfo.status === "Completed" &&  
                    <MoreActionLink 
                      orderId={orderInfo.basicInfo.id} 
                    />
                  }
                </div>

           
              </div>
            </div>

            {/* purchase order infomation */}
            <div className="mt-4">
              <SubTitle name="Purchase Order Information" />
              <div className={purchaseStyle.orderInfoWrapper}>
                <div className={purchaseStyle.basicInfoWrapper}>
                  <div className={commonStyle.col1}>
                  <ViewDateWithTitle
                      title="Issue Date"
                      value={orderInfo.basicInfo.issueDate}
                    />
                    <ViewText
                      title="Supplier"
                      value={orderInfo.basicInfo.supplier}
                    />
                    <ViewLabel title="Billing Address" />
                    {orderInfo.basicInfo.billingAddress.country ? (
                      <p>{orderInfo.basicInfo.billingAddress.country.value}</p>
                    ) : (
                      <></>
                    )}
                    {orderInfo.basicInfo.billingAddress.region ? (
                      <p>{orderInfo.basicInfo.billingAddress.region}</p>
                    ) : (
                      <></>
                    )}
                    {orderInfo.basicInfo.billingAddress.city ? (
                      <p>{orderInfo.basicInfo.billingAddress.city}</p>
                    ) : (
                      <></>
                    )}
                    {orderInfo.basicInfo.billingAddress.addressLine ? (
                      <p>{orderInfo.basicInfo.billingAddress.addressLine}</p>
                    ) : (
                      <></>
                    )}
                    {orderInfo.basicInfo.billingAddress.postalCode ? (
                      <p>{orderInfo.basicInfo.billingAddress.postalCode}</p>
                    ) : (
                      <></>
                    )}
                     <ViewText
                      title="Tax Rate"
                      value={orderInfo.basicInfo.taxrateName}
                    />
                    <ViewText
                      title="Currecy"
                      value={orderInfo.basicInfo.currency}
                    />
                 
                    {orderInfo.basicInfo.expectedDate > 0 ? (
                      <ViewDateWithTitle
                        title="Expected Delivery Date"
                        value={orderInfo.basicInfo.expectedDate}
                      />
                    ) : (
                      <></>
                    )}

                    {orderInfo.basicInfo.deliveryAddress && (
                      <ViewText
                        title="Address"
                        value={orderInfo.basicInfo.deliveryAddress}
                      />
                    )}
                  
                    {orderInfo.basicInfo.projectName && (
                      <ViewText
                        title="Project"
                        value={orderInfo.basicInfo.projectName}
                      />
                    )}

                    {orderInfo.basicInfo.notes && (
                      <ViewText
                        title="Notes"
                        value={orderInfo.basicInfo.notes}
                      />
                    )}
                  </div>
                  <div className={commonStyle.col2}>
                    <ViewText
                      title="Location"
                      value={orderInfo.basicInfo.location}
                    />
                    <ViewText
                      title="Bill Term"
                      value={
                        orderInfo.basicInfo.billTerm +
                        " Days after issue date"
                      }
                    />

                      {orderInfo.basicInfo.receiveDate > 0 ? (
                      <ViewDateWithTitle
                        title="Receive Date"
                        value={orderInfo.basicInfo.receiveDate}
                      />
                    ) : (
                      <></>
                    )}

                    <ViewText
                      title="Invoice"
                      value={
                        orderInfo.basicInfo.invoiceNumber
                          ? orderInfo.basicInfo.invoiceNumber
                          : ""
                      }
                    />
                    <ViewDateWithTitle
                      title="Invoice Date"
                      value={orderInfo.basicInfo.invoiceDate}
                    />

                    <ViewDateWithTitle
                      title="Invoice Due Date"
                      value={orderInfo.basicInfo.invoiceDue}
                    />

                  {detailsSuccess &&
                    userDetails.data.orgInfo.xeroConnected &&
                    userDetails.data.orgInfo.xeroConfigured ? (
                      <div>
                        <ViewLabel title="Sent to Xero" />
                        {orderInfo.basicInfo.bill_status === "ISSUED" ? (
                          <XeroLink
                          invoiceId={orderInfo.basicInfo.bill_id}
                          type="purchase_order_bill"
                        />
                        ) : Number(orderInfo.basicInfo.xeroTaskId) > 0 && orderInfo.basicInfo.xeroTaskError ? (
                          <div>
                            <p className="text-red-500 ">
                              Error: {orderInfo.basicInfo.xeroTaskError}
                            </p>
                              {refreshTaskSent && restartTaskStatus.success ? (
                                 <ProcessingButton text={"The Xero task has been restarted successfully. It may take 3-30 minutes to process the task."}/>
                              ) : (
                                <>
                                  {restartTaskStatus.loading ? (
                                    <Loader mess={"Requesting..."} />
                                  ) : (
                                    <button
                                      className="border-brandColor border px-3 py-1 rounded mb-2 hover:bg-brandColor hover:text-white"
                                      onClick={() =>
                                        restartXeroTask(
                                          orderInfo.basicInfo.xeroTaskId
                                        )
                                      }
                                    >
                                      Resend to Xero
                                    </button>
                                  )}
                                  {restartTaskStatus.error && (
                                    <p className="text-red-500">
                                      {restartTaskStatus.error}
                                    </p>
                                  )}
                                </>
                              )}
                          </div>
                        ) : Number(orderInfo.basicInfo.xeroTaskId) > 0 && !orderInfo.basicInfo.xeroTaskError ? (
                          <ProcessingButton text={"Processing..."}/>
                        ) : !orderInfo.basicInfo.xeroTaskId ? 
                        <div>
                          {
                            !orderInfo.basicInfo.syncXero ? <div> {xeroTaskSent && sendToXeroStatus.success ? (
                              <ProcessingButton text={"The Xero task has been created successfully. It may take 3-30 minutes to process the task."}/>
                            ) : (
                              <>
                                {sendToXeroStatus.loading ? (
                                  <Loader mess={"Requesting..."} />
                                ) : (
                                  <div>
                                    {orderInfo.basicInfo.status === "Completed" && 
                                     <button
                                     className="border-brandColor border px-3 py-1 rounded mb-2 hover:bg-brandColor hover:text-white"
                                     onClick={createPurchaseOrderXeroTask}
                                   >
                                     Send Order to Xero
                                   </button>

                                    }

                                  </div>
                                )}
                                {sendToXeroStatus.error && (
                                  <p className="text-red-500">
                                    {sendToXeroStatus.error}
                                  </p>
                                )}
                              </>
                            )} </div> : <ProcessingButton text={"Processing..."}/>
                          }
                        </div> 
                        : 
                        <></>
                        }

                      </div>
                    ) : (
                      <></>
                    )}

                  </div>
                </div>

                <div className={purchaseStyle.invoiceConPreview}>
                  <DownloadPDFButton
                    name="Download PDF"
                    action={downloadPDFAction}
                  />
                  <EmailButton name="Send Email" action={sendEmailAction} />
                </div>
              </div>
            </div>
            {/* invoice section */}
            <div className="flex flex-row items-end justify-between mt-4">
              <SubTitle name="Order Products" />
              <p className="text-gray ">
                {orderInfo.basicInfo.taxIncluded
                  ? "* Amounts are tax inclusive"
                  : "* Amounts are tax exclusive"}
              </p>
            </div>
            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Unit Cost</th>
                    <th>Discount</th>
                    <th>Net Cost</th>
                    <th>Quantity</th>
                    <th>Received Quantity</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {orderInfo.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.variantSku && (
                            <VariantSkuTag name={item.variantSku} />
                          )}
                        </td>
                        <td>
                          {" "}
                          {item.productName}{" "}
                          {item.variant ? (
                            <span className={purchaseStyle.variantTag}>
                              {item.variant}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{item.unitName}</td>

                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          <PriceText num={item.unitCost} />
                        </td>
                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.discount}% <FromTag name="OFF" />
                        </td>
                        <td>
                          {item.discount ? (
                            <PriceText
                              num={convtPrice(
                                (1 - Number(item.discount) / 100) *
                                  Number(item.unitCost)
                              )}
                            />
                          ) : (
                            <PriceText num={item.unitCost} />
                          )}
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>
                        <td>{convtQuantity(item.receivedQuantity)}</td>
                        <td
                          style={{
                            width: "10%",
                            maxWidth: "15%",
                          }}
                        >
                          <PriceText num={item.totalCost} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="8" className={commonStyle.tableFootName}>
                      Total
                    </td>

                    <td>
                      <PriceText num={orderInfo.orderSummary.productsTotal} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="mt-8">
              <div className={purchaseStyle.summaryWrapper}>
                <div className={purchaseStyle.costCon}>
                  {/* additional cost */}
                  <SubTitle name="Additional Cost" />
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Cost Name</th>
                        <th>Cost</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orderInfo.orderCosts.map((c, index2) => {
                        return (
                          <tr key={index2}>
                            <td
                              className={purchaseStyle.productNameCol}
                              style={{
                                width: "60%",
                              }}
                            >
                              {c.costName}
                            </td>

                            <td
                              style={{
                                width: "36%",
                              }}
                            >
                              <PriceText num={c.cost} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className={commonStyle.tableFootName}>Total</td>

                        <td>
                          <PriceText
                            num={orderInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="mt-4">
                    <OrderAttachmentsPreview files={orderInfo.orderFiles} />
                  </div>
                </div>

                <div className={purchaseStyle.summaryCon}>
                  {/* summary total price */}
                  <SubTitle name="Order Summary" />
                  <table
                    className={commonStyle.summaryTable}
                    style={{ marginTop: "12px" }}
                  >
                    {orderInfo.basicInfo.taxIncluded ? (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.productsTotal}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Cost Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.additionalCostTotal}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            <PriceText
                              num={orderInfo.basicInfo.totalCostInclTax}
                            />
                            {orderInfo.basicInfo.currency}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.basicInfo.totalCostExclTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderInfo.basicInfo.totalTax} />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.productsTotal}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Cost Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.additionalCostTotal}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.basicInfo.totalCostExclTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.basicInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText num={orderInfo.basicInfo.totalTax} />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            <PriceText
                              num={orderInfo.basicInfo.totalCostInclTax}
                            />
                            {orderInfo.basicInfo.currency}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>

                  {/*  order actions */}
                  <div className="mb-20 mt-4">
              <div className="w-full flex flex-row items-center justify-between h-12 bg-lightbg p-4 border-borderGray border border-solid hover:cursor-pointer" onClick={()=>setShowActions(!showActions)}>
                <p className="font-medium">Order History</p> 
                <MdKeyboardArrowDown
                  className={`w-5 h-5 transition-transform duration-300 ${
                    showActions ? 'rotate-180' : 'rotate-0'
                  }`}
                />
        
              </div>
              {
                showActions && actionsSuccess && <div className="w-full border-borderGray border border-solid px-4">
                <table className={commonStyle.logsTable}>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>Time</th>
                      <th>Action</th>
                      <th>Detail</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actions.map((item, index3) => (
                      <tr key={index3}>
                        <td><ViewDateWithTime value={item.atime} /></td>
                        <td>{item.action}</td>
                        <td className="w-1/2">{item.notes}</td>
                        <td>
                          {item.firstName}{" "}
                          {item.lastName ? item.lastName : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              }
           
             </div>
          
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
