import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  ALL_PERMISSIONS_REQUEST,
  ALL_PERMISSIONS_SUCCESS,
  ALL_PERMISSIONS_FAIL,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAIL,
  ROLE_NAME_LIST_REQUEST,
  ROLE_NAME_LIST_SUCCESS,
  ROLE_NAME_LIST_FAIL,
  ROLE_DETAIL_REQUEST,
  ROLE_DETAIL_SUCCESS,
  ROLE_DETAIL_FAIL,
  NEW_USER_REQUEST,
  NEW_USER_SUCCESS,
  NEW_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CREATE_USER_OPTIONS_REQUEST,
  CREATE_USER_OPTIONS_SUCCESS,
  CREATE_USER_OPTIONS_FAIL,
  NEW_ROLE_REQUEST,
  NEW_ROLE_SUCCESS,
  NEW_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "../constants/userRoleConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// get company's all permissons, used when create new role
export const allPermissionsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: ALL_PERMISSIONS_REQUEST });
    axios
      .get(SERVER_URL + `/permission/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: ALL_PERMISSIONS_SUCCESS, payload: response.data });
        } else {
          throw Object.assign(
            new Error(
              "Request permission list failed, please try again later."
            ),
            { code: 1101 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: ALL_PERMISSIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ALL_PERMISSIONS_FAIL,
      payload: "Connection error, please try again",
    });
  }
};

// get role detail by role id
export const roleDetailRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: ROLE_DETAIL_REQUEST });
    axios
      .get(SERVER_URL + `/role/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: ROLE_DETAIL_SUCCESS, payload: response.data.data });
        } else {
          throw Object.assign(
            new Error("Request role details failed, please try again later."),
            { code: 1102 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: ROLE_DETAIL_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ROLE_DETAIL_FAIL,
      payload: "Connection error, please try again",
    });
  }
};

// get company's all roles name  list
export const roleNameListRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {

    dispatch({ type: ROLE_NAME_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/role/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: ROLE_NAME_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error(
              "Request role name list failed, please refresh the page."
            ),
            { code: 1103 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: ROLE_NAME_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ROLE_NAME_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// add a new role
export const newRoleRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
 

    dispatch({ type: NEW_ROLE_REQUEST });
    axios
      .post(SERVER_URL + `/role/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_ROLE_SUCCESS });
          dispatch(roleNameListRequest());
        } else {
          if (response.data.error === 10) {
            throw Object.assign(
              new Error(
                "Role name already existed, please use another role name."
              ),
              { code: 1104 }
            );
          } else {
            throw Object.assign(
              new Error("Create new role failed, please try again later."),
              { code: 1105 }
            );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_ROLE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_ROLE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// update a new role
export const updateRoleRequest =
  (values, rid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
     

      dispatch({ type: UPDATE_ROLE_REQUEST });
      axios
        .put(SERVER_URL + `/role/${rid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_ROLE_SUCCESS });
            dispatch(roleNameListRequest());
          } else {
            if (response.data.error === 10) {
              throw Object.assign(
                new Error(
                  "Role name already existed, please use another role name."
                ),
                { code: 1104 }
              );
            } else {
              throw Object.assign(
                new Error("Update role failed, please try again later."),
                { code: 1106 }
              );
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_ROLE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_ROLE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const deleteRoleRequest = (rid) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: DELETE_ROLE_REQUEST });
    axios
      .delete(SERVER_URL + `/role/${rid}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_ROLE_SUCCESS });
          dispatch(roleNameListRequest());
        } else {
          throw Object.assign(
            new Error("Delete role failed, please try again later."),
            { code: 1107 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_ROLE_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_ROLE_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// get user list , include super admin
export const usersListRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  try {
    
    dispatch({ type: USERS_LIST_REQUEST });
    axios
      .get(SERVER_URL + `/user/list`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let list = [];
          let active_user_num = 0;
          response.data.data.userList.forEach((x) => {
            let user = {
              id: x.id,
              firstName: x.first_name,
              lastName: x.last_name ? x.last_name : "",
              userPhone: x.phone,
              userEmail: x.email,
              position: x.position,
              location: x.locationId
                ? {
                    id: x.locationId,
                    label: x.locationName,
                    value: x.locationName,
                  }
                : "",
              department: x.department_id
                ? {
                    id: x.department_id,
                    label: x.department_name,
                    value: x.department_name,
                  }
                : "",
              description: x.description,
              status: x.activated,
              roles: x.roles,
              isAdmin: x.is_admin,
              isActivated: x.is_activated,
            };

            if(x.is_activated !== -1){
              active_user_num = active_user_num + 1
            };

            list.push(user);
          });

          dispatch({
            type: USERS_LIST_SUCCESS,
            payload: { users: list, userNum: response.data.data.userNum, activeUserNum: active_user_num},
          });
        } else {
          throw Object.assign(
            new Error("Request user list failed, please try again later."),
            { code: 1108 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: USERS_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: USERS_LIST_FAIL,
      payload: "get users list failed, please refresh the page",
    });
  }
};

// get company's all roles name  list
export const createUserOptionsRequest = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    

    dispatch({ type: CREATE_USER_OPTIONS_REQUEST });
    axios
      .get(SERVER_URL + `/user/options`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: CREATE_USER_OPTIONS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw Object.assign(
            new Error("Request options failed please try again later."),
            { code: 1109 }
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_USER_OPTIONS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: CREATE_USER_OPTIONS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// add a new user
export const newUserRequest = (values) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
   

    dispatch({ type: NEW_USER_REQUEST });
    axios
      .post(SERVER_URL + `/user/create`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_USER_SUCCESS });
          dispatch(usersListRequest());
        } else {
          if (response.data.error === 6) {
            throw Object.assign(
              new Error(
                "User invitation failed. The number of users has exceeded the plan limit. Please upgrade your plan to increase the user limit."
              ),
              { code: 1110 }
            );
          } else if (response.data.error === 10) {
            throw Object.assign(
              new Error("Email exists, please use a new email address."),
              { code: 1110 }
            );
          } else {
            throw Object.assign(
              new Error("Create new user failed, please try again."),
              { code: 1111 }
            );
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_USER_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_USER_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

// add a new user
export const updateUserRequest =
  (values, uid) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      

      dispatch({ type: UPDATE_USER_REQUEST });
      axios
        .put(SERVER_URL + `/user/${uid}`, values, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_USER_SUCCESS });
            dispatch(usersListRequest());
          } else {
            if (response.data.error === 20) {
              throw Object.assign(
                new Error("Email exists, please use a new email address."),
                { code: 1110 }
              );
            } else {
              throw Object.assign(
                new Error("Update user failed, please try again."),
                { code: 1112 }
              );
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_USER_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };
