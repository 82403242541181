import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { batchSerialNumbersRequest } from "../api/batchSerialServices";
import { validateQuantity } from "../functions/validate";
import commonStyle from "../style/commonStyle.module.css";
import {
  AddOutlineButton,
  SimpleBlueButton,
  SimpleOutlineButton,
  XDeleteButton,
} from "./editComponents";
import {
  DateInput,
  NoBorderInput,
  StyledInput,
  StyledTextarea,
  FilterSearchInput,
} from "./inputFields";
import {
  ContentModal,
  Loader,
  SubTitle,
  Tag,
  TextErrorMessage,
  ViewDateText,
} from "./viewComponents";

function SelectedNumber({ requiredQty, qty }) {
  let requiredQuantity = 0;
  let quantity = 0;

  if (requiredQty) {
    requiredQuantity = Number(requiredQty);
  }
  if (qty) {
    quantity = Number(qty);
  }
  let colorClassname =
    quantity !== requiredQuantity
      ? commonStyle.insufficientNum
      : commonStyle.sufficientNum;

  return (
    <div className={colorClassname}>
      <span>{quantity}</span>
      <span>/</span>
      <span>{requiredQuantity}</span>
    </div>
  );
}

function SerialNumberSelect({
  btnName,
  onSave,
  prodName,
  requiredQty,
  initialNumbers,
  famid,
  variantCode,
  locationId,
  index,
  rentalStartTime,
  rentalEndTime,
}) {
  // type: two options, serial, batch

  const [modalOpen, setModalOpen] = useState(false);
  const openModalAction = () => {
    setModalOpen(true);
  };

  const [selectSerials, setSelectedSerials] = useState(
    initialNumbers ? initialNumbers : []
  );

  const saveSerialNumbers = (selectedSerials) => {
    setModalOpen(false);
    setSelectedSerials(selectedSerials);
    onSave(selectedSerials, index);
  };

  return (
    <div>
      <ContentModal
        title="Select Serial Numbers"
        isModalOpen={modalOpen}
        closeOnOverlayClick={true}
        closeModal={() => setModalOpen(false)}
        content={
          <SelectSerialNumbersForm
            famid={famid}
            variantCode={variantCode}
            locationId={locationId}
            initialNumbers={selectSerials}
            rentalStartTime={rentalStartTime}
            rentalEndTime={rentalEndTime}
            prodName={prodName}
            requiredQty={requiredQty}
            onSave={saveSerialNumbers}
          />
        }
      />

      <p
        onClick={openModalAction}
        className={commonStyle.selecteTrackingNumberBtn}
      >
        {btnName}
      </p>
      <SelectedNumber qty={selectSerials.length} requiredQty={requiredQty} />
    </div>
  );
}

function SelectSerialNumbersForm({
  famid,
  variantCode,
  locationId,
  prodName,
  initialNumbers,
  requiredQty,
  onSave,
  rentalStartTime,
  rentalEndTime,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [selectedSerialNumbers, setSelectedSerialNumbers] =
    useState(initialNumbers);
  const [serialNumbers, setSerialNumbers] = useState([]);

  const [searchKeywords, setSearchKeywords] = useState("");

  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getSerialNumbers = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await batchSerialNumbersRequest(
          "serial",
          famid,
          variantCode,
          locationId,
          rentalStartTime,
          rentalEndTime,
          config
        );

        if (responseData.success) {
          let numbers = responseData.data;
          if (selectedSerialNumbers.length > 0) {
            for (const ele of numbers) {
              for (const item of selectedSerialNumbers) {
                if (item.uuid === ele.uuid) {
                  ele.isSelected = true;
                } else {
                  ele.isSelected = false;
                }
              }
            }
          }
          setSerialNumbers(numbers);

          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getSerialNumbers();
  }, []);
  //////////////////////////////////////////////////////////////////
  const handleSearchTextChange = (value) => {
    setSearchKeywords(value);
  };
  const handleSerialNumberChange = (index) => {
    let numbersCopy = [...serialNumbers];
    let object = numbersCopy[index];
    object.isSelected = true;

    const updatedSelectedList = [...selectedSerialNumbers, object];
    setSelectedSerialNumbers(updatedSelectedList);

    setSerialNumbers(numbersCopy);
  };

  const handleDeselect = (index) => {
    let selectedCopy = [...selectedSerialNumbers];
    let object = selectedCopy[index];

    let numbersCopy = [...serialNumbers];
    for (const ele of numbersCopy) {
      if (ele.uuid === object.uuid) {
        ele.isSelected = false;
      }
    }

    selectedCopy.splice(index, 1);
    setSelectedSerialNumbers(selectedCopy);
    setSerialNumbers(numbersCopy);
  };

  const saveSelectedSerialNumbers = () => {
    onSave(selectedSerialNumbers);
    // setModalOpen(false);
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <h2 className="text-2xl font-medium">{prodName}</h2>
      <div className="mt-6 grid grid-cols-2 gap-4">
        <div>
          <SubTitle name="Available Serial Numbers" />
          <div className="mt-2">
            <FilterSearchInput
              value={searchKeywords}
              onChange={(value) => handleSearchTextChange(value)}
              placeholdertext="Search"
            />
          </div>

          <div>
            {requestStatus.loading ? (
              <Loader mess="Requesting data..." />
            ) : requestStatus.success ? (
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Serial Number</th>
                    <th>Date Added</th>
                    <th
                      style={{
                        margin: "auto",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {serialNumbers.filter((x) => x.code.includes(searchKeywords))
                    .length > 0 ? (
                    serialNumbers
                      .filter((x) => x.code.includes(searchKeywords))
                      .map((item, index) =>
                        !item.isSelected ? (
                          <tr key={index}>
                            <td>{item.code}</td>
                            <td>
                              <ViewDateText value={item.atime} />
                            </td>
                            <td style={{ padding: "10px" }}>
                              <AddOutlineButton
                                name="Select"
                                action={() => handleSerialNumberChange(index)}
                              />
                            </td>
                          </tr>
                        ) : (
                          <React.Fragment key={index}></React.Fragment>
                        )
                      )
                  ) : (
                    <tr>
                      <td>No serials number found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : requestStatus.error ? (
              <TextErrorMessage error={requestStatus.error} />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between">
            <SubTitle name="Selected Serial Numbers" />
            <div >
              <SelectedNumber
                qty={selectedSerialNumbers.length}
                requiredQty={requiredQty}
              />
            </div>
          </div>

          <table className={commonStyle.pageTable}>
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Date Added</th>
                <th
                  style={{
                    margin: "auto",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {selectedSerialNumbers.length > 0 ? (
                selectedSerialNumbers.map((item, index) => (
                  <tr key={index}>
                    <td>{item.code}</td>
                    <td>
                      <ViewDateText value={item.atime} />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <XDeleteButton action={() => handleDeselect(index)} />
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
          <div className="w-full mt-6">
          <SimpleBlueButton name="Save" action={saveSelectedSerialNumbers} />
          </div>
        </div>
      </div>
    </div>
  );
}

function SerialNumberCollect({
  btnName,
  prodName,
  initialNumbers,
  requiredQty,
  onSave,
  index,
}) {
  // type: two options, serial, batch

  const [modalOpen, setModalOpen] = useState(false);
  const openModalAction = () => {
    setModalOpen(true);
  };
  const [serialNumbers, setSerialNumbers] = useState(
    initialNumbers ? initialNumbers : []
  );
  const saveSerialNumbers = (serialNumbers) => {
    setModalOpen(false);
    setSerialNumbers(serialNumbers);
    onSave(serialNumbers, index);
  };

  return (
    <div>
      <ContentModal
        title="Add Serial Numbers"
        isModalOpen={modalOpen}
        closeOnOverlayClick={true}
        closeModal={() => setModalOpen(false)}
        content={
          <CollectSerialForm
            initialNumbers={serialNumbers}
            prodName={prodName}
            requiredQty={requiredQty}
            onSave={saveSerialNumbers}
          />
        }
      />

      <p
        onClick={openModalAction}
        className={commonStyle.selecteTrackingNumberBtn}
      >
        {btnName}
      </p>
      <SelectedNumber
        qty={serialNumbers ? serialNumbers.length : 0}
        requiredQty={requiredQty}
      />
    </div>
  );
}

function CollectSerialForm({ prodName, onSave, requiredQty, initialNumbers }) {
  const qtyLimit = Number(requiredQty);

  const [serialInputValue, setSerialInputValue] = useState("");
  const [serialNumbers, setSerialNumbers] = useState(initialNumbers);

  const handleInputChange = (e) => {
    setSerialInputValue(e.target.value);
  };

  const handleSubmit = () => {
    // Split input based on comma or new line
    const numbersArray = serialInputValue
      .split(/,|\n/)
      .map((value) => value.trim()) // Remove leading/trailing spaces
      .filter((value) => value !== ""); // Filter out empty values

    // Remove duplicates by converting the array to a Set and then back to an array
    const uniqueValues = Array.from(new Set(numbersArray));

    let structuredValues = [];
    for (const item of uniqueValues) {
      structuredValues.push({
        serialNumber: item,
        quantity: 1,
      });
    }

    // merge to orginal array
    const mergedSerialNums = [...serialNumbers, ...structuredValues];
    // remove duplicates

    let uniqueKeys = [];
    let newSerialNums = [];

    for (const item of mergedSerialNums) {
      if (!uniqueKeys.includes(item.serialNumber)) {
        uniqueKeys.push(item.serialNumber);
        newSerialNums.push(item);
      }
    }
    // remove item exceed required qty

    if (newSerialNums.length > qtyLimit) {
      let newValues = newSerialNums.slice(0, qtyLimit);
      setSerialNumbers(newValues);
    } else {
      setSerialNumbers(newSerialNums);
    }
    setSerialInputValue("");
  };

  const deleteSerialItem = (index) => {
    let copy = [...serialNumbers];
    copy.splice(index, 1);
    setSerialNumbers(copy);
  };

  const onSubmit = () => {
    onSave(serialNumbers);
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <h2 style={{ marginTop: "20px" }}>{prodName}</h2>
      <div>
        <StyledTextarea
          label="Enter serial numbers must be comma separated or entered on a new line.(E.g. s0001,s0002,s0003)"
          type="text"
          name="serialNumbers"
          presetRows="4"
          value={serialInputValue}
          onChange={(serialNumbers) =>
            handleInputChange({
              target: { value: serialNumbers, name: "serialNumbers" },
            })
          }
        />
        <div style={{ width: "fit-content" }}>
          <SimpleOutlineButton
            name="Add Serial Numbers"
            action={handleSubmit}
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SelectedNumber qty={serialNumbers.length} requiredQty={qtyLimit} />
      </div>
      <table className={commonStyle.pageTable}>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Date Added</th>
            <th
              style={{
                margin: "auto",
                borderLeft: "1px solid var(--border-color)",
              }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {serialNumbers.length > 0 ? (
            serialNumbers.map((item, index) => (
              <tr key={index}>
                <td>{item.serialNumber}</td>
                <td>
                  <ViewDateText
                    value={Math.floor(new Date().getTime()) / 1000}
                  />
                </td>
                <td>
                  <XDeleteButton action={() => deleteSerialItem(index)} />
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="w-full mt-6">
      <SimpleBlueButton name="Save Serial Numbers" action={onSubmit} />
      </div>
    </div>
  );
}

function BatchNumberCollect({
  btnName,
  prodName,
  initialNumbers,
  requiredQty,
  onSave,
  index,
}) {
  // type: two options, serial, batch

  const [modalOpen, setModalOpen] = useState(false);
  const openModalAction = () => {
    setModalOpen(true);
  };
  const [batchNumbers, setBatchNumbers] = useState(
    initialNumbers ? initialNumbers : []
  );
  const saveSerialNumbers = (numbers) => {
    setBatchNumbers(numbers);
    onSave(numbers, index);
    setModalOpen(false);
  };

  return (
    <div>
      <ContentModal
        title="Add Batch"
        isModalOpen={modalOpen}
        closeOnOverlayClick={true}
        closeModal={() => setModalOpen(false)}
        content={
          <CollectBatchForm
            initialNumbers={batchNumbers}
            prodName={prodName}
            requiredQty={requiredQty}
            onSave={saveSerialNumbers}
          />
        }
      />

      <p
        onClick={openModalAction}
        className={commonStyle.selecteTrackingNumberBtn}
      >
        {btnName}
      </p>
      <SelectedNumber
        qty={
          batchNumbers.length
            ? parseFloat(
              batchNumbers.reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.quantity);
              }, 0).toFixed(2)
            )
            
            
            : 0
        }
        requiredQty={requiredQty}
      />
    </div>
  );
}

function CollectBatchForm({ prodName, onSave, requiredQty, initialNumbers }) {
  const qtyLimit = Number(requiredQty);

  const [batchInputValue, setBatchInputValue] = useState({
    batchNumber: "",
    expireDate: "",
    quantity: "",
  });
  const [formErrors, setFormErrors] = useState("");
  const [batchNumbers, setBatchNumbers] = useState(initialNumbers);

  const handleChange = (e) => {
    const { value, name, type } = e.target;

    if (type === "quantity") {
      const validated = validateQuantity(value);
      if (!validated) return;
    }

    setBatchInputValue({ ...batchInputValue, [name]: value });
  };

  const handleSubmit = () => {
    const hasErrors = validate(batchInputValue);
    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      let batchCopy = [...batchNumbers];
      batchCopy.push(batchInputValue);
      setBatchNumbers(batchCopy);
      setBatchInputValue({
        batchNumber: "",
        expireDate: "",
        quantity: "",
      });
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.batchNumber) {
      errors.batchNumber = "Batch number is required!";
    }
    if (!values.quantity) {
      errors.quantity = "Batch quantity is required!";
    }

    return errors;
  };

  const deleteBatchItem = (index) => {
    let copy = [...batchNumbers];
    copy.splice(index, 1);
    setBatchNumbers(copy);
  };

  const onSubmit = () => {
    onSave(batchNumbers);
  };


  const totalQuantity = parseFloat(
    batchNumbers.reduce((acc, curr) => acc + Number(curr.quantity), 0).toFixed(2)
  );

  return (
    <div style={{ paddingTop: "20px" }}>
      <h2 style={{ marginTop: "20px" }}>{prodName}</h2>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "20px" }}>
          <StyledInput
            label="Batch Number*"
            type="text"
            name="batchNumber"
            value={batchInputValue.batchNumber}
            onChange={(batchNumber) =>
              handleChange({
                target: {
                  value: batchNumber,
                  name: "batchNumber",
                  type: "text",
                },
              })
            }
            error={formErrors.batchNumber}
          />
        </div>
        <div style={{ marginRight: "20px" }}>
          <StyledInput
            label="Quantity*"
            type="text"
            name="quantity"
            value={batchInputValue.quantity}
            onChange={(quantity) =>
              handleChange({
                target: { value: quantity, name: "quantity", type: "quantity" },
              })
            }
            error={formErrors.quantity}
          />
        </div>
        <div>
          <DateInput
            label="Expire Date"
            name="expireDate"
            disabledPreDate
            value={batchInputValue.expireDate}
            onChange={(expireDate) =>
              handleChange({
                target: {
                  value: expireDate,
                  name: "expireDate",
                },
              })
            }
            error={formErrors.expireDate}
          />
        </div>
      </div>
      <div style={{ width: "fit-content" }}>
        <SimpleOutlineButton name="Add Batch" action={handleSubmit} />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SelectedNumber
          qty={totalQuantity}
          requiredQty={qtyLimit}
        />
      </div>
      <table className={commonStyle.pageTable}>
        <thead>
          <tr>
            <th>Batch Number</th>
            <th>Quantity</th>
            <th>Expire Date</th>
            <th>Date Added</th>
            <th
              style={{
                margin: "auto",
                borderLeft: "1px solid var(--border-color)",
              }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {batchNumbers.length > 0 ? (
            batchNumbers.map((item, index) => (
              <tr key={index}>
                <td>{item.batchNumber}</td>
                <td>{item.quantity}</td>
                <td>
                  {item.expireDate ? (
                    <ViewDateText
                      value={Math.floor(item.expireDate.getTime()) / 1000}
                    />
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  <ViewDateText
                    value={Math.floor(new Date().getTime()) / 1000}
                  />
                </td>
                <td>
                  <XDeleteButton action={() => deleteBatchItem(index)} />
                </td>
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="w-full mt-6">
      <SimpleBlueButton name="Save Batch Numbers" action={onSubmit} />
      </div>
    </div>
  );
}

function BatchNumberSelect({
  btnName,
  prodName,
  famid,
  variantCode,
  locationId,
  initialNumbers,
  requiredQty,
  onSave,
  index,
  rentalStartTime,
  rentalEndTime,
}) {
  // type: two options, serial, batch

  const [modalOpen, setModalOpen] = useState(false);
  const openModalAction = () => {
    setModalOpen(true);
  };
  const [selectedBatchNumbers, setSelectedBatchNumbers] = useState(
    initialNumbers ? initialNumbers : []
  );

  const saveSelectedBatch = (numbers) => {
    setSelectedBatchNumbers(numbers);
    onSave(numbers, index);
    setModalOpen(false);
  };

  return (
    <div>
      <ContentModal
        title="Add Batch"
        isModalOpen={modalOpen}
        closeOnOverlayClick={true}
        closeModal={() => setModalOpen(false)}
        content={
          <SelectBatchForm
            initialNumbers={selectedBatchNumbers}
            prodName={prodName}
            famid={famid}
            variantCode={variantCode}
            locationId={locationId}
            requiredQty={requiredQty}
            onSave={saveSelectedBatch}
            rentalStartTime={rentalStartTime}
            rentalEndTime={rentalEndTime}
          />
        }
      />

      <p
        onClick={openModalAction}
        className={commonStyle.selecteTrackingNumberBtn}
      >
        {btnName}
      </p>
      <SelectedNumber
        qty={
          selectedBatchNumbers
            ? parseFloat(selectedBatchNumbers.reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.consumeQuantity);
              }, 0).toFixed(2))
            : 0
        }
        requiredQty={requiredQty}
      />
    </div>
  );
}

function SelectBatchForm({
  prodName,
  famid,
  variantCode,
  locationId,
  onSave,
  requiredQty,
  initialNumbers,
  rentalEndTime,
  rentalStartTime,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [batchNumbers, setBatchNumbers] = useState([]);
  const [formError, setFormError] = useState("");

  const [searchKeywords, setSearchKeywords] = useState("");

  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getBatchNumbers = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await batchSerialNumbersRequest(
          "batch",
          famid,
          variantCode,
          locationId,
          rentalStartTime,
          rentalEndTime,
          config
        );

        if (responseData.success) {
          let numbers = responseData.data;
          for (const ele of numbers) {
            ele.consumeQuantity = "";
            if (initialNumbers.length > 0) {
              for (const item of initialNumbers) {
                if (item.uuid === ele.uuid) {
                  ele.consumeQuantity = item.consumeQuantity;
                }
              }
            }
          }

          setBatchNumbers(numbers);

          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getBatchNumbers();
  }, []);

  const handleSearchTextChange = (value) => {
    setSearchKeywords(value);
  };

  const handleQuantityChange = (index, value, totalQuantity) => {
    const validated = validateQuantity(value);
    if (!validated) return;

    if (Number(value) > Number(totalQuantity)) {
      return;
    }

    const batchCopy = [...batchNumbers];
    let obj = batchCopy[index];
    obj.consumeQuantity = value;

    setBatchNumbers(batchCopy);
  };

  const onSubmit = () => {
    let batchCopy = [...batchNumbers];
    if (!batchCopy.some((x) => Number(x.consumeQuantity) > 0)) {
      setFormError("No batch selected.");
      return;
    }


    if (parseFloat(
      batchCopy.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.consumeQuantity);
      }, 0).toFixed(2)) > requiredQty
    ) {
      setFormError("Selected quantity is greater than required quantity.");
      return;
    }

    setFormError("");

    let selectedBatch = [];
    batchCopy.forEach((x) => {
      if (Number(x.consumeQuantity) > 0) {
        selectedBatch.push(x);
      }
    });

    onSave(selectedBatch);
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <h2>{prodName}</h2>

      {formError ? (
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <TextErrorMessage mess={formError} />
        </div>
      ) : (
        <></>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SelectedNumber
          qty={
            batchNumbers
              ? parseFloat(batchNumbers.reduce((accumulator, currentValue) => {
                  return accumulator + Number(currentValue.consumeQuantity);
                }, 0).toFixed(2))
              : 0
          }
          requiredQty={requiredQty}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <FilterSearchInput
          value={searchKeywords}
          onChange={(value) => handleSearchTextChange(value)}
          placeholdertext="Search"
        />
      </div>

      <div>
        {requestStatus.loading ? (
          <Loader mess="Requesting data..." />
        ) : requestStatus.success ? (
          <table className={commonStyle.pageTable}>
            <thead>
              <tr>
                <th>Batch Number</th>
                <th>Expire Date</th>
                <th>Date Added</th>
                <th>Deduct Quantity / Available Quantity</th>
              </tr>
            </thead>
            <tbody>
              {batchNumbers.filter((x)=> x.code.includes(searchKeywords)).length > 0 ? (
                batchNumbers.filter((x)=> x.code.includes(searchKeywords)).map((item, index) => (
                  <tr key={index}>
                    <td>{item.code}</td>

                    <td>
                      {item.expire_date ? (
                        <ViewDateText value={item.expire_date} />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <ViewDateText value={item.atime} />
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            background: "var(--light-green-background-color)",
                          }}
                        >
                          <NoBorderInput
                            type="text"
                            name="quantity"
                            value={item.consumeQuantity}
                            onChange={(quantity) =>
                              handleQuantityChange(
                                index,
                                quantity,
                                item.quantity
                              )
                            }
                          />
                        </div>
                        <span style={{ marginRight: "4px" }}>/</span>
                        <span style={{ marginRight: "4px" }}>
                          {item.quantity}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
               <tr><td>No batch number found.</td></tr>
              )}
            </tbody>
          </table>
        ) : requestStatus.error ? (
          <TextErrorMessage error={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
      <div className="w-full mt-6">
      <SimpleBlueButton name="Save" action={onSubmit} />
      </div>
    </div>
  );
}



export {
  BatchNumberCollect,
  BatchNumberSelect,
  SerialNumberCollect,
  SerialNumberSelect,
};
