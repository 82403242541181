import React, { useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { NavLink } from "react-router-dom";
import inventoryStyle from "./inventoryStyle.module.css";
import { MdAttachMoney, MdFileCopy, MdOutlinePreview } from "react-icons/md";
import { AiFillEdit, AiOutlineProfile, AiOutlineDelete } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";
import { DeleteDialog } from "../../components/viewComponents";
import { useDispatch } from "react-redux";
import { deleteProductRequest } from "../../actions/productActions";

export default function ProductActions({ product, showPreview = false, reloadList }) {
  const dispatch = useDispatch();
  // access check for the actions
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading: deleteLoading, success: deleteSuccess, error: deleteError} = useSelector(
    (state) => state.deleteProduct
  );

  const [deleteDialog, setDeleteDialog] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  const openDeleteDialog = (e) => {
    e.preventDefault();
    // setDeleteOrderId(orderId);
    setDeleteDialog(true);
  };
  const sendDeleteRequest = async () => {
    dispatch(deleteProductRequest(product.id))
  };

  const closeDialogAction = () => {
    setDeleteDialog(false);
    if(deleteSuccess){
      reloadList();
    }
  }

  return (
    <div className={inventoryStyle.actionsGroup}>
      <DeleteDialog
        title="Delete Product"
        preDeleteMess={`Please note that the delete action can not be undone. Are you sure you want to proceed?`}
        successMessage="Product deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={closeDialogAction}
        closeDialogWithSuccess={closeDialogAction}
        loading={deleteLoading}
        success={deleteSuccess}
        error={deleteError}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      {showPreview && userDetailsSuccess && userDetails.data.permissions.includes("view_product_details") ? (
        <NavLink
          to={`/inventory/preview/${product.id}`}
          className={`${inventoryStyle.actionCon} ${inventoryStyle.blueAction}`}
        >
          <MdOutlinePreview className={inventoryStyle.actionIcon} />
          <p>Preview</p>
        </NavLink>
      ) : (
        <></>
      )}

      {/* check if has access for "new_product" */}
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("new_product") ? (
          <NavLink
            to={"/inventory/new"}
            state={product.id}
            className={`${inventoryStyle.actionCon} ${inventoryStyle.yellowAction}`}
          >
            <MdFileCopy className={inventoryStyle.actionIcon} />
            <p>Clone</p>
          </NavLink>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {/* check if has access for "edit_product" */}
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("edit_product") ? (
          <NavLink
            to={`/inventory/edit/${product.id}`}
            state={product.status}
            className={`${inventoryStyle.actionCon} ${inventoryStyle.greenAction}`}
          >
            <AiFillEdit className={inventoryStyle.actionIcon} />
            <p>Edit</p>
          </NavLink>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {/* check if has access for "product_custom_price" */}
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("product_custom_price") &&
        product.status !== "Draft" ? (
          <NavLink
            to={`/inventory/custome-price/${product.id}`}
            className={`${inventoryStyle.actionCon} ${inventoryStyle.orangeAction}`}
          >
            <MdAttachMoney className={inventoryStyle.actionIcon} />
            <span style={{ textAlign: "center", lineHeight: 1 }}>
              Custom Price
            </span>
          </NavLink>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {/* check if has access for "stock_logs" */}
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("view_stock_change_logs") &&
        product.status !== "Draft" ? (
          <NavLink
            to={`/inventory/stock-logs/${product.id}`}
            className={`${inventoryStyle.actionCon} ${inventoryStyle.blueAction}`}
            state={{productName: product.name, variantSku: product.variantSku}}
          >
            <AiOutlineProfile className={inventoryStyle.actionIcon} />
            {/* <div ></div> */}

            <span style={{ textAlign: "center", lineHeight: 1 }}>
              Stock Logs
            </span>
          </NavLink>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {/* check if has access for "edit_product" */} 
      {/* only show delete button on the list page */} 
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("edit_product") && Number(product.stock) <= 0 ? (
          <button
            className={`${inventoryStyle.actionCon} ${inventoryStyle.redAction}`}
            style={{ border: "none" }}
            onClick={(e) => openDeleteDialog(e)}
          >
            <AiOutlineDelete className={inventoryStyle.actionIcon} />
            <p>Delete</p>
          </button>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
}
