import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import commonStyle from "../../../style/commonStyle.module.css";
import {
  EditButton,
  EditButtonLink,
  ScreenTitle,
} from "../../../components/editComponents";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ErrorMessage,
  Loader,
  ViewText,
  ViewDateWithTitle,
  SubTitle,
  PriceText,
  VariantTag,
  VariantSkuTag,
  ViewLabel,
} from "../../../components/viewComponents";
import { stockAdjustDetailsRequest } from "../../../actions/productActions";
import useDocumentTitle from "../../../useDocumentTitle";
import { convtPrice, convtQuantity } from "../../../functions/functions";
import XeroLink from "../../../components/XeroLink";
import { createStockAdjustXeroTaskRequest, restartXeroTaskRequest } from "../../../api/xeroServices";


export default function PreviewStockAdjustmentScreen() {
  useDocumentTitle("Preview Stock Adjustment");
  const dispatch = useDispatch();
  const { id: adjustid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const standard_config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, adjustDetails, error, success } = useSelector(
    (state) => state.stockAdjustDetails
  );

  /////////////////////////////////////////////////////////////////////
  const [refreshTaskSent, setRefreshTaskSent] = useState(false);
  const [restartTaskStatus, setRestartTaskStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [xeroTaskSent, setXeroTaskSent] = useState(false);
  const [sendToXeroStatus, setSendToXeroStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  const [refreshCnt, setRefreshCnt] = useState(0);

 /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(stockAdjustDetailsRequest(adjustid));
  }, [dispatch, adjustid, refreshCnt]);

  /////////////////////////////////////////////////////////////////////////////
  const restartXeroTask = async (taskId) => {
    try {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await restartXeroTaskRequest(taskId, standard_config);
      if (responseData.data.success) {
        setRefreshTaskSent(true);
        setRestartTaskStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const createAdjustOrderXeroTask = async () => {
    try {
      setSendToXeroStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      let req_data = {
        orderId: adjustid
      }
      const responseData = await createStockAdjustXeroTaskRequest(req_data, standard_config);
      if (responseData.data.success) {
        setXeroTaskSent(true);
        setSendToXeroStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw "Create xero task failed";
      }
    } catch (error) {
      setSendToXeroStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  /////////////////////////////////////////////////////////////////////////////
  const ProcessingButton = ({text}) => {
    const handProcessRefresh = (e) => {
      e.preventDefault();
      setRefreshCnt(refreshCnt + 1);
    }

    return(
      <div>
        <p className="text-green-500">{text}</p>
        <button onClick={handProcessRefresh} className="border-brandColor border px-3 py-1 rounded my-2 hover:bg-brandColor hover:text-white">
          Check Status
        </button>
      </div>
    )

  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Stock Adjustment" />
      <ScreenTitle title="Preview Stock Adjustment" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting details, please wait a moment..." />
        ) : success ? (
          <div>
            {/* purchase order infomation */}
            <SubTitle name="Order Basic Information" />

            <div className={commonStyle.previewContentContainer}>
              {adjustDetails.basicInfo.status === "Approved" && (
                <div className="flex justify-end">
                  <EditButtonLink
                    path={`/inventory/bulk-stock-adjustment/edit/${adjustDetails.basicInfo.id}`}
                  />
                </div>
              )}

              <div className={commonStyle.previewWrapper}>
                <div className={commonStyle.col1}>
                  <ViewText
                    title="Location"
                    value={adjustDetails.basicInfo.location}
                  />

                  <ViewText
                    title="Adjust Type"
                    value={
                      adjustDetails.basicInfo.type === "RECEIVESTOCK"
                        ? "Receive Stock"
                        : adjustDetails.basicInfo.type === "STOCKDEDUCTION"
                        ? "Deduct Stock"
                        : "Unknown"
                    }
                  />
                  <ViewText
                    title="Adjust Reason"
                    value={adjustDetails.basicInfo.reasonName}
                  />
                  <ViewText
                    title="Reference"
                    value={adjustDetails.basicInfo.reference}
                  />
                  {adjustDetails.basicInfo.projectName && (
                    <ViewText
                      title="Project"
                      value={
                        adjustDetails.basicInfo.projectCode +
                        "-" +
                        adjustDetails.basicInfo.projectName
                      }
                    />
                  )}
                  <ViewText
                    title="Notes"
                    value={adjustDetails.basicInfo.notes}
                  />
                </div>
                <div className={commonStyle.col3}>
                  <ViewDateWithTitle
                    title="Create Date"
                    value={adjustDetails.basicInfo.atime}
                  />
                  <ViewDateWithTitle
                    title="Issue Date"
                    value={adjustDetails.basicInfo.issueTime}
                  />

                  <ViewText
                    title="Creator"
                    value={
                      adjustDetails.basicInfo.creatorFirstName +
                      " " +
                      adjustDetails.basicInfo.creatorLastName
                    }
                  />
                  {adjustDetails.basicInfo.hasXeroManagedItems && detailsSuccess &&
                    userDetails.data.orgInfo.xeroConnected &&
                    userDetails.data.orgInfo.xeroConfigured ? (
                      <div>
                        <ViewLabel title="Sent to Xero" />
                        {adjustDetails.basicInfo.xeroJournalStatus === "ISSUED" ? (
                        <XeroLink
                        invoiceId={adjustDetails.basicInfo.xeroJournalId}
                        type={
                          adjustDetails.basicInfo.type === "RECEIVESTOCK"
                            ? "stock_adjust_receive_stock"
                            : "stock_adjust_deduct_stock"
                        }
                        />
                        ) : Number(adjustDetails.basicInfo.xeroTaskId) > 0 && adjustDetails.basicInfo.xeroTaskError ? (
                          <div>
                            <p className="text-red-500 ">
                              Error: {adjustDetails.basicInfo.xeroTaskError}
                            </p>
                              {refreshTaskSent && restartTaskStatus.success ? (
                                 <ProcessingButton text={"The Xero task has been restarted successfully. It may take 3-30 minutes to process the task."}/>
                              ) : (
                                <>
                                  {restartTaskStatus.loading ? (
                                    <Loader mess={"Requesting..."} />
                                  ) : (
                                    <button
                                      className="border-brandColor border px-3 py-1 rounded mb-2 hover:bg-brandColor hover:text-white"
                                      onClick={() =>
                                        restartXeroTask(
                                          adjustDetails.basicInfo.xeroTaskId
                                        )
                                      }
                                    >
                                      Resend to Xero
                                    </button>
                                  )}
                                  {restartTaskStatus.error && (
                                    <p className="text-red-500">
                                      {restartTaskStatus.error}
                                    </p>
                                  )}
                                </>
                              )}
                          </div>
                        ) : Number(adjustDetails.basicInfo.xeroTaskId) > 0 && !adjustDetails.basicInfo.xeroTaskError ? (
                          <ProcessingButton text={"Processing..."}/>
                        ) : !adjustDetails.basicInfo.xeroTaskId ? 
                        <div>
                          {
                            !adjustDetails.basicInfo.syncXero ? <div> {xeroTaskSent && sendToXeroStatus.success ? (
                              <ProcessingButton text={"The Xero task has been created successfully. It may take 3-30 minutes to process the task."}/>
                            ) : (
                              <>
                                {sendToXeroStatus.loading ? (
                                  <Loader mess={"Requesting..."} />
                                ) : (
                                  <button
                                    className="border-brandColor border px-3 py-1 rounded mb-2 hover:bg-brandColor hover:text-white"
                                    onClick={createAdjustOrderXeroTask}
                                  >
                                    Send Order to Xero
                                  </button>
                                )}
                                {sendToXeroStatus.error && (
                                  <p className="text-red-500">
                                    {sendToXeroStatus.error}
                                  </p>
                                )}
                              </>
                            )}</div> : <ProcessingButton text={"Processing..."}/>
                          }
                        </div> 
                        : 
                        <></>
                        }

                      </div>
                    ) : (
                      <></>
                    )}
                </div>
              </div>
            </div>

            {/* invoice section */}
            <div className="mt-8">
              <SubTitle name="Adjust Products" />
            </div>
            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Adjust Cost</th>
                    <th>Quantity</th>
                    <th>Adjust Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {adjustDetails.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.variantSku && (
                            <VariantSkuTag name={item.variantSku} />
                          )}
                        </td>
                        <td>{item.productName} </td>
                        <td>{item.unitName}</td>
                        <td>
                          <PriceText num={convtPrice(item.unitCost)} />
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>

                        <td>
                          <PriceText num={convtPrice(item.totalCost)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
