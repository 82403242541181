import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { MdFileCopy, MdLocalShipping } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import {
  CloneButtonLink,
  EditButtonLink,
  ViewButtonLink,
  DeleteButton,
  ShippingButton,
} from "../../../components/editComponents";
import {
  DeleteDialog,
  Modal,
  SimpleDialog,
  VoidSaleOrderDialog,
} from "../../../components/viewComponents";
import { shipSaleOrderRequest } from "../../../actions/saleOrderActions";
import SaleOrderShippingForm from "./SaleOrderShippingForm";
import {
  deleteSaleOrderRequest,
  voidSaleOrderRequest,
} from "../../../api/saleOrderServices";
import { checkXeroInvoiceStatusRequest } from "../../../api/xeroServices";

function EditLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_sale_order") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/sale/order/edit/${orderId}`}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <AiFillEdit className="w-4 h-4 mr-2" /> Edit
        </NavLink>
      ) : (
        <EditButtonLink path={`/sale/order/edit/${orderId}`} />
      )}
    </>
  ) : (
    <></>
  );
}

function CloneLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_sale_order") ? (
    <>
      {buttonView ? (
        <NavLink
          to={`/sale/order/new`}
          state={orderId}
          className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandYellow rounded border border-solid border-brandYellow hover:bg-brandYellow hover:text-white ${
            marginRight ? "mr-2" : ""
          }`}
        >
          <MdFileCopy className="w-4 h-4 mr-2" /> Clone
        </NavLink>
      ) : (
        <CloneButtonLink path={`/sale/order/new`} data={orderId} />
      )}
    </>
  ) : (
    <></>
  );
}

function PreviewLink({ orderId, buttonView = false, marginRight = false }) {
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  return userDetailsSuccess &&
    userDetails.data.permissions.includes("new_sale_order") ? (
    <ViewButtonLink path={`/sale/order/preview/${orderId}`} />
  ) : (
    <></>
  );
}

function ShippingSaleOrderButton({
  orderId,
  shipSuccess,
  buttonView = false,
  marginRight = false,
}) {
  const dispatch = useDispatch();
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: shippingLoading,
    error: shippingError,
    success: shippingSuccess,
  } = useSelector((state) => state.shipSaleOrder);

  const initialShippingValues = {
    shippingCompany: "",
    trackingNo: "",
  };

  const [shippingModal, setShippingModal] = useState(false);
  const [shippingDialog, setShippingDialog] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const openShippingModal = () => {
    setShippingModal(true);
  };

  const addShippingInformation = (shippingValues) => {
    setShippingModal(false);
    setShippingDialog(true);
    dispatch(shipSaleOrderRequest(shippingValues, orderId));
  };

  const closeDialogWithSuccess = () => {
    setShippingDialog(false);
    shipSuccess();
  };

  return (
    <div>
      <Modal
        title="Shipping"
        isModalOpen={shippingModal}
        closeModal={() => setShippingModal(false)}
        content={
          <SaleOrderShippingForm
            values={initialShippingValues}
            onSubmit={addShippingInformation}
            btnName="Ship Order"
          />
        }
      />
      <SimpleDialog
        title="Shipping"
        successMessage="Shipping information updated successfully."
        isDialogOpen={shippingDialog}
        closeDialog={() => setShippingDialog(false)}
        loading={shippingLoading}
        loadingMessage="Processing request..."
        success={shippingSuccess}
        error={shippingError}
        confirmAction={closeDialogWithSuccess}
      />
      {userDetailsSuccess ? (
        userDetails.data.permissions.includes("new_sale_order") ? (
          <>
            {buttonView ? (
              <button
                onClick={openShippingModal}
                className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-brandGreen rounded border border-solid border-brandGreen hover:bg-brandGreen hover:text-white ${
                  marginRight ? "mr-2" : ""
                }`}
              >
                <MdLocalShipping className="w-4 h-4 mr-2" /> Ship Order
              </button>
            ) : (
              <ShippingButton action={openShippingModal} />
            )}
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

function DeleteOrderButton({
  orderId,
  deleteSuccess,
  buttonView = false,
  marginRight = false,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };
  const sendDeleteRequest = async () => {
    try {
      setDeleteStatus({
        ...deleteStatus,
        loading: true,
        success: false,
        error: "",
      });
      const responseData = await deleteSaleOrderRequest(orderId, config);

      if (responseData.success) {
        setDeleteStatus({
          ...deleteStatus,
          loading: false,
          success: true,
        });
      } else {
        throw "Delete sale order failed.";
      }
    } catch (error) {
      setDeleteStatus({
        ...deleteStatus,
        loading: false,
        error: error,
      });
    }
  };

  const closeDeleteDialogWithSuccess = () => {
    deleteSuccess();
    setDeleteDialog(false);
    setDeleteStatus({
      loading: false,
      success: false,
      error: "",
    });
  };

  return (
    <div>
      <DeleteDialog
        title="Delete Sale Order"
        preDeleteMess={`Please note that the delete action can not be undone. Are you sure you want to proceed?`}
        successMessage="Sale order deleted sucessfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={closeDeleteDialogWithSuccess}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />

      {userDetailsSuccess &&
      userDetails.data.permissions.includes("new_sale_order") ? (
        <>
          {buttonView ? (
            <button
              onClick={openDeleteDialog}
              className={`h-11 flex flex-row items-center justify-center py-2 px-3 text-red-500 rounded border border-solid border-red-500 hover:bg-red-500 hover:text-white ${
                marginRight ? "mr-2" : ""
              }`}
            >
              <AiOutlineDelete className="w-4 h-4 mr-2" /> Delete
            </button>
          ) : (
            <DeleteButton action={openDeleteDialog} />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

function MoreActionLink({
  orderId,
  hasXeroInvoice,
  xeroInvoiceId,
  onVoidSuccess,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [filterOpen, setFilterOpen] = useState(false);
  const [voidDialog, setVoidDialog] = useState(false);
  const [xeroInvoiceStatusChecked, setXeroInvoiceStatusChecked] =
    useState(false);
  const [xeroInvoiceStatus, setXeroInvoiceStatus] = useState("");

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const voidOrderCLick = (e) => {
    e.preventDefault();
    setVoidDialog(true);
    setFilterOpen(false);

    if (hasXeroInvoice && xeroInvoiceId) {
      if (!xeroInvoiceStatusChecked) {
        checkXeroInvoiceStatus();
      }
    } else {
      setReqStatus({
        loading: false,
        success: true,
        error: "",
      });
    }
  };

  const checkXeroInvoiceStatus = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
        error: "",
        success: false,
      }));
      const responseData = await checkXeroInvoiceStatusRequest(
        xeroInvoiceId,
        config
      );
      setXeroInvoiceStatusChecked(true);
      if (responseData.data.success) {
        setXeroInvoiceStatus(responseData.data.data.status);
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else if (responseData.data.error === 30) {
        throw "The Xero invoice ID is not correct.";
      } else {
        throw "Check xero invoice status failed";
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
        success: false,
      }));
    }
  };

  const sendDeleteRequest = async () => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
        success: false,
        error: "",
      }));
      let req_data = {
        saleOrderId: orderId,
      };
      const responseData = await voidSaleOrderRequest(req_data, config);
      if (responseData.data.success) {
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
        success: false,
      }));
    }
  };

  const voidOrderSuccess = (pageName) => {
    setVoidDialog(false);
    onVoidSuccess(pageName);
  };

  return (
    <div className="relative">
      <VoidSaleOrderDialog
        title="Void Sale Order"
        successMessage="Sale order voided successfully."
        isDialogOpen={voidDialog}
        closeDialog={() => setVoidDialog(false)}
        closeDialogWithSuccess={voidOrderSuccess}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        checkLoading={reqStatus.loading}
        checkSuccess={reqStatus.success}
        checkError={reqStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
        hasXeroInvoice={hasXeroInvoice}
        xeroInvoiceStatus={xeroInvoiceStatus}
      />

      <div
        className="w-[44px] h-[44px] rounded flex items-center justify-center rounded border border-solid border-darkGray hover:bg-brandColor hover:text-white hover:cursor-pointer hover:border-brandColor"
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <IoIosMore className="w-4 h-4" />
      </div>

      <div
        className={`${
          filterOpen ? "absolute block top-14 right-0 z-20 " : "hidden"
        }  min-w-[140px] bg-white shadow-lg p-4 rounded`}
      >
        <div className="w-full">
          <NavLink
            to={`/sale/order/after-approve-edit/${orderId}`}
            className={`w-full h-8 flex flex-row items-center justify-start hover:underline text-brandColor`}
          >
            <AiFillEdit className="w-4 h-4 mr-2" />
            Edit
          </NavLink>

          {userDetailsSuccess &&
            userDetails.data.permissions.includes("void_sale_order") && (
              <button
                className="mt-2 w-full h-8 flex flex-row items-center justify-start hover:underline text-red-500"
                onClick={voidOrderCLick}
              >
                <AiOutlineDelete className="w-4 h-4 mr-2" />
                Void
              </button>
            )}
        </div>
      </div>
    </div>
  );
}

export {
  EditLink,
  CloneLink,
  PreviewLink,
  DeleteOrderButton,
  ShippingSaleOrderButton,
  MoreActionLink,
};
