import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {MdKeyboardArrowDown} from "react-icons/md";
import { saleOrderActionsRequest, saleOrderDetailsRequest } from "../../../actions/saleOrderActions";
import {
  generateInvoiceRequest,
  generatePacklingSlipRequest,
} from "../../../api/generatePDFServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import XeroLink from "../../../components/XeroLink";
import {
  DownloadPDFButton,
  EditButtonLink,
  EmailButton,
  ScreenTitle,
} from "../../../components/editComponents";
import {
  ErrorMessage,
  FromTag,
  Loader,
  Modal,
  PriceText,
  SubTitle,
  Tag,
  VariantSkuTag,
  ViewDateWithTime,
  ViewDateWithTitle,
  ViewLabel,
  ViewText,
} from "../../../components/viewComponents";
import {convtQuantity } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import purchaseStyle from "../../purchase/purchaseStyle.module.css";
import salesStyle from "../salesStyle.module.css";
import SoEmailForm from "./SoEmailForm";
import { SaleOrderInvoice } from "./invoiceComponents";
import { OrderAttachmentsPreview } from "../../../components/attachmentComponents";
import { createSaleOrderXeroTaskRequest, restartXeroTaskRequest } from "../../../api/xeroServices";
import { CloneLink, DeleteOrderButton, EditLink, MoreActionLink, ShippingSaleOrderButton } from "./soActionButtons";

export default function PreviewSaleOrderScreen() {
  useDocumentTitle("Preview Sale Order");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: soid } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const standard_config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, orderInfo, error, success } = useSelector(
    (state) => state.saleOrderDetails
  );
  const { loading:actionsLoading, actions, error: actionsError, success: actionsSuccess } = useSelector(
    (state) => state.saleOrderActions
  );

  const [emailModalOpen, setEmaiModalOpen] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [reqInvoiceStatus, setReqInvoiceStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [refreshTaskSent, setRefreshTaskSent] = useState(false);
  const [restartTaskStatus, setRestartTaskStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [xeroTaskSent, setXeroTaskSent] = useState(false);
  const [sendToXeroStatus, setSendToXeroStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  const [refreshCnt, setRefreshCnt] = useState(0);
  const [showActions, setShowActions] = useState(false);

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(saleOrderDetailsRequest(soid));
    dispatch(saleOrderActionsRequest(soid));
  }, [dispatch, soid, refreshCnt]);


  /////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  const downloadPackingSlipAction = async () => {
    try {
      setReqStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generatePacklingSlipRequest(soid, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(
          blob,
          `Packing_slip_${
            orderInfo.basicInfo.invoiceNumberSu
              ? orderInfo.basicInfo.invoiceNumberSu
              : orderInfo.basicInfo.orderNumber
          }.pdf`
        );

        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const downloadInvocieAction = async () => {
    try {
      setReqInvoiceStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await generateInvoiceRequest(soid, config);
      if (responseData.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([responseData.data]);
        saveAs(
          blob,
          `Invoice_${
            orderInfo.basicInfo.invoiceNumberSu
              ? orderInfo.basicInfo.invoiceNumberSu
              : orderInfo.basicInfo.orderNumber
          }.pdf`
        );

        setReqInvoiceStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqInvoiceStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const sendEmailAction = () => {
    setEmaiModalOpen(true);
  };
  ////////////////////////////////////////////////////////////////////////////////////////
  const restartXeroTask = async (taskId) => {
    try {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await restartXeroTaskRequest(taskId, standard_config);
      if (responseData.data.success) {
        setRefreshTaskSent(true);
        setRestartTaskStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRestartTaskStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const createSaleOrderXeroTask = async () => {
    try {
      setSendToXeroStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      let req_data = {
        orderId: soid
      }
      const responseData = await createSaleOrderXeroTaskRequest(req_data, standard_config);
      if (responseData.data.success) {
        setXeroTaskSent(true);
        setSendToXeroStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else {
        throw "Create xero task failed";
      }
    } catch (error) {
      setSendToXeroStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const addShippingInfoSuccessAction = () => {
    setRefreshCnt(refreshCnt + 1)
  }

  const deleteSuccessAction = () => {
    navigate(`/sale/sale-order-list`, {
      replace: true,
    });
  };

  const ProcessingButton = ({text}) => {
    const handProcessRefresh = (e) => {
      e.preventDefault();
      setRefreshCnt(refreshCnt + 1);
    }

    return(
      <div>
        <p className="text-green-500">{text}</p>
        <button onClick={handProcessRefresh} className="border-brandColor border px-3 py-1 rounded my-2 hover:bg-brandColor hover:text-white">
          Check Status
        </button>
      </div>
    )

  };

  const voidOrderSuccessAction = (pageName) => {
    
    if (pageName === "list"){
      navigate(`/sale/sale-order-list`, {
        replace: true,
        from: location,
      });
    }else{
      setRefreshCnt(refreshCnt + 1);
    }

  }


  return (
    <div className={commonStyle.pageContainer}>
      <Modal
        title="Send Email"
        isModalOpen={emailModalOpen}
        closeModal={() => setEmaiModalOpen(false)}
        content={
          <SoEmailForm
            customerEmail={success ? orderInfo.basicInfo.email : ""}
            orderInfo={orderInfo}
            closeModal={() => setEmaiModalOpen(false)}
          />
        }
      />

      <Breadcrumbs screenName={"Preview Sale Order"} />
      <ScreenTitle title={"Preview Sale Order"} />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting sale order details, please wait a moment..." />
        ) : success ? (
          <div>
            <div className={purchaseStyle.orderNumberRow}>
              <div className={purchaseStyle.orderNumberCon}>
                <div>
                  <ViewText title="Order No." />
                  <p> {orderInfo.basicInfo.orderNumber}</p>
                </div>
                {orderInfo.basicInfo.status === "Approved" ? (
                  <Tag name={orderInfo.basicInfo.status} color="green" />
                ) : orderInfo.basicInfo.status === "Draft" ? (
                  <Tag name={orderInfo.basicInfo.status} color="yellow" />
                ) : orderInfo.basicInfo.status === "Back Order" ? (
                  <Tag name={orderInfo.basicInfo.status} color="yellow" />
                ) : orderInfo.basicInfo.status === "Completed" ? (
                  <Tag name={orderInfo.basicInfo.status} color="blue" />
                ) : orderInfo.basicInfo.status === "Deleted" ? (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                ) : (
                  <Tag name={orderInfo.basicInfo.status} color="gray" />
                )}
                 {
                    orderInfo.basicInfo.sent && orderInfo.basicInfo.sent === 1 ? <Tag name={"Sent"} color="brandBlue" /> : <></>
                  }
              </div>
              <div>
              <div className={commonStyle.actionsGrop}>
                  {(orderInfo.basicInfo.status === "Draft" || orderInfo.basicInfo.status === "Back Order") &&  <EditLink orderId={orderInfo.basicInfo.id} buttonView={true} marginRight={true}/>}
                  {orderInfo.basicInfo.status === "Approved" && orderInfo.basicInfo.isNeedShipping && !orderInfo.basicInfo.trackingNo && <ShippingSaleOrderButton orderId={orderInfo.basicInfo.id} buttonView={true} marginRight={true} shipSuccess={addShippingInfoSuccessAction}/>}
                  <CloneLink orderId={orderInfo.basicInfo.id} buttonView={true} marginRight={true}/>
                  {(orderInfo.basicInfo.status === "Draft" || orderInfo.basicInfo.status === "Back Order") && <DeleteOrderButton orderId={orderInfo.basicInfo.id} deleteSuccess={deleteSuccessAction} buttonView={true} marginRight={true}/>}
                  {(orderInfo.basicInfo.status === "Approved" || orderInfo.basicInfo.status === "Completed") &&  
                  <MoreActionLink 
                  orderId={orderInfo.basicInfo.id} 
                  hasXeroInvoice={orderInfo.basicInfo.invoiceId ? true : false} 
                  xeroInvoiceId={orderInfo.basicInfo.invoiceId} onVoidSuccess={voidOrderSuccessAction}/>} 
                
              </div>
              </div>
            </div>

            {/*  order infomation */}
            <div className="mt-4">
              <SubTitle name="Order Information" />
              <div className={purchaseStyle.orderInfoWrapper}>
                <div className={purchaseStyle.basicInfoWrapper}>
                  <div className={commonStyle.col1}>
                    <ViewDateWithTitle
                      title="Issue Date"
                      value={orderInfo.basicInfo.issueDate}
                    />

                    {orderInfo.basicInfo.noCustomer ? (
                      <></>
                    ) : (
                      <>
                        <ViewText
                          title="Customer"
                          value={orderInfo.basicInfo.customerName}
                        />

                        <ViewText
                          title="Customer Contact"
                          value={
                            orderInfo.basicInfo.firstName +
                            " " +
                            orderInfo.basicInfo.lastName
                          }
                        />
                        <ViewText
                          title="Email"
                          value={orderInfo.basicInfo.email}
                        />
                        <ViewText
                          title="Phone"
                          value={orderInfo.basicInfo.phone}
                        />

                        <ViewLabel title="Billing Address" />
                        {orderInfo.basicInfo.billingAddress.country ? (
                          <p>
                            {orderInfo.basicInfo.billingAddress.country.value}
                          </p>
                        ) : (
                          <></>
                        )}
                        {orderInfo.basicInfo.billingAddress.region ? (
                          <p>{orderInfo.basicInfo.billingAddress.region}</p>
                        ) : (
                          <></>
                        )}
                        {orderInfo.basicInfo.billingAddress.city ? (
                          <p>{orderInfo.basicInfo.billingAddress.city}</p>
                        ) : (
                          <></>
                        )}
                        {orderInfo.basicInfo.billingAddress.addressLine ? (
                          <p>
                            {orderInfo.basicInfo.billingAddress.addressLine}
                          </p>
                        ) : (
                          <></>
                        )}
                        {orderInfo.basicInfo.billingAddress.postalCode ? (
                          <p>{orderInfo.basicInfo.billingAddress.postalCode}</p>
                        ) : (
                          <></>
                        )}

                        <ViewText
                          title="Need Shipping?"
                          value={
                            orderInfo.basicInfo.isNeedShipping ? "Yes" : "No"
                          }
                        />
                        {orderInfo.basicInfo.isNeedShipping ? (
                          <>
                            <ViewLabel title="Shipping Address" />
                            {orderInfo.basicInfo.shippingAddress.country ? (
                              <p>
                                {
                                  orderInfo.basicInfo.shippingAddress.country
                                    .value
                                }
                              </p>
                            ) : (
                              <></>
                            )}
                            {orderInfo.basicInfo.shippingAddress.region ? (
                              <p>
                                {orderInfo.basicInfo.shippingAddress.region}
                              </p>
                            ) : (
                              <></>
                            )}
                            {orderInfo.basicInfo.shippingAddress.city ? (
                              <p>{orderInfo.basicInfo.shippingAddress.city}</p>
                            ) : (
                              <></>
                            )}
                            {orderInfo.basicInfo.shippingAddress.addressLine ? (
                              <p>
                                {
                                  orderInfo.basicInfo.shippingAddress
                                    .addressLine
                                }
                              </p>
                            ) : (
                              <></>
                            )}
                            {orderInfo.basicInfo.shippingAddress.postalCode ? (
                              <p>
                                {orderInfo.basicInfo.shippingAddress.postalCode}
                              </p>
                            ) : (
                              <></>
                            )}

                            <ViewText
                              title="Shipping Company"
                              value={
                                orderInfo.basicInfo.shippingCompany
                                  ? orderInfo.basicInfo.shippingCompany
                                  : "Waiting for shipping"
                              }
                            />
                            <ViewText
                              title="Tracking Number"
                              value={
                                orderInfo.basicInfo.trackingNo
                                  ? orderInfo.basicInfo.trackingNo
                                  : "Null"
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        <ViewText
                          title="Salesperson"
                          value={
                            orderInfo.basicInfo.salespersonName
                              ? orderInfo.basicInfo.salespersonName
                              : "Null"
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className={commonStyle.col2}>
                    <ViewText
                      title="Location"
                      value={orderInfo.basicInfo.locationName}
                    />
                    {orderInfo.basicInfo.priceTierName && (
                      <ViewText
                        title="Price Tier"
                        value={orderInfo.basicInfo.priceTierName}
                      />
                    )}

                    <ViewText
                      title="Tax Rate"
                      value={orderInfo.basicInfo.taxrateName}
                    />
                    <ViewText
                      title="Currecy"
                      value={orderInfo.basicInfo.currency}
                    />

                    <ViewText
                      title="Payment Term"
                      value={
                        orderInfo.basicInfo.paymentTerm +
                        " Days after issue date"
                      }
                    />

                    {orderInfo.basicInfo.projectName && (
                      <ViewText
                        title="Project"
                        value={orderInfo.basicInfo.projectName}
                      />
                    )}
                    <ViewText title="Notes" value={orderInfo.basicInfo.notes} />

                    {orderInfo.basicInfo.suInvoice && (
                      <ViewText
                        title="Invoice Number (StockUnify Generated)"
                        value={orderInfo.basicInfo.invoiceNumberSu}
                      />
                    )}
                    {detailsSuccess &&
                    userDetails.data.orgInfo.xeroConnected &&
                    userDetails.data.orgInfo.xeroConfigured ? (
                      <div>
                        <ViewLabel title="Sent to Xero" />
                        {orderInfo.basicInfo.invoiceStatus === "ISSUED" ? (
                        <XeroLink
                        invoiceId={orderInfo.basicInfo.invoiceId}
                        type="sale_order_invoice"
                        />
                        ) : Number(orderInfo.basicInfo.xeroTaskId) > 0 && orderInfo.basicInfo.xeroTaskError ? (
                          <div>
                            <p className="text-red-500 ">
                              Error: {orderInfo.basicInfo.xeroTaskError}
                            </p>
                              {refreshTaskSent && restartTaskStatus.success ? (
                                 <ProcessingButton text={"The Xero task has been restarted successfully. It may take 3-30 minutes to process the task."}/>
                              ) : (
                                <>
                                  {restartTaskStatus.loading ? (
                                    <Loader mess={"Requesting..."} />
                                  ) : (
                                    <button
                                      className="border-brandColor border px-3 py-1 rounded mb-2 hover:bg-brandColor hover:text-white"
                                      onClick={() =>
                                        restartXeroTask(
                                          orderInfo.basicInfo.xeroTaskId
                                        )
                                      }
                                    >
                                      Resend to Xero
                                    </button>
                                  )}
                                  {restartTaskStatus.error && (
                                    <p className="text-red-500">
                                      {restartTaskStatus.error}
                                    </p>
                                  )}
                                </>
                              )}
                          </div>
                        ) : Number(orderInfo.basicInfo.xeroTaskId) > 0 && !orderInfo.basicInfo.xeroTaskError ? (
                          <ProcessingButton text={"Processing..."}/>
                        ) : !orderInfo.basicInfo.xeroTaskId ? 
                        <div>
                          {
                            !orderInfo.basicInfo.syncXero ? <div> {xeroTaskSent && sendToXeroStatus.success ? (
                              <ProcessingButton text={"The Xero task has been created successfully. It may take 3-30 minutes to process the task."}/>
                            ) : (
                              <>
                                {sendToXeroStatus.loading ? (
                                  <Loader mess={"Requesting..."} />
                                ) : (
                                  <div>
                                    {orderInfo.basicInfo.status !== "Void" && 
                                     <button
                                     className="border-brandColor border px-3 py-1 rounded mb-2 hover:bg-brandColor hover:text-white"
                                     onClick={createSaleOrderXeroTask}
                                   >
                                     Send Order to Xero
                                   </button>

                                    }

                                  </div>
                                )}
                                {sendToXeroStatus.error && (
                                  <p className="text-red-500">
                                    {sendToXeroStatus.error}
                                  </p>
                                )}
                              </>
                            )}</div> : <ProcessingButton text={"Processing..."}/>
                          }
                        </div> 
                        : 
                        <></>
                        }

                      </div>
                    ) : (
                      <></>
                    )}

                   
                    {orderInfo.basicInfo.source === "xerohook" ? (
                      <ViewText title="Source" value={"Synced from Xero"} />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {
                  orderInfo.basicInfo.status !== "Void" &&  <div className={purchaseStyle.invoiceConPreview}>
                  <DownloadPDFButton
                    name="Download Packing Slip"
                    action={downloadPackingSlipAction}
                  />

                  {orderInfo.basicInfo.invoiceStatus === "ISSUED" ? (
                    <SaleOrderInvoice
                      name={orderInfo.basicInfo.invoiceNumber}
                      invoiceId={orderInfo.basicInfo.invoiceId}
                      detailspage={true}
                    />
                  ) : (
                    <></>
                  )}
                  {orderInfo.basicInfo.suInvoice && (
                    <DownloadPDFButton
                      name="Download Invoice (StockUnify Generated)"
                      action={downloadInvocieAction}
                    />
                  )}

                  <EmailButton name="Send Email" action={sendEmailAction} />
                </div>
                }

               
              </div>
            </div>

            <div className="flex flex-row items-end justify-between mt-4">
              <SubTitle name="Order Products" />
              <p className="text-gray ">
                {orderInfo.basicInfo.taxIncluded
                  ? "* Amounts are tax inclusive"
                  : "* Amounts are tax exclusive"}
              </p>
            </div>

            <div className={commonStyle.longDataTable}>
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Net Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderInfo.orderItems.map((item, index1) => {
                    return (
                      <tr key={index1}>
                        <td>
                          {item.productName ? (
                            <VariantSkuTag name={item.variantSku} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {item.type === "package" ? (
                            <div className={salesStyle.productPackageName}>
                              {item.packageName}
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              {item.type === "packageProduct" ? (
                                <div className={salesStyle.packageProductTag}>
                                  Package
                                </div>
                              ) : (
                                <></>
                              )}
                              {item.productName}
                              {item.variant ? (
                                <span className={purchaseStyle.variantTag}>
                                  {item.variant}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </td>

                        <td>{item.productName ? item.unitName : ""}</td>

                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.type !== "packageProduct" ? (
                            <PriceText num={item.unitPrice} />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td
                          style={{
                            width: "10%",
                          }}
                        >
                          {item.type === "packageProduct" ? (
                            <></>
                          ) : (
                            <>
                              {item.discount}% <FromTag name="OFF" />
                            </>
                          )}
                        </td>
                        <td>
                          {item.type === "packageProduct" ? (
                            <></>
                          ) : (
                            <>
                              {item.discount ? (
                                <PriceText
                                  num={
                                    (1 - Number(item.discount) / 100) *
                                    Number(item.unitPrice)
                                  }
                                />
                              ) : (
                                <PriceText num={item.unitPrice} />
                              )}
                            </>
                          )}
                        </td>
                        <td>{convtQuantity(item.quantity)}</td>

                        <td>
                          {item.type !== "packageProduct" ? (
                            <PriceText num={item.totalPrice} />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7" className={commonStyle.tableFootName}>
                      Total
                    </td>

                    <td>
                      <PriceText num={orderInfo.orderSummary.productsTotal} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="mt-8">
              <div className={purchaseStyle.summaryWrapper}>
                <div className={purchaseStyle.costCon}>
                  {/* additional cost */}
                  <SubTitle name="Additional Charge" />
                  <table className={commonStyle.pageTable}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orderInfo.orderCosts.map((c, index2) => {
                        return (
                          <tr key={index2}>
                            <td
                              className={purchaseStyle.productNameCol}
                              style={{
                                width: "60%",
                              }}
                            >
                              {c.costName}
                            </td>

                            <td
                              style={{
                                width: "36%",
                              }}
                            >
                              <PriceText num={c.cost} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className={commonStyle.tableFootName}>Total</td>

                        <td>
                          <PriceText
                            num={orderInfo.orderSummary.additionalCostTotal}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="mt-4">
                    <OrderAttachmentsPreview files={orderInfo.orderFiles} />
                  </div>
                </div>

                <div className={purchaseStyle.summaryCon}>
                  {/* summary total price */}
                  <SubTitle name="Order Summary" />
                  <table
                    className={commonStyle.summaryTable}
                    style={{ marginTop: "12px" }}
                  >
                    {orderInfo.basicInfo.taxIncluded ? (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.productsTotal}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.additionalCostTotal}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            <PriceText
                              num={orderInfo.basicInfo.orderTotalInclTax}
                            />
                            {orderInfo.basicInfo.currency}
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.basicInfo.orderTotalExclTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.basicInfo.orderTotalTax}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Products Total
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.productsTotal}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className={commonStyle.tableFootName}>
                            Additional Charge Total
                          </td>
                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.orderSummary.additionalCostTotal}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Excl.
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.basicInfo.orderTotalExclTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.tableFootName}>
                            Tax
                            <span className={commonStyle.taxText}>
                              {orderInfo.taxRate
                                ? "-" + orderInfo.basicInfo.taxRate.value
                                : ""}
                            </span>
                          </td>

                          <td style={{ width: "30%" }}>
                            <PriceText
                              num={orderInfo.basicInfo.orderTotalTax}
                            />
                          </td>
                        </tr>

                        <tr className={commonStyle.summaryTableTotalRow}>
                          <td className={commonStyle.summaryTableTotalName}>
                            Order Total{" "}
                            <span className={commonStyle.taxText}>
                              Tax Incl.
                            </span>
                          </td>

                          <td className={commonStyle.summaryTableTotalValue}>
                            <PriceText
                              num={orderInfo.basicInfo.orderTotalInclTax}
                            />
                            {orderInfo.basicInfo.currency}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
             {/*  order actions */}
             <div className="mb-20 mt-4">
              <div className="w-full flex flex-row items-center justify-between h-12 bg-lightbg p-4 border-borderGray border border-solid hover:cursor-pointer" onClick={()=>setShowActions(!showActions)}>
                <p className="font-medium">Order History</p> 
                <MdKeyboardArrowDown
                  className={`w-5 h-5 transition-transform duration-300 ${
                    showActions ? 'rotate-180' : 'rotate-0'
                  }`}
                />
        
              </div>
              {
                showActions && actionsSuccess && <div className="w-full border-borderGray border border-solid px-4">
                <table className={commonStyle.logsTable}>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>Time</th>
                      <th>Action</th>
                      <th>Detail</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actions.map((item, index3) => (
                      <tr key={index3}>
                        <td><ViewDateWithTime value={item.atime} /></td>
                        <td>{item.action}</td>
                        <td className="w-1/2">{item.notes}</td>
                        <td>
                          {item.firstName}{" "}
                          {item.lastName ? item.lastName : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              }
           
             </div>
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}
