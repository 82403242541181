import React, { useEffect } from "react";
import { FcSalesPerformance } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import {
    invBoardDataRequest
} from "../../actions/dashboardActions";
import {
  DollarTag,
    ErrorMessage,
    Loader,
    ViewDateWithTime,
} from "../../components/viewComponents";
import {
    convtDisplayPrice
} from "../../functions/functions";
import dashboardStyle from "./dashboardStyle.module.css";

export default function InventoryBoard() {
  const dispatch = useDispatch();

  const { userDetails, success: userDetailsSuccess, loading: userDetailsLoading, error: userDetailsError } = useSelector(
    (state) => state.userDetail
  );

  const { loading, invData, success, error } = useSelector(
    (state) => state.invBoard
  );

  useEffect(() => {
    dispatch(invBoardDataRequest());
  }, [dispatch]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const InventoryStatistic = ({ name, value, type = "number" }) => {
    return (
      <div className="w-full bg-lightbg flex flex-col py-4 rounded hover:bg-brandColor hover:text-white hover:cursor-pointer">
        <p className="text-center">{name}</p>
        <p className="w-full text-center text-2xl font-medium mt-1">{type==="currency" && <DollarTag />}{value}</p>
      </div>
    );
  };

  return (
    <div className={dashboardStyle.section_wrapper}>
      <div className="w-full">
        {loading ? (
          <Loader mess="Requesting data...please wait a moment" />
        ) : success ? (
          <>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <FcSalesPerformance className={dashboardStyle.section_icon} />
                <p className={dashboardStyle.section_title}>Overview</p>
              </div>
              {
                invData.atime > 0 ?  <div className="flex flex-row items-center">
                <span className="mr-1">Last Update:</span>
                <ViewDateWithTime value={invData.atime} />
              </div> : <></>
              }
             
            </div>
            <div className="w-full grid grid-cols-5 gap-4 mt-4">
              <InventoryStatistic
                name="Total Products"
                value={invData.product_cnt ? invData.product_cnt : 0}
                type="number"
              />
              {userDetailsSuccess &&
              userDetails.data.permissions.includes("total_soh_value") &&
                <InventoryStatistic
                  name="Total Stock on Hand Value"
                  value={invData.product_value ? (convtDisplayPrice(invData.product_value)) : 0}
                  type="currency"
                />
              }
              <InventoryStatistic
                name="Total Customers"
                value={invData.customer_cnt ? invData.customer_cnt : 0 }
                type="number"
              />
              <InventoryStatistic
                name="Total Suppliers"
                value={invData.supplier_cnt ? invData.supplier_cnt : 0}
                type="number"
              />
              {userDetailsSuccess &&
              userDetails.data.permissions.includes("total_created_po_value") &&
                <InventoryStatistic
                  name="Created PO Value"
                  value={invData.po_value ? (convtDisplayPrice(invData.po_value)) : 0}
                  type="currency"
                />
              }
            
            </div>
          </>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
