const K_COUNTRIES = [
    {id: 2, value: "Australia", label: "Australia"},
    {id: 1, value: "New Zealand", label: "New Zealand"},
    {id: 3, value: "United Kingdom", label: "United Kingdom"},
    {id: 8, value: "South Africa", label: "South Africa"},
    {id: 18, value: "Singapore", label: "Singapore"},
    {id: 4, value: "United States", label: "United States"},
    {id: 13, value: "Hong Kong", label: "Hong Kong"},
    {id: 16, value: "Argentina", label: "Argentina"},
    {id: 38, value: "Botswana", label: "Botswana"},
    {id: 7, value: "Brazil", label: "Brazil"},
    {id: 32, value: "Cameroon", label: "Cameroon"},
    {id: 5, value: "Canada", label: "Canada"},
    {id: 39, value: "Ethiopia", label: "Ethiopia"},
    {id: 19, value: "Fiji", label: "Fiji"},
    {id: 10, value: "France", label: "France"},
    {id: 11, value: "Germany", label: "Germany"},
    {id: 35, value: "Ghana", label: "Ghana"},
    {id: 26, value: "India", label: "India"},
    {id: 12, value: "Italy", label: "Italy"},
    {id: 15, value: "Japan", label: "Japan"},
    {id: 33, value: "Kenya", label: "Kenya"},
    {id: 25, value: "Kiribati", label: "Kiribati"},
    {id: 16, value: "Malta", label: "Malta"},
    {id: 30, value: "Malaysia", label: "Malaysia"},
    {id: 6, value: "Mexico", label: "Mexico"},
    {id: 23, value: "Micronesia", label: "Micronesia"},
    {id: 31, value: "Nigeria", label: "Nigeria"},
    {id: 27, value: "Pakistan", label: "Pakistan"},
    {id: 28, value: "Philippines", label: "Philippines"},
    {id: 36, value: "Rwanda", label: "Rwanda"},
    {id: 22, value: "Solomon Islands", label: "Solomon Islands"},
    {id: 20, value: "Samoa", label: "Samoa"},
    {id: 9, value: "Spain", label: "Spain"},
    {id: 29, value: "Sri Lanka", label: "Sri Lanka"},
    {id: 37, value: "Sudan", label: "Sudan"},
    {id: 14, value: "Taiwan", label: "Taiwan"},
    {id: 21, value: "Tonga", label: "Tonga"},
    {id: 24, value: "Vanuatu", label: "Vanuatu"},
    {id: 34, value: "Zimbabwe", label: "Zimbabwe"},
    {id: 17, value: "Other", label: "Other"},
  ];

  export {
    K_COUNTRIES
  };