import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createUserOptionsRequest } from "../../../actions/userRoleActions";
import {
  SimpleBlueButton,
  AddLinkOutlineButton,
  LoadingButton,
  DeleteButton,
  SimpleOutlineDeleteButton,
} from "../../../components/editComponents";
import {
  StyledMultiSelect,
  StyledSelect,
  StyledInput,
  StyledEmailInput,
} from "../../../components/inputFields";
import {
  DeleteDialog,
  ErrorMessage,
  ErrorText,
  Loader,
  RoleTag,
  SuccessText,
  ViewLabel,
} from "../../../components/viewComponents";
import settingsStyle from "../settingsStyle.module.css";
import { deleteInactiveUserRequest } from "../../../api/userServices";
import { useLocation, useNavigate } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function UserDetailForm({ btnName, values, onSubmit, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-type": "application/json",
    },
  };

  useEffect(() => {
    dispatch(createUserOptionsRequest());
  }, [dispatch]);

  // get roles list
  const { loading, options, error, success } = useSelector(
    (state) => state.createUserOptions
  );

  const [userValues, setUserValues] = useState(values);
  const [formErrors, setFormErrors] = useState({});

  const [sendEmailStatus, setSendEmailStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteDialog,setDeleteDialog] = useState(false);

  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const checkValidation = () => {
    const hasErrors = validate(userValues);

    if (Object.keys(hasErrors).length === 0) {
      onSubmit(userValues);
    } else {
      setFormErrors(hasErrors);
    }
  };
  // handle change when input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserValues({ ...userValues, [name]: value });
  };

  // handle email input field change
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const errors = {};
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (re.test(value)) {
      // this is a valid email address
      setFormErrors({});
      setUserValues({ ...userValues, [name]: value });
    } else {
      // invalid email, maybe show an error to the user.
      setUserValues({ ...userValues, [name]: value });
      errors.userEmail = "Please enter a valid email address";
      setFormErrors(errors);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////
  const sendInviteEmail = () => {
    let ueserEmail = {
      userEmail: userValues.userEmail,
    };

    try {
      setSendEmailStatus({ ...sendEmailStatus, loading: true });
      axios
        .post(SERVER_URL + `/user/send_activate_email`, ueserEmail, config)
        .then(async (response) => {
          if (response.data.success) {
            setSendEmailStatus({
              ...sendEmailStatus,
              loading: false,
              success: true,
            });
          } else if (response.data.error === 10) {
            setSendEmailStatus({
              ...sendEmailStatus,
              loading: false,
              error: "Account is activated",
            });
          } else {
            setSendEmailStatus({
              ...sendEmailStatus,
              loading: false,
              error: "Resend invite email failed, please try again later.",
            });
          }
        })
        .catch((err) => {
          setSendEmailStatus({
            ...sendEmailStatus,
            loading: false,
            error: "Resend invite email failed, please try again later.",
          });
        });
    } catch (error) {
      setSendEmailStatus({
        ...sendEmailStatus,
        loading: false,
        error: "Connection error, please try again later.",
      });
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////

  // check input validation
  const validate = (values) => {
    const errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.userEmail) {
      errors.userEmail = "Email is required!";
    } else if (!regexEmail.test(values.userEmail)) {
      errors.userEmail = "Email is invalid!";
    }
    if (!values.firstName) {
      errors.firstName = "First name is required!";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required!";
    }
    if (!values.location) {
      errors.location = "Location is required!";
    }
    if (values.roles.length === 0) {
      errors.roles = "User role is required";
    }
    return errors;
  };

  const getRolesOptions = () => {
    let roleOptions = [];
    options.roleOptions.forEach((x) => {
      if (Number(x.id) !== 1) {
        roleOptions.push(x);
      }
    });
    return roleOptions;
  };

// deleteUser

  const deleteUser = async () => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
        success: false,
        error: "",
      }));
      const responseData = await deleteInactiveUserRequest(values.id, config);
      if (responseData.data.success) {
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
          error: "",
        }));
      } else if (responseData.data.error === 10) {
        throw "User does not exist.";
      } else if (responseData.data.error === 20) {
        throw "User account is activated, can not been deleted.";
      } else {
        throw "Delete user failed.";
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };
  const closeDeleteDialogWithSuccess = () => {
    setDeleteDialog(false);
    navigate("/settings/users", { replace: true, from: location });
  }

  return (
    <div>
      <DeleteDialog
        title="Delete User"
        preDeleteMess="Are you sure you want to proceed with delete this user?"
        successMessage="User deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={closeDeleteDialogWithSuccess}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={deleteUser}
        proceedBtnName={"Delete"}
      />

      {loading ? (
        <Loader mess="Requesting data, please wait a moment..." />
      ) : success ? (
        <div style={{ width: "80%" }}>
          <div style={{ display: "flex" }}>
            <div className={settingsStyle.col1}>
              <StyledInput
                label="First Name*"
                type="text"
                name="firstName"
                autofocus="autofocus"
                value={userValues.firstName}
                onChange={(firstName) =>
                  handleChange({
                    target: { value: firstName, name: "firstName" },
                  })
                }
                error={formErrors.firstName}
              />
               <StyledSelect
                  label="Default Location*"
                  placeHolder="Select location"
                  selectOptions={options.locationOptions}
                  value={userValues.location}
                  onChange={(location) =>
                    handleChange({
                      target: { value: location, name: "location" },
                    })
                  }
                  error={formErrors.location}
                />
              <StyledEmailInput
                label="Email*"
                type="email"
                name="userEmail"
                value={userValues.userEmail}
                isReadOnly={type === "edit" && userValues.isActivated === 1 ? true : false}
                onChange={(userEmail) =>
                  handleEmailChange({
                    target: { value: userEmail, name: "userEmail" },
                  })
                }
                error={formErrors.userEmail}
              />

              <StyledInput
                label="Position"
                type="text"
                name="position"
                value={userValues.position}
                onChange={(position) =>
                  handleChange({
                    target: { value: position, name: "position" },
                  })
                }
                error={formErrors.position}
              />
             
            </div>
            <div className={settingsStyle.col2}>
              <StyledInput
                label="Last Name*"
                type="text"
                name="lastName"
                value={userValues.lastName}
                onChange={(lastName) =>
                  handleChange({
                    target: { value: lastName, name: "lastName" },
                  })
                }
                error={formErrors.lastName}
              />

              <StyledInput
                label="Phone"
                type="text"
                name="userPhone"
                value={userValues.userPhone}
                onChange={(userPhone) =>
                  handleChange({
                    target: { value: userPhone, name: "userPhone" },
                  })
                }
                error={formErrors.userPhone}
              />
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <StyledSelect
                  label="Department"
                  placeHolder="Select department"
                  selectOptions={options.departmentOptions}
                  value={userValues.department}
                  onChange={(department) =>
                    handleChange({
                      target: { value: department, name: "department" },
                    })
                  }
                  error={formErrors.department}
                />
                <AddLinkOutlineButton
                  name="New Department"
                  path="/company-settings/departments"
                />
              </div>
              
            </div>
          </div>
          {userValues.isAdmin === 1 ? (
            <>
            <ViewLabel title = "Roles*"/>
            <RoleTag userRoles={[userValues.roles[0].label]} />
            </>
            
          ) : (
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <StyledMultiSelect
                label="Roles*"
                selectOptions={success ? getRolesOptions() : []}
                placeHolder="Select roles"
                value={userValues.roles}
                onChange={(roles) =>
                  handleChange({
                    target: { value: roles, name: "roles" },
                  })
                }
                error={formErrors.roles}
              />
              <AddLinkOutlineButton
                name="New Role"
                path="/settings/roles/new"
              />
            </div>
          )}

          <div className="w-full grid grid-cols-1 gap-4 mt-6">
            <SimpleBlueButton name={btnName} action={checkValidation} />
            {Number(userValues.isActivated) === 0 ? (
              sendEmailStatus.loading ? (
                <LoadingButton name="Sending email..." />
              ) : (
                <SimpleBlueButton
                  name="Resend Invite User Email"
                  action={sendInviteEmail}
                />
              )
            ) : (
              <></>
            )}
            {sendEmailStatus.error ? (
              <ErrorText mess={sendEmailStatus.error} />
            ) : sendEmailStatus.success ? (
              <SuccessText mess="The invitation email was sent successfully." />
            ) : (
              <></>
            )}
            {Number(userValues.isActivated) === 0 ? 
            <SimpleOutlineDeleteButton
                  name="Delete User"
                  action={()=>setDeleteDialog(true)}
                /> : (
              <></>
            )}
         
          </div>
        </div>
      ) : (
        <ErrorMessage mess={error} />
      )}
    </div>
  );
}
