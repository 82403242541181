import axios from "axios";
import {serviceMiddleware} from "./serviceMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const removeUserRequest = async (userId, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/user/remove/${userId}`, {}, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteInactiveUserRequest = async (userId, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/user/delete-inactive-user/${userId}`, {}, config);
    return response;
  } catch (error) {
    throw error;
  }
};






