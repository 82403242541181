// button component with blue color
function SmallDialogButton({ name, action, marginRight=false }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button
      className={`w-fit text-base h-8 text-textColor border border-solid border-brandColor hover:bg-brandColor hover:text-white rounded px-3 ${marginRight ? "mr-4" : ""}`}
      onClick={handleClick}
    >
      {name}
    </button>
  );
}

function DialogProceedButton({ name, action, marginRight=false }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button
      className={`w-fit text-base h-10 bg-brandColor text-white border border-solid border-brandColor hover:bg-brandHover rounded px-3 ${marginRight ? "mr-4" : ""}`}
      onClick={handleClick}
    >
      {name}
    </button>
  );
}

function DialogOutlineButton({ name, action, marginRight=false }) {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent auto-submit
    action(); // Call the original action
  };

  return (
    <button
      className={`w-fit text-base h-10 text-textColor border border-solid border-brandColor hover:bg-brandColor hover:text-white rounded px-3 ${marginRight ? "mr-4" : ""}`}
      onClick={handleClick}
    >
      {name}
    </button>
  );
}

export { SmallDialogButton, DialogProceedButton, DialogOutlineButton };
