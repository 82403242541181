import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// get package list

export const restartXeroTaskRequest = async (taskId, config) => {
   try {
     const response = await axios.get(SERVER_URL + `/integration/xero/restart_task/${taskId}`, config)
     return response;
   } catch (error) {
     throw error;
   }
 
};

export const createSaleOrderXeroTaskRequest = async (data, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/sale_order/create_xero_task`, data, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const createStockAdjustXeroTaskRequest = async (data, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/product/stock_adjustment/create_xero_task`, data, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkXeroInvoiceStatusRequest = async (invoiceId, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/integration/xero/check_invoice_status/${invoiceId}`, config)
    return response;
  } catch (error) {
    throw error;
  }

};


export const createPurchaseOrderXeroTaskRequest = async (data, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/purchase_order/create_xero_task`, data, config)
    return response;
  } catch (error) {
    throw error;
  }
};
