import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAIL_FAIL,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  NEW_CATEGORY_REQUEST,
  NEW_CATEGORY_SUCCESS,
  NEW_CATEGORY_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  PRODUCT_OPTIONS_REQUEST,
  PRODUCT_OPTIONS_FAIL,
  PRODUCT_OPTIONS_SUCCESS,
  CREATE_NEW_PRODUCT_REQUEST,
  CREATE_NEW_PRODUCT_SUCCESS,
  CREATE_NEW_PRODUCT_FAIL,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
  PRODUCT_ADJUST_DETAIL_REQUEST,
  PRODUCT_ADJUST_DETAIL_SUCCESS,
  PRODUCT_ADJUST_DETAIL_FAIL,
  STOCKTAKE_LIST_REQUEST,
  STOCKTAKE_LIST_SUCCESS,
  STOCKTAKE_LIST_FAIL,
  DIRECTLY_RECEIVE_STOCK_REQUEST,
  DIRECTLY_RECEIVE_STOCK_SUCCESS,
  DIRECTLY_RECEIVE_STOCK_FAIL,
  STOCK_ADJUST_LIST_REQUEST,
  STOCK_ADJUST_LIST_SUCCESS,
  STOCK_ADJUST_LIST_FAIL,
  STOCK_ADJUST_DETAILS_REQUEST,
  STOCK_ADJUST_DETAILS_SUCCESS,
  STOCK_ADJUST_DETAILS_FAIL,
  PRODUCT_PACKAGE_LIST_REQUEST,
  PRODUCT_PACKAGE_LIST_SUCCESS,
  PRODUCT_PACKAGE_LIST_FAIL,
  NEW_EDIT_PRODUCT_PACKAGE_REQUEST,
  NEW_EDIT_PRODUCT_PACKAGE_SUCCESS,
  NEW_EDIT_PRODUCT_PACKAGE_FAIL,
  PRODUCT_PACKAGE_DETAILS_REQUEST,
  PRODUCT_PACKAGE_DETAILS_SUCCESS,
  PRODUCT_PACKAGE_DETAILS_FAIL,
  SERIAL_BATCH_LIST_REQUEST,
  SERIAL_BATCH_LIST_SUCCESS,
  SERIAL_BATCH_LIST_FAIL,
  PRODUCT_UNIT_LIST_REQUEST,
  PRODUCT_UNIT_LIST_SUCCESS,
  PRODUCT_UNIT_LIST_FAIL,
  NEW_UNIT_REQUEST,
  NEW_UNIT_SUCCESS,
  NEW_UNIT_FAIL,
  UPDATE_UNIT_REQUEST,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  BULK_STOCK_TRANSFER_REQUEST,
  BULK_STOCK_TRANSFER_SUCCESS,
  BULK_STOCK_TRANSFER_FAIL,
  STOCK_TRANSFER_LIST_REQUEST,
  STOCK_TRANSFER_LIST_SUCCESS,
  STOCK_TRANSFER_LIST_FAIL,
  STOCK_TRANSFER_DETAIL_REQUEST,
  STOCK_TRANSFER_DETAIL_SUCCESS,
  STOCK_TRANSFER_DETAIL_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  UNIT_LIST_REQUEST,
  UNIT_LIST_SUCCESS,
  UNIT_LIST_FAIL,
  
} from "../constants/productConstants";

//////////////////////////// create new product///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const productOptionsReducer = (state = { options: {} }, action) => {
  switch (action.type) {
    case PRODUCT_OPTIONS_REQUEST:
      return { loading: true };
    case PRODUCT_OPTIONS_SUCCESS:
      return { loading: false, options: action.payload, success:true };
    case PRODUCT_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const productListReducer = (state = { productList: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, success:false };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, productList: action.payload, success:true };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { productInfo: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_DETAIL_SUCCESS:
      return { loading: false, productInfo: action.payload, success:true };
    case PRODUCT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createNewProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_PRODUCT_REQUEST:
      return { loading: true };
    case CREATE_NEW_PRODUCT_SUCCESS:
      return { loading: false, success:true };
    case CREATE_NEW_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateProductReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_REQUEST:
      return { loading: true };
    case EDIT_PRODUCT_SUCCESS:
      return { loading: false, success:true };
    case EDIT_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return { loading: true };
    case DELETE_PRODUCT_SUCCESS:
      return { loading: false, success:true };
    case DELETE_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//////////////////////////// product units///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
export const productUnitListReducer = (state = { units: [] }, action) => {
  switch (action.type) {
    case PRODUCT_UNIT_LIST_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_UNIT_LIST_SUCCESS:
      return { loading: false, units: action.payload, success:true };
    case PRODUCT_UNIT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const unitListReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case UNIT_LIST_REQUEST:
      return { loading: true , success:false};
    case UNIT_LIST_SUCCESS:
      return { loading: false, list: action.payload, success:true };
    case UNIT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_UNIT_REQUEST:
      return { loading: true , success:false};
    case NEW_UNIT_SUCCESS:
      return { loading: false, success:true };
    case NEW_UNIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_UNIT_REQUEST:
      return { loading: true , success:false};
    case UPDATE_UNIT_SUCCESS:
      return { loading: false, success:true };
    case UPDATE_UNIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_UNIT_REQUEST:
      return { loading: true , success:false};
    case DELETE_UNIT_SUCCESS:
      return { loading: false, success:true };
    case DELETE_UNIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



//////////////////////////// product categories///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
export const productCategoryListReducer = (state = { categoryList: [] }, action) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return { loading: false, categoryList: action.payload, success:true };
    case PRODUCT_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryListReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true , success:false};
    case CATEGORY_LIST_SUCCESS:
      return { loading: false, list: action.payload, success:true };
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_CATEGORY_REQUEST:
      return { loading: true , success:false};
    case NEW_CATEGORY_SUCCESS:
      return { loading: false, success:true };
    case NEW_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CATEGORY_REQUEST:
      return { loading: true , success:false};
    case UPDATE_CATEGORY_SUCCESS:
      return { loading: false, success:true };
    case UPDATE_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CATEGORY_REQUEST:
      return { loading: true , success:false};
    case DELETE_CATEGORY_SUCCESS:
      return { loading: false, success:true };
    case DELETE_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const productAdjustStockDetailsReducer = (state = { productInfo: {} }, action) => {
  switch (action.type) {
    case PRODUCT_ADJUST_DETAIL_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_ADJUST_DETAIL_SUCCESS:
      return { loading: false, productInfo: action.payload, success:true };
    case PRODUCT_ADJUST_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const directlyReceiveStockReducer = (state = {adjustId: null}, action) => {
  switch (action.type) {
    case DIRECTLY_RECEIVE_STOCK_REQUEST:
      return { loading: true , success:false};
    case DIRECTLY_RECEIVE_STOCK_SUCCESS:
      return { loading: false, adjustId: action.payload, success:true };
    case DIRECTLY_RECEIVE_STOCK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const stockAdjustListReducer = (state = { adjustList: [] }, action) => {
  switch (action.type) {
    case STOCK_ADJUST_LIST_REQUEST:
      return { loading: true , success:false};
    case STOCK_ADJUST_LIST_SUCCESS:
      return { loading: false, adjustList: action.payload, success:true };
    case STOCK_ADJUST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockAdjustDetailsReducer = (state = { adjustDetails: {} }, action) => {
  switch (action.type) {
    case STOCK_ADJUST_DETAILS_REQUEST:
      return { loading: true , success:false};
    case STOCK_ADJUST_DETAILS_SUCCESS:
      return { loading: false, adjustDetails: action.payload, success:true };
    case STOCK_ADJUST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bulkStockTransferReducer = (state = { }, action) => {
  switch (action.type) {
    case BULK_STOCK_TRANSFER_REQUEST:
      return { loading: true , success:false};
    case BULK_STOCK_TRANSFER_SUCCESS:
      return { loading: false, success:true };
    case BULK_STOCK_TRANSFER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockTransferListReducer = (state = { transferList: [] }, action) => {
  switch (action.type) {
    case STOCK_TRANSFER_LIST_REQUEST:
      return { loading: true , success:false};
    case STOCK_TRANSFER_LIST_SUCCESS:
      return { loading: false, transferList: action.payload, success:true };
    case STOCK_TRANSFER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockTransfeDetailsReducer = (state = { transferDetails: {} }, action) => {
  switch (action.type) {
    case STOCK_TRANSFER_DETAIL_REQUEST:
      return { loading: true , success:false};
    case STOCK_TRANSFER_DETAIL_SUCCESS:
      return { loading: false, transferDetails: action.payload, success:true };
    case STOCK_TRANSFER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const serialBatchNumbersReducer = (state = { numbers: [] }, action) => {
  switch (action.type) {
    case SERIAL_BATCH_LIST_REQUEST:
      return { loading: true , success:false};
    case SERIAL_BATCH_LIST_SUCCESS:
      return { loading: false, numbers: action.payload, success:true };
    case SERIAL_BATCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stocktakeListReducer = (state = { stocktakeList: [] }, action) => {
  switch (action.type) {
    case STOCKTAKE_LIST_REQUEST:
      return { loading: true, success:false };
    case STOCKTAKE_LIST_SUCCESS:
      return { loading: false, stocktakeList: action.payload, success:true };
    case STOCKTAKE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// product package
export const productPackageListReducer = (state = { packageList: [] }, action) => {
  switch (action.type) {
    case PRODUCT_PACKAGE_LIST_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_PACKAGE_LIST_SUCCESS:
      return { loading: false, packageList: action.payload, success:true };
    case PRODUCT_PACKAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateProductPackageReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_EDIT_PRODUCT_PACKAGE_REQUEST:
      return { loading: true , success:false};
    case NEW_EDIT_PRODUCT_PACKAGE_SUCCESS:
      return { loading: false, success:true };
    case NEW_EDIT_PRODUCT_PACKAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productPackageDetailsReducer = (state = { packageDetails: {} }, action) => {
  switch (action.type) {
    case PRODUCT_PACKAGE_DETAILS_REQUEST:
      return { loading: true , success:false};
    case PRODUCT_PACKAGE_DETAILS_SUCCESS:
      return { loading: false, packageDetails: action.payload, success:true };
    case PRODUCT_PACKAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};




