import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dashboardStyle from "./dashboardStyle.module.css";
import { HiCube } from "react-icons/hi";
import { topSaleProductsRequest } from "../../actions/dashboardActions";
import {
  ErrorMessage,
  Loader,
  MoreLink,
  VariantTag,
} from "../../components/viewComponents";
import { getTimeStamps } from "../../functions/functions";
import NodataChart from "./charts/NodataChart";
import { convtPrice } from "../../functions/functions";
import NoPermission from "../../components/NoPermission";

export default function LeaderBoard() {
  const dispatch = useDispatch();
  // access check for the actions

  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const { loading, topSales, success, error } = useSelector(
    (state) => state.topSaleBoard
  );

  useEffect(() => {
    let timeStamp = getTimeStamps("7days", userDetails.data.orgInfo.time_zone);
    if (userDetailsSuccess) {
      if (userDetails.data.permissions.includes("view_top_sale_products")) {
        dispatch(
          topSaleProductsRequest(timeStamp[0], timeStamp[1], "soldRevenue")
        );
      }
    }
  }, [dispatch, userDetails.data.orgInfo.time_zone]);

  return (
    <div className={dashboardStyle.section_wrapper_width}>
      {userDetailsSuccess &&
      userDetails.data.permissions.includes("view_top_sale_products") ? (
        <div className="w-full">
          <div className={dashboardStyle.section_titleCon}>
            <div className="w-full flex flex-row justify-between">
              <div className="flex items-center ">
                <HiCube className={dashboardStyle.section_icon} />
                <p className={dashboardStyle.section_title}>
                  Last 7 days Top Sale Products
                </p>
              </div>

              <MoreLink href={`/reporting/sale/summary-by-product`} />
            </div>
          </div>
          {loading ? (
            <Loader mess="Requesting data..." />
          ) : success ? (
            <div>
              {topSales.length > 0 ? (
                <div className={dashboardStyle.LeaderBoardWrapepr}>
                  {/* <LeaderBoard title="Today's Top Seller" data={topSales} /> */}

                  <table className={dashboardStyle.leadBoradTable}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSales.map((item, index) => (
                        <tr key={index}>
                          <td
                            className={
                              index === 0
                                ? dashboardStyle.leadBoradItemNum1
                                : index === 1
                                ? dashboardStyle.leadBoradItemNum2
                                : index === 2
                                ? dashboardStyle.leadBoradItemNum3
                                : dashboardStyle.leadBoradItemNum
                            }
                          >
                            {index + 1}
                          </td>
                          <td className={dashboardStyle.leadBoradItemCon}>
                            <div>
                              <p>{item.name}</p>{" "}
                              {item.variant ? (
                                <VariantTag name={item.variant} />
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                          <td>{convtPrice(item.incomeTotal)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <NodataChart text="There are no records of sales from the last 7 days" />
              )}
            </div>
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="w-full">
          <div className={dashboardStyle.section_titleCon}>
            <div className="w-full flex flex-row justify-between">
              <div className="flex items-center ">
                <HiCube className={dashboardStyle.section_icon} />
                <p className={dashboardStyle.section_title}>
                  Last 7 days Top Sale Products
                </p>
              </div>
            </div>
          </div>
          <NoPermission requiredPermission={"View top-selling products"} />
        </div>
      )}
    </div>
  );
}
