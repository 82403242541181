import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import settingsStyle from "../settingsStyle.module.css";
import commonStyle from "../../../style/commonStyle.module.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { roleDetailRequest } from "../../../actions/userRoleActions";
import {
  EditButtonLink,
  ScreenTitle,
} from "../../../components/editComponents";
import styledComponentsStyle from "../../../style/styledEditComponentsStyle.module.css";
import { ErrorMessage, Loader } from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";

export default function RoleView() {
  useDocumentTitle("Preview Role");
  const dispatch = useDispatch();
  const { id: roleID } = useParams();

  const { loading, roleDetail, error, success } = useSelector(
    (state) => state.roleDetail
  );

  useEffect(() => {
    dispatch(roleDetailRequest(roleID));
  }, [dispatch, roleID]);

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Role" />
      <ScreenTitle title="Preview Role" />
      <div>
        <div className={commonStyle.pageContentContainer}>
          {loading ? (
            <Loader mess="Requesting role details, please wait a moment..." />
          ) : success ? (
            <>
              <div className={settingsStyle.previewCon}>
                <div className={settingsStyle.roleName}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <AiOutlineUsergroupAdd
                      style={{
                        color: "#4683FF",
                        marginRight: "2px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <p style={{ fontSize: "1rem" }}>{roleDetail.roleName}</p>
                  </div>
                  {roleDetail.id === 1 ? (
                    <></>
                  ) : (
                    <div className={styledComponentsStyle.actionBtn}>
                      <EditButtonLink
                        path={`/settings/roles/edit/${roleDetail.id}`}
                      />
                    </div>
                  )}
                </div>

                <div className={settingsStyle.permissions}>
                {roleDetail.permissions.filter(
                    (x) => x.category === "dashboard"
                  ).length > 0 ? (
                    <>
                      <p>Dashboard</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "dashboard")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roleDetail.permissions.filter(
                    (x) => x.category === "inventory"
                  ).length > 0 ? (
                    <>
                      <p>Inventory</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "inventory")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roleDetail.permissions.filter((x) => x.category === "sale")
                    .length ? (
                    <>
                      <p>Sale</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "sale")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roleDetail.permissions.filter(
                    (x) => x.category === "purchase"
                  ).length ? (
                    <>
                      <p>Purchase</p>

                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "purchase")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleDetail.permissions.filter(
                    (x) => x.category === "customer"
                  ).length ? (
                    <>
                      <p>Customer</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "customer")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleDetail.permissions.filter(
                    (x) => x.category === "supplier"
                  ).length ? (
                    <>
                      <p>Supplier</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "supplier")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roleDetail.permissions.filter(
                    (x) => x.category === "settings"
                  ).length ? (
                    <>
                      <p>Settings</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "settings")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roleDetail.permissions.filter(
                    (x) => x.category === "reporting"
                  ).length ? (
                    <>
                      <p>Reporting</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "reporting")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {roleDetail.id === 1 &&
                  roleDetail.permissions.filter((x) => x.category === "billing")
                    .length ? (
                    <>
                      <p>Billing</p>
                      <div className={settingsStyle.optionCon}>
                        {roleDetail.permissions
                          .filter((x) => x.category === "billing")
                          .map((item) => (
                            <div
                              key={item.id}
                              className={
                                item.permission
                                  ? settingsStyle.optionTrue
                                  : settingsStyle.optionFalse
                              }
                            >
                              {item.permission ? (
                                <AiOutlineCheckCircle
                                  className={settingsStyle.hasPermission}
                                />
                              ) : (
                                <AiOutlineCloseCircle
                                  className={settingsStyle.noPermission}
                                />
                              )}
                              <span className={settingsStyle.optionName}>
                                {item.title}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <ErrorMessage mess={error} />
          )}
        </div>
      </div>
    </div>
  );
}
