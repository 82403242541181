import React, { useState } from "react";
import {
  SimpleBlueButton,
  RatioButton,
} from "../../../components/editComponents";
import { StyledInput } from "../../../components/inputFields";
import { TextErrorMessage } from "../../../components/viewComponents";
import settingsStyle from "../settingsStyle.module.css";

export default function RoleDetailForm({
  title,
  btnName,
  onSubmit,
  type,
  roleDetails,
}) {
  //initialise ratio values
  //initialise ratio values
  const roleId = roleDetails.id ? roleDetails.id : "";
  const [roleName, setRoleName] = useState(roleDetails.roleName);
  const [permissionsValues, setPermissionsValues] = useState(
    roleDetails.permissions
  );

  //define the error message
  const [formErrors, setFormErrors] = useState({});

  ///////////////////////////////////Handle Change/////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const handleRoleNameChange = (e) => {
    const newValue = e.target.value;
    setRoleName(newValue);
  };

  const handlePermissionChange = (e) => {
    // get index of changed item
    const index = permissionsValues.findIndex((x) => x.name === e.target.name);
    // copy current state
    let newState = [...permissionsValues];
    newState[index].permission = e.target.checked;
    setPermissionsValues(newState);
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  const checkValidationSave = () => {
    let submitedValeus = {
      id: roleId,
      roleName: roleName,
      permissions: permissionsValues,
    };
    const hasErrors = validate(submitedValeus);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSubmit(submitedValeus);
    } else {
      setFormErrors(hasErrors);
    }
  };

  // check input validation
  const validate = (values) => {
    const errors = {};

    if (!values.roleName) {
      errors.roleName = "Role name is required!";
    }

    if (!values.permissions.some((obj) => obj.permission === true)) {
      errors.permission =
        "Please select at least one permisson for this role !";
    }
    return errors;
  };

  return (
    <div>
      <div className={settingsStyle.roleNameEdit}>
        <StyledInput
          label="Role Name"
          type="text"
          name="roleName"
          value={roleName}
          onChange={(roleName) =>
            handleRoleNameChange({
              target: { value: roleName, name: "roleName" },
            })
          }
          error={formErrors.roleName}
        />
      </div>

      <div className={settingsStyle.permissions}>
        <p
          style={{ marginBottom: "12px", color: "#757474", fontSize: "0.7rem" }}
        >
          Select permissons to this role
        </p>
        {formErrors.permission ? (
          <TextErrorMessage mess={formErrors.permission} />
        ) : (
          <></>
        )}

        {/* dashboard permissions */}
        {permissionsValues.filter((x) => x.category === "dashboard").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Dashboard</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "dashboard")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* inventory permissions */}
        {permissionsValues.filter((x) => x.category === "inventory").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Inventory</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "inventory")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* sale permissions */}
        {permissionsValues.filter((x) => x.category === "sale").length > 0 ? (
          <>
            <p className={settingsStyle.categoryName}>Sale</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "sale")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* purchase permissions */}
        {permissionsValues.filter((x) => x.category === "purchase").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Purchase</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "purchase")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}
        {/* customer permissions */}
        {permissionsValues.filter((x) => x.category === "customer").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Customer</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "customer")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* supplier permissions */}
        {permissionsValues.filter((x) => x.category === "supplier").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Supplier</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "supplier")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* settings permissions */}
        {permissionsValues.filter((x) => x.category === "settings").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Settings</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "settings")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}
              {/* settings permissions */}
              {permissionsValues.filter((x) => x.category === "reporting").length >
        0 ? (
          <>
            <p className={settingsStyle.categoryName}>Reporting</p>
            <div className={settingsStyle.optionCon}>
              {permissionsValues
                .filter((x) => x.category === "reporting")
                .map((item) => (
                  <div key={item.id} className={settingsStyle.optionEdit}>
                    <RatioButton
                      id={item.id}
                      label={item.title}
                      name={item.name}
                      isOn={item.permission}
                      onChange={(e) => handlePermissionChange(e)}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full mt-6">
        <SimpleBlueButton name={btnName} action={checkValidationSave} />
      </div>
    </div>
  );
}
