import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  productDetailsRequest,
  serialBatchNumbersRequest,
} from "../../actions/productActions";
import Breadcrumbs from "../../components/Breadcrumbs";
import XeroLink from "../../components/XeroLink";
import { ScreenTitle } from "../../components/editComponents";
import {
  ErrorMessage,
  Loader,
  ProductImage,
  SubTitle,
  TextErrorMessage,
  TipsText,
  VariantSkuTag,
  VariantTag,
  ViewLabel,
  ViewText,
  XeroSmallLogo,
} from "../../components/viewComponents";
import { productTypeOptions } from "../../constantsData/productType";
import { convtPrice, convtQuantity } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ProductActions from "./ProductActions";
import ProductInfoTab from "./ProductInfoTab";
import SerialBatchNumbers from "./SerialBatchNumbers";
import inventoryStyle from "./inventoryStyle.module.css";

export default function PreviewProductScreen() {
  useDocumentTitle("Preview Product");
  const { id: productId } = useParams();

  const dispatch = useDispatch();

  const [tabName, setTabName] = useState("Basic Details");

  useEffect(() => {
    dispatch(productDetailsRequest(productId, "preview"));
    dispatch(serialBatchNumbersRequest(productId));
  }, [dispatch, productId]);

  const { loading, productInfo, error, success } = useSelector(
    (state) => state.productDetails
  );
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const {
    loading: numbersLoading,
    numbers,
    error: numebrsError,
    success: numbersSuccess,
  } = useSelector((state) => state.serialBatchNumbers);

  const changeTabRequest = (tab) => {
    setTabName(tab);
  };

  const updateDetails = () => {};

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Preview Product" />
      <ScreenTitle title="Preview Product" />

      {loading ? (
        <Loader mess="Requesting product details, please wait a moment..." />
      ) : success ? (
        <div>
          <div style={{ margin: "20px" }}>
            <h1 className="text-xl font-medium">
              <VariantSkuTag name={productInfo.basicInfo.variantSku} />{" "}
              {productInfo.basicInfo.name}
            </h1>
            <div
              style={{
                width: "100%",
                paddingTop: "32px",
                paddingBottom: "16px",
              }}
            >
              <ProductActions
                product={{
                  id: productId,
                  name: productInfo.basicInfo.name,
                  variantSku: productInfo.basicInfo.variantSku,
                  status: productInfo.basicInfo.status,
                  locations: productInfo.basicInfo.location,
                }}
                showPreview={false}
                reloadList={updateDetails}
              />
            </div>
          </div>

          <div className={commonStyle.tabsContainer}>
            <ProductInfoTab
              tabName={tabName}
              serialized={productInfo.basicInfo.serialized}
              batchTracked={productInfo.basicInfo.batchTracked}
              changeTab={changeTabRequest}
            />
            <div className={commonStyle.settingsContent}>
              {tabName === "Basic Details" ? (
                <div>
                  <div className={inventoryStyle.stockLevelCon}>
                    <SubTitle name={"Product Basic Details"} />
                    <div className={commonStyle.previewWrapper}>
                      <div className={commonStyle.col1}>
                        <ViewText
                          title="Product Code"
                          value={productInfo.basicInfo.variantSku}
                        />

                        <ViewText
                          title="Product Name"
                          value={productInfo.basicInfo.name}
                        />
                        <ViewText
                          title="Category"
                          value={
                            productInfo.basicInfo.categoryName
                              ? productInfo.basicInfo.categoryName
                              : "null"
                          }
                        />
                        <ViewText
                          title="Default Supplier"
                          value={
                            productInfo.basicInfo.supplierName
                              ? productInfo.basicInfo.supplierName
                              : "null"
                          }
                        />

                        <ViewText
                          title="Description"
                          value={
                            productInfo.basicInfo.description
                              ? productInfo.basicInfo.description
                              : "null"
                          }
                        />
                      </div>

                      <div className={commonStyle.col2}>
                        <ViewText
                          title="Product Type"
                          value={
                            productTypeOptions.filter(
                              (x) => x.value === productInfo.basicInfo.type
                            )[0].label
                          }
                        />
                        <ViewText
                          title="Unit"
                          value={
                            productInfo.basicInfo.unitName
                              ? productInfo.basicInfo.unitName
                              : "null"
                          }
                        />
                        <ViewText
                          title="Serialized? "
                          value={
                            productInfo.basicInfo.serialized ? "Yes" : "No"
                          }
                        />
                        <ViewText
                          title="Batch Tracked? "
                          value={
                            productInfo.basicInfo.batchTracked ? "Yes" : "No"
                          }
                        />
                        {userDetailsSuccess &&
                        userDetails.data.orgInfo.xeroConnected &&
                        userDetails.data.orgInfo.xeroConfigured ? (
                          <div>
                            <ViewLabel title={"Manage Inventory in Xero"} />
                            {productInfo.basicInfo.managed ? (
                              <div className="flex flex-row items-center">
                                <XeroSmallLogo />
                                <p>Xero Track Inventory Item</p>
                              </div>
                            ) : (
                              <p> Xero Non-Track Inventory Item</p>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className={commonStyle.col3}>
                        <ProductImage
                          alt="product preview"
                          src={productInfo.basicInfo.image}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <SubTitle name={"Product Transaction Details"} />
                      <div className="bg-lightbg px-4 rounded mt-4">
                        <div className="w-full grid grid-cols-4 gap-4">
                          <ViewText
                            title="Barcode"
                            value={productInfo.basicInfo.barcode}
                          />
                          <ViewText
                            title="Sale Price"
                            value={convtPrice(productInfo.basicInfo.salePrice)}
                          />
                          {userDetailsSuccess &&
                            userDetails.data.permissions.includes(
                              "view_product_cost"
                            ) && (
                              <ViewText
                                title="Latest Cost"
                                value={convtPrice(
                                  productInfo.basicInfo.latestCost
                                )}
                              />
                          )}

                          <ViewText
                            title="Re-order Level"
                            value={productInfo.basicInfo.reorderLevel}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {tabName === "Stock Level" ? (
                <div className={inventoryStyle.basicPrice}>
                  {productInfo.basicInfo.locations.length > 0 ? (
                    productInfo.basicInfo.locations.map((loc, index1) => (
                      <div key={index1}>
                        <div className={inventoryStyle.locationTitle}>
                          <MdLocationPin />
                          <span>{loc.value}</span>
                        </div>
                        <table className={inventoryStyle.dynTable}>
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Barcode</th>
                              <th>Re-order Level</th>
                              <th>Sale Price</th>
                              {userDetailsSuccess &&
                              userDetails.data.permissions.includes(
                                "view_product_cost"
                              ) ? (
                                <th>Latest Cost</th>
                              ) : (
                               <></>
                              )}
                              <th>Stock on Hand</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productInfo.variations.map((item, index3) =>
                              Number(item.locationId) !== Number(loc.id) ? (
                                ""
                              ) : (
                                <tr key={index3}>
                                  <td style={{ width: "15%" }}>
                                    <VariantSkuTag name={item.variantSku} />
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {item.barcode}
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {item.reorderLevel}
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    {convtPrice(item.salePrice)}
                                  </td>
                                  {userDetailsSuccess &&
                                  userDetails.data.permissions.includes(
                                    "view_product_cost"
                                  ) ? (
                                    <td style={{ width: "15%" }}>
                                      {convtPrice(item.latestCost)}
                                    </td>
                                  ) : (
                                   <></>
                                  )}

                                  <td style={{ width: "15%" }}>
                                    {item.stockLevel}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <TipsText text="There are no stock on hand."></TipsText>
                  )}
                </div>
              ) : (
                <></>
              )}

              {tabName === "Serial Numbers" ? (
                <div className={inventoryStyle.basicPrice}>
                  {numbersLoading ? (
                    <Loader mess="Requesting data, please wait a moment..." />
                  ) : numbersSuccess ? (
                    <div>
                      {parseFloat(
                        productInfo.variations
                          .reduce((accumulator, currentValue) => {
                            return (
                              accumulator +
                              Number(
                                currentValue.stockLevel
                                  ? currentValue.stockLevel
                                  : 0
                              )
                            );
                          }, 0)
                          .toFixed(2)
                      ) !==
                      parseFloat(
                        numbers.numbers
                          .reduce((accumulator, currentValue) => {
                            return (
                              accumulator +
                              Number(currentValue.quantity_in_stock)
                            );
                          }, 0)
                          .toFixed(2)
                      ) ? (
                        <div>
                          <TextErrorMessage mess="The quantity of serial numbers is not equal to the stock on hand" />
                        </div>
                      ) : (
                        <></>
                      )}

                      {numbers.numbers.length > 0 ? (
                        <SerialBatchNumbers
                          locations={numbers.locations}
                          numbers={numbers.numbers}
                          hasProject={numbers.hasProject}
                        />
                      ) : (
                        <p>There are no serial numbers for this product.</p>
                      )}
                    </div>
                  ) : (
                    <ErrorMessage mess={numebrsError} />
                  )}
                </div>
              ) : (
                <></>
              )}

              {tabName === "Batch Numbers" ? (
                <div className={inventoryStyle.basicPrice}>
                  {numbersLoading ? (
                    <Loader mess="Requesting data, please wait a moment..." />
                  ) : numbersSuccess ? (
                    <div>
                      {parseFloat(
                        productInfo.variations
                          .reduce((accumulator, currentValue) => {
                            return (
                              accumulator +
                              Number(
                                currentValue.stockLevel
                                  ? currentValue.stockLevel
                                  : 0
                              )
                            );
                          }, 0)
                          .toFixed(2)
                      ) !==
                      parseFloat(
                        numbers.numbers
                          .reduce((accumulator, currentValue) => {
                            return (
                              accumulator +
                              Number(currentValue.quantity_in_stock)
                            );
                          }, 0)
                          .toFixed(2)
                      ) ? (
                        <div>
                          <TextErrorMessage mess="The quantity of batch numbers is not equal to the stock on hand" />
                        </div>
                      ) : (
                        <></>
                      )}

                      {numbers.numbers.length > 0 ? (
                        <SerialBatchNumbers
                          locations={numbers.locations}
                          numbers={numbers.numbers}
                          hasProject={numbers.hasProject}
                        />
                      ) : (
                        <p>There are no batch numbers for this product.</p>
                      )}
                    </div>
                  ) : (
                    <ErrorMessage mess={numebrsError} />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ErrorMessage mess={error} />
      )}
    </div>
  );
}
