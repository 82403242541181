import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  ScreenTitle,
} from "../../../components/editComponents";
import { convtPrice } from "../../../functions/functions";
import commonStyle from "../../../style/commonStyle.module.css";

import { purchaseOrderDetailsRequest, receiveStockRequest } from "../../../actions/purchaseOrderActions";
import {
  ErrorMessage,
  Loader,
  RecievStockDialog
} from "../../../components/viewComponents";
import useDocumentTitle from "../../../useDocumentTitle";
import ReceivePurchaseOrderForm from "./ReceivePurchaseOrderForm";

export default function ReceivePurchaseOrderScreen() {
  useDocumentTitle("Receive Purchase Order")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: poid } = useParams();

  const { loading, orderInfo, error, success } = useSelector(
    (state) => state.purchaseOrderDetails
  );


  const [receivedStockValues, setReceivedStockValues] = useState();
  // define if is partially received
  const [partiallyReceived, setPartiallyReceived] = useState(false);
  const [proceedDialogOpen, setProceedDialogOpen] = useState(false);

  const {
    loading: proceedLoading,
    error: proceedError,
    success: proceedSuccess,
  } = useSelector((state) => state.receiveStock);

  const orderFiles = () => {
    let copy = [...orderInfo.orderFiles];
    let data = [];
    copy.forEach((x)=>{
      data.push({
        fileName: x.fileName,
        uri: x.uri,
        loading: false,
        error: ""
      })
    })
    return data;
  };


  useEffect(() => {
    dispatch(purchaseOrderDetailsRequest(poid, "receiveStock"));
  }, [dispatch, poid]);

  /////////////////////////////////////////////////////////////////////////////

  const openProceedDialog = (values) => {
    setPartiallyReceived(false);

    let receivedItems = [];
    let invoiceValue;

    values.receivedStock.forEach((item) => {
      if(item.receivedQuantity < item.quantity ) {
      setPartiallyReceived(true);
      }

      let batchNumbersCollected = [];
      if (item.batchTracked) {
        item.batchNumbers.forEach((ele) => {
          batchNumbersCollected.push({
            batchNumber: ele.batchNumber,
            expireDate: ele.expireDate
              ? Math.floor(ele.expireDate.getTime() / 1000)
              : "",
            quantity: ele.quantity,
          });
        });
      } else {
        batchNumbersCollected = item.batchNumbers;
      }

      receivedItems.push({
        famid: item.famid,
        id: item.id,
        variantCode: item.variantCode,
        quantity: item.quantity,
        receivedQuantity: item.receivedQuantity,
        serialNumbers: item.serialNumbers,
        batchNumbers: batchNumbersCollected
      })
    })

    invoiceValue = {
      invoiceNo: values.invoice.invoiceNo,
      invoiceDate:values.invoice.invoiceDate ? Math.floor(
        values.invoice.invoiceDate.getTime() / 1000
      ) : "",
      invoiceDue: values.invoice.invoiceDue ? Math.floor(
        values.invoice.invoiceDue.getTime() / 1000
      ) : "",
    }

    values.receivedStock = receivedItems;
    values.invoice = invoiceValue;

    setReceivedStockValues(values);
    setProceedDialogOpen(true);
  }

  const receivedStockRequest = (isSplitOrder, syncXero) => {
    
    let putValues = {...receivedStockValues};

    let orderTotalExcltax;
    let orderTotalIncltax;
    let orderTax;

    if (orderInfo.basicInfo.taxIncluded) {
      orderTotalExcltax =
      convtPrice((Number(putValues.orderSummary.productsTotal) +
        Number(putValues.orderSummary.additionalCostTotal)) /
      (1 + orderInfo.basicInfo.taxrate / 100));

      orderTotalIncltax =
      convtPrice(Number(putValues.orderSummary.productsTotal) +
        Number(putValues.orderSummary.additionalCostTotal));
  
      orderTax =
      convtPrice(((Number(putValues.orderSummary.productsTotal) +
          Number(putValues.orderSummary.additionalCostTotal)) /
          (1 + orderInfo.basicInfo.taxrate / 100)) *
        (orderInfo.basicInfo.taxrate / 100));
    } else {
      orderTotalExcltax =
      convtPrice(Number(putValues.orderSummary.productsTotal) +
        Number(putValues.orderSummary.additionalCostTotal));
        orderTotalIncltax =
        convtPrice((Number(putValues.orderSummary.productsTotal) +
          Number(putValues.orderSummary.additionalCostTotal)) *
        (1 + orderInfo.basicInfo.taxrate / 100));
        orderTax =
        convtPrice((Number(putValues.orderSummary.productsTotal) +
          Number(putValues.orderSummary.additionalCostTotal)) *
        (orderInfo.basicInfo.taxrate / 100));
    }

    putValues.costs.forEach((c) => {
      let addCostExclTax;
      if (orderInfo.basicInfo.taxIncluded) {
        addCostExclTax = convtPrice(c.cost / (1 + orderInfo.basicInfo.taxrate / 100));

      } else{
        addCostExclTax = c.cost;
      }
      c["costExclTax"] = addCostExclTax;
    });

    putValues.orderTotalExcltax = orderTotalExcltax;
    putValues.orderTotalIncltax = orderTotalIncltax;
    putValues.orderTax = orderTax;

    putValues.isTaxIncluded = orderInfo.basicInfo.taxIncluded;
    putValues.isPartiallyReceived = partiallyReceived;
    putValues.isSplitOrder = isSplitOrder;
    putValues.syncXero = syncXero;
    putValues.taxrate = orderInfo.basicInfo.taxrate;
    putValues.splitOrderNo = orderInfo.basicInfo.orderNumber + "/01";
 
    dispatch(receiveStockRequest(putValues, poid))

  }

  const closeDialogWithSuccessAction = (pageName) => {
    setProceedDialogOpen(false)
    if (pageName === "list"){
      navigate(`/purchase/order-list`, {
        replace: true,
        from: location,
      });
    }else{
      navigate(`/purchase/order/preview/${poid}`, {
        replace: true,
        from: location,
      });
    }

  }

  return (
    <div className={commonStyle.pageContainer}>
      <RecievStockDialog 
       title="Proceed Receive Stock"
       partiallyReceived={partiallyReceived}
       successMessage="Stock received successfully."
       isDialogOpen={proceedDialogOpen}
       closeDialog={() => setProceedDialogOpen(false)}
       closeDialogWithSuccess={closeDialogWithSuccessAction}
       loading={proceedLoading}
       loadingMessage="Processing request..."
       success={proceedSuccess}
       error={proceedError}
       confirmAction={receivedStockRequest}
       oriSendXero={false}
      />

      <Breadcrumbs screenName="Receive Stock" />
      <ScreenTitle title="Receive Stock" />
      <div className={commonStyle.pageContentContainer}>
        {loading ? (
          <Loader mess="Requesting purchase order details, please wait a moment..." />
        ) : success ? (
          <div>
            <ReceivePurchaseOrderForm
              notes={orderInfo.basicInfo.notes}
              orderBasicInfo={orderInfo.basicInfo}
              orderItems={orderInfo.orderItems}
              initialCost={orderInfo.orderCosts}
              initialFiles={orderFiles}
              initialSummary={orderInfo.orderSummary}
              submitReceiveStock = {openProceedDialog}
            />
          </div>
        ) : (
          <ErrorMessage mess={error} />
        )}
      </div>
    </div>
  );
}