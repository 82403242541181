import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const customTableHeaderSettingsRequest = async (tableName, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/settings/system-settings/data-table/${tableName}`, config)
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCustomTableHeaderSettingsRequest = async (data, config) => {
  try {
    const response = await axios.post(SERVER_URL + `/settings/system-settings/data-table/update`, data, config)
    return response;
  } catch (error) {
    throw error;
  }
};


