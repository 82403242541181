import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import { saleOrderReportOptionsRequest } from "../../actions/reportingActions";
import { saleSummaryReportRequest } from "../../api/reportingServices";
import Breadcrumbs from "../../components/Breadcrumbs";
import Pagination from "../../components/Pagination";
import {
  LoadingButton,
  ScreenTitle,
  SimpleBlueButton,
  SimpleOutlineButton,
} from "../../components/editComponents";
import { DateInput, StyledSelect } from "../../components/inputFields";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  Tag,
  TextErrorMessage,
  ViewDateText,
} from "../../components/viewComponents";
import { formatDate } from "../../functions/functions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";
import { Text } from "@react-pdf/renderer";

export default function SaleSummaryReportScreen() {
  useDocumentTitle("Sale Summary Report");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.userDetail
  );
  const { options, success, loading, error } = useSelector(
    (state) => state.saleOrderReportOptions
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  // pagination
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [orders, setOrders] = useState({});
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  // filter and search  function
  const [filterLocation, setFilterLocation] = useState("");
  const [filterSalePerson, setFilterSalePerson] = useState("");
  const [filterCustomer, setFilterCustomer] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterError, setFilterError] = useState("");

  const applyFilter = () => {
    getOrders("filter");
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  const downloadFile = async () => {
    let reportData = [];
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";

    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await saleSummaryReportRequest(
        0,
        99999999,
        filterLocation ? filterLocation.id : "",
        filterSalePerson ? filterSalePerson.id : "",
        filterCustomer ? filterCustomer.id : "",
        config,
        startTimestamp,
        endTimestamp
      );

      if (responseData.success) {
        setFilterError("");
        reportData = responseData.data.sale_orders;
        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }

    //get csv file
    let csvWriter = new CsvWriter([
      "Issue Date",
      "Order Number",
      "Customer",
      "Location",
      "Total Price (Inc.Tax)",
      "Status",
      "Salesperson",
    ]);

    for (let line of reportData) {
      csvWriter.addLine([
        formatDate(
          new Date(line.issueDate * 1000),
          detailsSuccess ? userDetails.data.orgInfo.date_format : "DD/MM/YYYY",
          detailsSuccess
            ? userDetails.data.orgInfo.time_zone
            : "Pacific/Auckland"
        ),
        line.orderNumber,
        line.customerName,
        line.locationName,
        line.totalPriceInclTax,
        line.status,
        line.creatorFirstName + " " + line.creatorLastName,
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "sale_summary.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getOrders("initial");
  }, [pageSize, pageNum]);

  useEffect(() => {
    if (!options) {
      dispatch(saleOrderReportOptionsRequest());
    }
  }, [options]);

  const getOrders = async (type) => {
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";
    if (startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await saleSummaryReportRequest(
        pageNum,
        pageSize,
        filterLocation ? filterLocation.id : "",
        filterSalePerson ? filterSalePerson.id : "",
        filterCustomer ? filterCustomer.id : "",
        config,
        startTimestamp,
        endTimestamp
      );

      if (responseData.success) {
        setOrders(responseData.data);
        setFilterError("");
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      }else if(responseData.error === -3){
        dispatch(userlogout());
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Sale Summary Report" />

      <ScreenTitle
        title="Sale Summary Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => setFilterStartDate(startDate)}
              />
              <StyledSelect
                label="Location"
                selectOptions={success ? options.locations : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
              />
              <StyledSelect
                label="Salesperson"
                selectOptions={success ? options.salePersons : []}
                value={filterSalePerson}
                onChange={(salePerson) => setFilterSalePerson(salePerson)}
              />
            </div>
            <div style={{ width: "45%" }}>
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => setFilterEndDate(endDate)}
              />
              <StyledSelect
                label="Customer"
                selectOptions={success ? options.customers : []}
                value={filterCustomer}
                onChange={(customer) => setFilterCustomer(customer)}
              />
            </div>
          </div>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "-20px",
            }}
          >
            <SimpleOutlineButton name="Apply Filter" action={applyFilter} />
          </div>
        </div>

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success ? (
          <div className={commonStyle.longDataTable}>
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Issue Date</th>
                  <th>Order Number</th>
                  <th>Customer</th>
                  <th>Location</th>
                  <th>Total Price</th>
                  <th>Salesperson</th>
                </tr>
              </thead>
              <tbody>
                {orders.sale_orders.length > 0 ? (
                  orders.sale_orders.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>
                          {" "}
                          <ViewDateText value={item.issueDate} />
                        </td>
                        <td>{item.orderNumber}</td>
                        <td>{item.customerName}</td>
                        <td><Tag color="gray" name={item.locationName} /></td>
                        <td><DollarTag />{item.totalPriceInclTax}</td>
                        <td>
                          {item.creatorFirstName} {item.creatorLastName}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data in selected period.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {orders.sale_orders.length > 0 ? (
              <Pagination
                totalPageNum={orders.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess="Requesting report data failed, please try again later." />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
