import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../actions/authAction.js";
import {
    getLocationOptionsRequest,
    productsMovementReportRequest,
} from "../../api/reportingServices.js";
import Breadcrumbs from "../../components/Breadcrumbs.js";
import Pagination from "../../components/Pagination.js";
import {
    LoadingButton,
    ScreenTitle,
    SimpleBlueButton
} from "../../components/editComponents.js";
import { DateInput, Search, StyledSelect } from "../../components/inputFields.js";
import {
    ErrorMessage,
    Loader,
    Tag,
    TextErrorMessage,
    VariantSkuTag,
} from "../../components/viewComponents.js";
import { K_DATE_RANGES } from "../../constantsData/dateRanges.js";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle.js";
import { CsvWriter } from "./CsvWriter.js";

export default function ProductsMovementReportScreen() {
  useDocumentTitle("Products Movement Report");
  const dispatch = useDispatch();
  const optionsRef = useRef(true);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: userDetailsSuccess } = useSelector(
    (state) => state.userDetail
  );

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const sixtyDaysAgo = new Date();
  sixtyDaysAgo.setDate(today.getDate() - 60);
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(today.getDate() - 90);

  const pageSize = 40;
  const [pageNum, setPageNum] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState(K_DATE_RANGES[0]);
  const [filterStartDate, setFilterStartDate] = useState(thirtyDaysAgo);
  const [filterEndDate, setFilterEndDate] = useState(today);
  const [filterLocation, setFilterLocation] = useState();
  const [filterError, setFilterError] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);

  const [reportData, setReportData] = useState([]);
  const [optionStatus, setOptionStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [keyWords, setKeywords] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const handleDateRangeChange = (dateRange) => {
    setFilterDateRange(dateRange);
    let date_value = dateRange? dateRange.value : "";
    if (Number(date_value) === 30) {
      setFilterStartDate(thirtyDaysAgo);
      setFilterEndDate(today);
    } else if (Number(date_value) === 60) {
      setFilterStartDate(sixtyDaysAgo);
      setFilterEndDate(today);
    } else if (Number(date_value) === 90) {
      setFilterStartDate(ninetyDaysAgo);
      setFilterEndDate(today);
    } else if (Number(date_value) === 90) {
      setFilterStartDate(ninetyDaysAgo);
      setFilterEndDate(today);
    }
  };

  const handleStartDateChange = (date) => {
    setFilterStartDate(date);
    let find_custom_date = K_DATE_RANGES.find((x)=> x.value === "custom");
    if(find_custom_date){
      setFilterDateRange(find_custom_date);
    }
  }
  const handleEndDateChange = (date) => {
    setFilterEndDate(date);
    let find_custom_date = K_DATE_RANGES.find((x)=> x.value === "custom");
    if(find_custom_date){
      setFilterDateRange(find_custom_date);
    }
  }

  const handleSearchChange = (value) => {
    setKeywords(value);
    setPageNum(0);
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        setOptionStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getLocationOptionsRequest(config);
        if (responseData.success) {
          optionsRef.current = false;
          let org_locations = responseData.data;
          let option_locations = [];

          org_locations.forEach((element) => {
            option_locations.push({
              id: element.id,
              label: element.name,
              value: element.name,
              is_default: element.is_default,
            });
          });

          if (option_locations.length) {
            setLocationOptions(option_locations);
            let find_default_location = option_locations.find(
              (x) => x.is_default === 1
            );
            if (find_default_location) {
              setFilterLocation(find_default_location);
            } else {
              setFilterLocation(option_locations[0]);
            }
          }
          setOptionStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else if (responseData.error === -3) {
          dispatch(userlogout());
        }
      } catch (error) {
        setOptionStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (optionsRef) {
      getOptions();
    }
  }, [optionsRef]);

  /////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, [pageNum, filterLocation, keyWords, filterStartDate, filterEndDate]);

  const getProducts = async () => {
    if (!filterLocation) {
      setFilterError("Please select a location.");
      return;
    }

    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";
    if (endTimestamp && startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }

    try {
      setRequestStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productsMovementReportRequest(
        keyWords,
        pageNum,
        pageSize,
        filterLocation.id,
        startTimestamp,
        endTimestamp,
        config
      );
      if (responseData.success) {
        setReportData(responseData.data);
        setFilterError("");
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else if (responseData.error === -3) {
        dispatch(userlogout());
      } else {
        throw responseData.message ? responseData.message : "Error";
      }
    } catch (error) {
      setRequestStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const applyFilter = (e) => {
    e.preventDefault();
    getProducts();
  };

    /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  const downloadFile = async () => {
    // get full stocktake list
    if (!filterLocation) {
      setFilterError("Please select a location.");
      return;
    }
    let startTimestamp = filterStartDate
    ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
    : "";
  let endTimestamp = filterEndDate
    ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
    : "";
  if (endTimestamp && startTimestamp > endTimestamp) {
    setFilterError("The start date is greater than end date.");
    return;
  }

    let csv_data = [];
    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await productsMovementReportRequest(
        keyWords,
        0,
        99999999999,
        filterLocation.id,
        startTimestamp,
        endTimestamp,
        config
      );

      if (responseData.success) {
        csv_data = responseData.data.products;
        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else if (responseData.error === -3) {
        dispatch(userlogout());
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
    //get csv file
    let csvWriter = new CsvWriter([
      "Location",
      "Code",
      "ProductName",
      "Ordered Qty",
      "Sold Qty",
      "Adjust Qty(Received)",
      "Adjust Qty(Deducted)",
      "Transferred In Qty",
      "Transferred Out Qty",
      "TotalStockOnHand",
    ]);
    for (let line of csv_data) {
      csvWriter.addLine([
        line.locationName,
        line.variantSku,
        line.productName,
        Math.abs(line.orderedQty) > 0 ? line.orderedQty : "",
        Math.abs(line.soldQty) > 0 ? line.soldQty : "",
        Math.abs(line.adjustReceiveQty) > 0 ? line.adjustReceiveQty : "",
        Math.abs(line.adjustDeductQty) > 0 ? line.adjustDeductQty : "",
        Math.abs(line.transferOutQty) > 0 ? line.transferOutQty : "",
        Math.abs(line.transferInQty) > 0 ? line.transferInQty : "",
        line.stockLevel,
      ]);
    }
    const csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const startDate = new Date(filterStartDate);
    const endDate = new Date(filterEndDate);

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `products_movement_report_${startDate.toLocaleDateString()}_to_${endDate.toLocaleDateString()}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <SimpleBlueButton name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <Breadcrumbs screenName="Products Movement Report" />

      <ScreenTitle
        title="Products Movement Report"
        buttonComponent={<DownloadBtn />}
      />
      <div className={commonStyle.pageContentContainer}>
        <div>
          <div className="w-full flex flex-row items-center">
            <div className="w-[30%] mr-6">
              <StyledSelect
                label="Location"
                selectOptions={optionStatus.success ? locationOptions : []}
                value={filterLocation}
                onChange={(location) => setFilterLocation(location)}
                clearable={false}
              />
            </div>

            <div className="w-[20%]">
              <StyledSelect
                label="Filter by date range"
                selectOptions={K_DATE_RANGES}
                value={filterDateRange}
                clearable={false}
                onChange={(dateRange) => handleDateRangeChange(dateRange)}
              />
            </div>
            <div className="mt-8 mx-2">OR</div>
            <div className="w-[15%] mr-6">
              <DateInput
                label="Start Date"
                value={filterStartDate}
                disableFuturedate
                onChange={(startDate) => handleStartDateChange(startDate)}
              />
            </div>
            <div className="w-[15%] mr-6">
              <DateInput
                label="End Date"
                value={filterEndDate}
                disableFuturedate
                onChange={(endDate) => handleEndDateChange(endDate)}
              />
            </div>
            <div className="w-[10%] flex flex-row items-end mt-9">
              <button
                onClick={applyFilter}
                className="h-[38px] border border-solid border-brandColor hover:bg-brandColor hover:text-white rounded px-2 hover:cursor-pointer"
              >
                <span>{"Apply Filter"}</span>
              </button>

              {/* <SimpleOutlineButton name="Apply Filter" action={applyFilter} /> */}
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-row items-end justify-between">
            <div className="w-1/2">
            <Search
              placeholdertext="Type product name / code to search..."
              initialQuery={keyWords}
              onChange={handleSearchChange}
            />
            </div>
              <p>{`Showing results from ${filterDateRange ? filterDateRange.label : ""}`}</p>
          </div>
        

        {requestStatus.loading ? (
          <Loader mess="Requesting report, please wait a moment..." />
        ) : requestStatus.success && userDetailsSuccess ? (
          <div>
          

          <div className={commonStyle.longDataTable}>
            {filterError ? (
              <div style={{ marginTop: "20px" }}>
                <TextErrorMessage mess={filterError} />
              </div>
            ) : (
              <></>
            )}
            {/* hold the product list table */}
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Ordered Qty</th>
                  <th>Sold Qty</th>
                  <th>Adjust Qty(Received)</th>
                  <th>Adjust Qty(Deducted)</th>
                  <th>Transferred In Qty</th>
                  <th>Transferred Out Qty</th>
                  <th>Stock on Hand</th>
                </tr>
              </thead>
              <tbody>
                {reportData.products.length > 0 ? (
                  reportData.products.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <Tag name={item.locationName} color="gray" />
                        </td>
                        <td>
                          <VariantSkuTag name={item.variantSku} />
                        </td>
                        <td>{item.productName}</td>

                        <td>
                          {Math.abs(item.orderedQty) > 0 ? item.orderedQty : ""}
                        </td>
                        <td>
                          {Math.abs(item.soldQty) > 0 ? item.soldQty : ""}
                        </td>
                        <td>
                          {Math.abs(item.adjustReceiveQty) > 0
                            ? item.adjustReceiveQty
                            : ""}
                        </td>
                        <td>
                          {Math.abs(item.adjustDeductQty) > 0
                            ? `${item.adjustDeductQty}`
                            : ""}
                        </td>
                        <td>
                          {Math.abs(item.transferInQty) > 0
                            ? item.transferInQty
                            : ""}
                        </td>
                        <td>
                          {Math.abs(item.transferOutQty) > 0
                            ? `${item.transferOutQty}`
                            : ""}
                        </td>
                        <td>{item.stockLevel}</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>There are no data available.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {reportData.products.length > 0 ? (
              <Pagination
                totalPageNum={reportData.total_pages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
