import React, { useEffect } from "react";
import { FcSalesPerformance } from "react-icons/fc";
import { HiCube } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  createdPurchaseOrderRequest,
  saleBoardDataRequest,
} from "../../actions/dashboardActions";
import {
  DollarTag,
  ErrorMessage,
  Loader,
  MoreLink,
  Tag,
  ViewDateText,
} from "../../components/viewComponents";
import { convtDisplayPrice, getTimeStamps } from "../../functions/functions";
import NodataChart from "./charts/NodataChart";
import dashboardStyle from "./dashboardStyle.module.css";
import NoPermission from "../../components/NoPermission";

export default function SalesBoard() {
  const dispatch = useDispatch();
  // access check for the actions

  const {
    userDetails,
    success: userDetailsSuccess,
    loading: userDetailsLoading,
    error: userDetailsError,
  } = useSelector((state) => state.userDetail);
  const userTimeZone = userDetails.data.orgInfo.time_zone;
  ////////  ////////  ////////  ////////  ////////  ////////

  const { loading, salesData, success, error } = useSelector(
    (state) => state.saleBoard
  );

  const {
    loading: poLoading,
    orders,
    success: poSuccess,
    error: poError,
  } = useSelector((state) => state.createdPo);

  ////////////////////////////////////////////

  useEffect(() => {
    let timeStamp = getTimeStamps("7days", userTimeZone);
    if (userDetailsSuccess) {
      if (userDetails.data.permissions.includes("view_created_pos")) {
        dispatch(createdPurchaseOrderRequest());
      }

      if (userDetails.data.permissions.includes("view_sales_summary")) {
        dispatch(saleBoardDataRequest(timeStamp));
      }
    }
  }, [dispatch, userTimeZone]);

  const SaleStatistic = ({ name, value, type = "number" }) => {
    return (
      <div className="w-full bg-lightbg flex flex-col py-4 rounded hover:bg-brandColor hover:text-white hover:cursor-pointer">
        <p className="text-center">{name}</p>
        <p className="w-full text-center text-2xl font-medium mt-1">
          {type === "currency" && <DollarTag />}
          {value}
        </p>
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      {userDetailsSuccess &&
      userDetails.data.permissions.includes("view_created_pos") ? (
        <div className={dashboardStyle.section_wrapper_left_half}>
          <div className="flex flex-row justify-between">
            <div className="flex items-center ">
              <HiCube className={dashboardStyle.section_icon} />
              <p className={dashboardStyle.section_title}>Created PO</p>
            </div>

            <MoreLink href={`/purchase/order-list`} />
          </div>

          <div className="w-full mt-4 ">
            {poLoading ? (
              <Loader mess={"Requesting data.."} />
            ) : poSuccess ? (
              orders.length > 0 ? (
                <table className={dashboardStyle.leadBoradTable}>
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Create Date</th>
                      <th>Supplier</th>
                      <th>Location</th>
                      <th>Total Cost</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((item, index) => (
                      <tr key={index}>
                        <td>{item.orderNumber}</td>
                        <td>
                          {" "}
                          <ViewDateText value={item.createDate} />
                        </td>
                        <td>{item.supplier}</td>

                        <td>
                          <Tag name={item.location} color="gray" />
                        </td>
                        <td>
                          <DollarTag />
                          {item.totalCostInclTax}
                        </td>
                        <td>
                          <NavLink
                            to={`/purchase/order/preview/${item.id}`}
                            className="text-brandColor hover:underline"
                          >
                            View Details
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NodataChart text="No Created PO" />
              )
            ) : poError ? (
              <ErrorMessage mess={poError} />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className={dashboardStyle.section_wrapper_left_half}>
          <div className="flex flex-row justify-between">
            <div className="flex items-center ">
              <HiCube className={dashboardStyle.section_icon} />
              <p className={dashboardStyle.section_title}>Created PO</p>
            </div>
          </div>
          <NoPermission requiredPermission={"View created purchase orders"} />
        </div>
      )}

      {userDetailsSuccess &&
        userDetails.data.permissions.includes("view_sales_summary") ? (
          <div className={dashboardStyle.section_wrapper_width}>
            <div className="flex items-center ">
              <FcSalesPerformance className={dashboardStyle.section_icon} />
              <p className={dashboardStyle.section_title}>
                Last 7 days Sales Summary
              </p>
            </div>
            {loading ? (
              <Loader mess="Requesting data...please wait a moment" />
            ) : success ? (
              <>
                <div className="w-full grid grid-cols-3 gap-4 mt-4">
                  <SaleStatistic
                    name={"Revenue"}
                    value={convtDisplayPrice(salesData.revenue)}
                    type="currency"
                  />
                  <SaleStatistic
                    name={"COGS"}
                    value={convtDisplayPrice(salesData.cogs)}
                    type="currency"
                  />
                  <SaleStatistic
                    name={"Gross Profit"}
                    value={convtDisplayPrice(salesData.profit)}
                    type="currency"
                  />
                  <SaleStatistic
                    name={"Order Quantity"}
                    value={salesData.orderCount}
                    type="number"
                  />
                  <SaleStatistic
                    name={"Product Sold Quantity"}
                    value={salesData.productCount}
                    type="number"
                  />
                </div>
              </>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        ) : 
        <div className={dashboardStyle.section_wrapper_width}>
          <div className="flex items-center ">
            <FcSalesPerformance className={dashboardStyle.section_icon} />
            <p className={dashboardStyle.section_title}>
              Last 7 days Sales Summary
            </p>
          </div>
          <NoPermission requiredPermission={"View sales summary"} />
        </div>
        }
    </div>
  );
}
